import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { Paginator } from "@core/services/paginator";

export interface PagerInfo<T> {
  items: T[];
  searchKey: string;
  initialString?: string | null;
  perPage: number;
}

@Component({
  selector: "confect-pager",
  templateUrl: "./pager.component.html",
})
export class CPagerComponent<T> implements OnInit {
  paginator: Paginator<T>;
  display: { display: string; value: number | null }[];

  @Input() allowPageButtons: boolean = false;
  @Input() showItemCount: boolean = false;
  @Input() set pagerInfo(to: PagerInfo<T>) {
    this.paginator = new Paginator<T>(
      to.items,
      to.searchKey,
      to.initialString ?? "",
      to.perPage,
    );
  }

  @Output() pageChanged = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
    this.display = this.calculatePageButtons();
  }

  changed(page) {
    this.display = this.calculatePageButtons();
    this.pageChanged.emit(page);
  }

  calculatePageButtons(): { display: string; value: number | null }[] {
    const maxPage = this.paginator.pageTotal;
    const currPage = this.paginator.CURRENT_PAGE;

    function wrap(page: number | null) {
      if (page == null) {
        return { display: "...", value: null };
      }
      return { display: String(page), value: page };
    }

    if (maxPage < 6) {
      return [...Array(maxPage)].map((t, i) => {
        return { display: String(i + 1), value: i + 1 };
      });
    }

    if (currPage < 3) {
      return [wrap(1), wrap(2), wrap(3), wrap(null), wrap(maxPage)];
    }

    if (currPage > maxPage - 2) {
      return [
        wrap(1),
        wrap(null),
        wrap(maxPage - 2),
        wrap(maxPage - 1),
        wrap(maxPage),
      ];
    }

    return [
      wrap(1),
      wrap(null),
      wrap(currPage - 1),
      wrap(currPage),
      wrap(currPage + 1),
      wrap(null),
      wrap(maxPage),
    ];
  }
}
