<span
  class="relative z-0 inline-flex"
  [ngClass]="{
    'shadow-sm rounded-md': !threeDotsDropdown,
    'float-right mt-2.5 mr-2.5': threeDotsDropdown,
  }"
>
  @if (!threeDotsDropdown) {
    <button
      type="button"
      class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [class.rounded-r-md]="items.length === 0"
      [ngClass]="{
        'pointer-events-none text-gray-400 bg-gray-100': disable,
        ' bg-white text-gray-700': !disable,
      }"
      (click)="action.emit($event)"
      [disabled]="disable"
    >
      {{ title }}
    </button>
  }
  <span class="-ml-px block" [class.hidden]="items.length === 0">
    <button
      #anchor
      (click)="menuToggle($event)"
      id="option-menu"
      type="button"
      class="relative inline-flex items-center px-2 py-2 text-sm font-medium"
      [ngClass]="{
        'rounded-r-md border border-gray-300  hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500':
          !threeDotsDropdown,
        '	text-white bg-black bg-opacity-60	rounded-full ': threeDotsDropdown,
        'opacity-30': disable && threeDotsDropdown,
        'pointer-events-none ': disable,
        'bg-gray-100 text-gray-300': disable && !threeDotsDropdown,
        'bg-white  text-gray-500': !disable && !threeDotsDropdown,
      }"
      [disabled]="disable"
    >
      <!-- Heroicon name: solid/chevron-down -->
      <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
        />
      </svg>
    </button>
  </span>
</span>

@if (showMenu) {
  <confect-dropdown
    #dropdown
    [items]="items"
    [anchor]="anchor"
    (dropdownItemSelected)="itemSelected($event)"
  />
}
