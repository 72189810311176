import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import {
  ProductFields,
  ProductResponse,
  ProductsStatusCount,
  SearchPayload,
} from "../api-models/products.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class ProductsService extends ApiService {
  // WARNING: Do not use this, use CustomFieldsService instead
  public getProductLookupOptions(): Observable<ProductFields[]> {
    return this.http.get<ProductFields[]>(`${this.endpoint}/products/fields`);
  }

  searchProducts(payload: SearchPayload): Observable<ProductResponse> {
    const page = payload.page ?? 1;
    const page_size = payload.page_size ?? 50;
    return this.http.post<ProductResponse>(
      `${this.endpoint}/products/search?page=${page}&page_size=${page_size}`,
      payload,
    );
  }

  // public getProductsStatus(
  //   filters: any = {},
  //   fulltext_search: string = null,
  //   statuses: string[] = null,
  //   page: number = 1,
  //   per_page = 50
  // ): Observable<ProductStatusResponse> {
  //   const json = {
  //     filters: filters,
  //     fulltext_search: fulltext_search,
  //     statuses: statuses,
  //     page: page,
  //     per_page: per_page,
  //   };
  //   return this.http.post<ProductStatusResponse>(
  //     this.baseURL + "/feeds/products-status",
  //     json
  //   );
  // }

  public reportBadProduct(productId): Observable<any> {
    return this.http.post(`${this.baseURL}/flows/report-product`, {
      product_id: productId,
    });
  }

  public getProductsCount(
    payload: SearchPayload = {},
  ): Observable<ProductsStatusCount> {
    return this.http.post<ProductsStatusCount>(
      `${this.endpoint}/products/status`,
      payload,
    );
  }

  // getAudienceGrouping(audienceSpec, priotitize = []): Observable<any> {
  //   audienceSpec["prioritize"] = priotitize;
  //   return this.http.post(this.baseURL + "/audiences/grouping", audienceSpec);
  // }

  // getAudienceProducts(pids): Observable<any> {
  //   return this.http.post(this.baseURL + "/audiences/filter-products", {
  //     product_ids: pids,
  //   });
  // }

  getFlowOutputOverview(
    flowId,
    filters = {},
    mode = "all",
    page = 1,
  ): Observable<any> {
    return this.http.post(
      this.baseURL + "/flows/" + flowId + "/output-overview",
      {
        page: page,
        filters: filters,
        mode: mode,
      },
    );
  }
}
