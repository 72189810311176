<div
  class="flex-wrap space-x-2 items-center justify-center"
  [ngClass]="{ 'inline-flex': inline, flex: !inline }"
>
  @for (sentence of filterSentences; track sentence; let i = $index) {
    <span
      class="mt-2 inline-flex items-center px-2 py-0.5 rounded-md text-sm font-medium bg-blue-100 text-blue-800 h-6"
    >
      {{ sentence }}
    </span>
    <!-- inline-flex
    items-center
    px-2.5
    py-0.5
    rounded-md
    text-sm
    font-medium
    bg-gray-100
    text-gray-800
    -->
    @if (filterSentences.length > 1 && i !== filterSentences.length - 1) {
      <span class="mt-2 h-6">and</span>
    }
  }
</div>
