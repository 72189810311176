import { CMath, Interval, Point } from "./confect-math";

interface CBoxInterface {
  box2Bounding: (box: Box) => Bounding;
  bounding2Box: (bounding: Bounding) => Box;
  boxSubset: (sub: Box, sup: Box) => boolean;
  boxIntersection: (a: Box, b: Box) => boolean;
  pointInBox: (box: Box, point: Point) => boolean;
  circumBox: (boxes: Box[]) => Box;
  splitBox: (box: Box, splitPos: number[], orientation: Orientation) => Box[];
  getBoxInterval: (box: Box, orientation: Orientation) => Interval;
}

export const CBoxHelper: CBoxInterface = {
  box2Bounding: (box: Box) => {
    return {
      left: box.x,
      right: box.x + box.width,
      center: box.x + box.width / 2,
      top: box.y,
      bottom: box.y + box.height,
      middle: box.y + box.height / 2,
    };
  },
  bounding2Box: (bounding: Bounding) => {
    return {
      x: bounding.left,
      y: bounding.top,
      width: bounding.right - bounding.left,
      height: bounding.bottom - bounding.top,
    };
  },
  boxSubset: (sub: Box, sup: Box) => {
    const subIntX = { start: sub.x, end: sub.x + sub.width };
    const subIntY = { start: sub.y, end: sub.y + sub.height };
    const supIntX = { start: sup.x, end: sup.x + sup.width };
    const supIntY = { start: sup.y, end: sup.y + sup.height };

    return (
      CMath.isSubsetOf(subIntX, supIntX) && CMath.isSubsetOf(subIntY, supIntY)
    );
  },

  boxIntersection: (a: Box, b: Box) => {
    const X1 = { start: a.x, end: a.x + a.width };
    const X2 = { start: b.x, end: b.x + b.width };
    const Y1 = { start: a.y, end: a.y + a.height };
    const Y2 = { start: b.y, end: b.y + b.height };

    const intX = CMath.intersection(X1, X2);
    const intY = CMath.intersection(Y1, Y2);

    if (intX != null && intY != null) {
      return true;
    }

    return false;
  },
  pointInBox: (box: Box, point: Point) => {
    if (
      box.x > point.x ||
      box.x + box.width < point.x ||
      box.y > point.y ||
      box.y + box.height < point.y
    ) {
      return false;
    }
    return true;
  },
  circumBox: (boxes: Box[]) => {
    const x = Math.min(...boxes.map((b) => b.x));
    const y = Math.min(...boxes.map((b) => b.y));
    const alt_x = Math.max(...boxes.map((b) => b.x + b.width));
    const alt_y = Math.max(...boxes.map((b) => b.y + b.height));

    return { x: x, y: y, width: alt_x - x, height: alt_y - y };
  },
  splitBox: (box: Box, splitPos: number[], orientation: Orientation) => {
    const interval = CBoxHelper.getBoxInterval(box, orientation);
    const splits = CMath.sort(splitPos);
    const out: Box[] = [];
    let boxCopy: Box = JSON.parse(JSON.stringify(box));
    splits.forEach((pos) => {
      if (interval.start >= pos || interval.end <= pos) {
        return;
      }
      const first: Box = {
        x: boxCopy.x,
        y: boxCopy.y,
        width:
          orientation === Orientation.Vertical
            ? pos - boxCopy.x
            : boxCopy.width,
        height:
          orientation === Orientation.Horizontal
            ? pos - boxCopy.y
            : boxCopy.height,
      };
      const second: Box = {
        x: orientation === Orientation.Vertical ? pos : boxCopy.x,
        y: orientation === Orientation.Horizontal ? pos : boxCopy.y,
        width:
          orientation === Orientation.Vertical
            ? boxCopy.width - first.width
            : boxCopy.width,
        height:
          orientation === Orientation.Horizontal
            ? boxCopy.height - first.height
            : boxCopy.height,
      };
      out.push(first);
      boxCopy = second;
    });
    out.push(boxCopy);
    return out;
  },
  getBoxInterval: (box: Box, orientation: Orientation) => {
    return orientation === Orientation.Vertical
      ? ({ start: box.x, end: box.x + box.width } as Interval)
      : ({ start: box.y, end: box.y + box.height } as Interval);
  },
};

export interface Box {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface Bounding {
  left: number;
  top: number;
  right: number;
  bottom: number;
  center: number;
  middle: number;
}

export enum Orientation {
  Horizontal = 0,
  Vertical = 1,
}
