<div>
  <ng-content />

  @if (CURRENT_CARD === null || isChanging || editMode) {
    @if (currentError !== null) {
      <div
        class="message crossfade"
        style="height: 30px; margin: 16px; text-align: center"
      >
        <h6>{{ currentError }}</h6>
      </div>
    }
    @if (!readOnly) {
      <div class="fieldset mb-4">
        <div id="card-number" class="field empty"></div>
        <div id="card-expiry" class="field empty half-width"></div>
        <div id="card-cvc" class="field empty half-width"></div>
      </div>
    }

    <div class="secure-box text-gray-300">
      <div class="container">
        <confect-icon icon="lock" iconClass="text-xl mr-2">
          <span class="text-lg text-green-600"
            >Your card is saved securely and encrypted with Stripe</span
          >
        </confect-icon>
      </div>
    </div>
    <div class="flex justify-end">
      @if (!hideSave) {
        <confect-button
          type="primary"
          (click)="saveCard()"
          [disabled]="isSaving"
        >
          Save
        </confect-button>
      }
    </div>
  }

  @if (CURRENT_CARD != null && !(isChanging || editMode)) {
    @if (slim) {
      <div
        [ngClass]="{
          'flex items-center justify-between': !editMode && allowEdit,
        }"
      >
        <div class="flex space-x-2">
          <i
            class="fa-brands flex items-end mt-0.5 text-lg"
            [ngClass]="[
              'fa-cc-' + (hasCardType ? CURRENT_CARD.brand : 'unknown'),
            ]"
          ></i>
          <div class="flex flex-col">
            <div class="text-sm font-semibold">
              {{ CURRENT_CARD.brand.toUpperCase() }}
              •••• •••• ••••
              {{ CURRENT_CARD.last4 }}
            </div>
            <div class="text-sm">
              Expires
              <span class="font-semibold"
                >{{ CURRENT_CARD.exp_month }}/{{ CURRENT_CARD.exp_year }}</span
              >
            </div>
          </div>
        </div>
        @if (allowEdit) {
          <confect-button
            type="secondary"
            (click)="editMode = true; editModeChange.emit(true); initStripe()"
            >Edit</confect-button
          >
        }
      </div>
    } @else {
      <div class="pb-8">
        <div class="card-info">
          <h4 style="text-align: center">
            {{
              CURRENT_CARD.brand.charAt(0).toUpperCase() +
                CURRENT_CARD.brand.slice(1).toLowerCase()
            }}
          </h4>
          <h4 style="text-align: center">
            •••• •••• •••• {{ CURRENT_CARD.last4 }}
          </h4>
          <h4 style="text-align: center">
            Expires {{ CURRENT_CARD.exp_month }}/{{ CURRENT_CARD.exp_year }}
          </h4>
        </div>
        @if (!readOnly) {
          <div class="w-full flex items-center justify-center">
            <confect-button type="secondary" (click)="changeCard()">
              Change card
            </confect-button>
          </div>
        }
      </div>
    }
  }
</div>
