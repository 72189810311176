import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "confect-modal-upload",
  templateUrl: "./modal-upload.component.html",
})
export class CModalUploadComponent {
  invalidInput = false;
  accept;

  isUploading = false;

  //State
  modalInputValue;
  touched = false;

  @Input() modalTitle: string = "";
  @Input() modalText: string = "";
  @Input() showModalText: boolean = false;
  @Input() modalUploadText: string =
    "Drag file here or upload from local source.";
  @Input() buttonText: string = "Select File";
  @Input() modalInvalidText: string = "Wrong file type";
  @Input() set fileTypes(to: string[]) {
    if (to == null) {
      this.accept = "";
      return;
    }
    this.accept = to.join(",");
  }
  @Input() multiple: boolean = true;
  @Output() modalClosed = new EventEmitter();

  constructor() {}

  closeModal() {
    this.modalClosed.emit(false);
  }
  confirmModal() {
    if (this.modalInputValue == null) {
      return;
    }
    this.isUploading = true;
    this.modalClosed.emit(this.modalInputValue);
  }

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  writeValue(value: any) {
    this.modalInputValue = value;
    this.modalClosed.emit(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
