import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "[confect-form-inline-group]",
  templateUrl: "./form-inline-group.component.html",
})
export class CFormInlineGroupComponent implements OnInit {
  @Input() title = "";
  @Input() description = "";

  // Use all available space
  @Input() full = false;

  @Input() disabled = false;

  @Input() showButton = false;
  @Input() buttonTitle = "Save";

  @Input() saveEmit = false;
  @Output() save = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
