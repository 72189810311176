<div
  [ngClass]="{ hidden: currentStep !== info.step }"
  class="flex flex-col divide-y"
>
  <div class="flex justify-between items-center mb-2">
    @if (info.title) {
      <h4 class="ml-1 cursor-default">{{ info.title }}</h4>
    } @else {
      <confect-icon-button
        (click)="back.emit()"
        icon="chevron_left"
        iconClass="text-2xl"
        >Back</confect-icon-button
      >
    }
    @if (!valid) {
      <div class="text-red">{{ invalidText }}</div>
    }
    @if (info.buttonText) {
      <confect-button [disabled]="!valid" (click)="next.emit()">{{
        info.buttonText
      }}</confect-button>
    }
  </div>
  <div class="pt-5">
    <ng-content />
  </div>
</div>
