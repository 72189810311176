import { Component, OnInit } from "@angular/core";

import { CScrollService } from "../../services/confect-scroll.service";

@Component({
  selector: "confect-main-wrapper",
  templateUrl: "./confect-main-wrapper.component.html",
})
export class CMainWrapperComponent implements OnInit {
  constructor(public cScroll: CScrollService) {}

  ngOnInit(): void {}
}
