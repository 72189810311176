<div class="w-full h-full flex">
  @if (!hide) {
    <div
      class="w-full h-full"
      sub-view
      [(spec)]="_spec"
      (specChange)="specChange.emit($event)"
      #subView
    >
      <ng-template split-content-tmp let-view="view">
        <ng-template
          [ngTemplateOutlet]="contentTemplate"
          [ngTemplateOutletContext]="{ view: view }"
        />
      </ng-template>
    </div>
  }
</div>
