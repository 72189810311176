import { Component, Input, OnInit } from "@angular/core";

import { FilterSpecDisplayService } from "@core/services/filter-spec-display";

@Component({
  selector: "ngx-filter-spec-display",
  templateUrl: "./filter-spec-display.component.html",
  styleUrls: ["./filter-spec-display.component.scss"],
})
export class FilterSpecDisplayComponent implements OnInit {
  _filter: any;
  _emptyFilterMatchesNothing = false;

  filterSentences: string[] = [];

  @Input() set filter(to: any) {
    this._filter = to;
    this.refresh();
  }

  @Input() inline = false;

  @Input() set emptyFilterMatchesNothing(to: boolean) {
    this._emptyFilterMatchesNothing = to;
    this.refresh();
  }

  constructor(private filterToText: FilterSpecDisplayService) {}

  ngOnInit(): void {}

  refresh() {
    this.filterSentences = this.filterToText.toList(this._filter);
    if (
      this.filterSentences[0] === "All products" &&
      this._emptyFilterMatchesNothing
    ) {
      this.filterSentences = ["Nothing"];
    }
  }
}
