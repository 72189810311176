import { Component, Input } from "@angular/core";

@Component({
  selector: "confect-spinner",
  templateUrl: "./confect-spinner.component.html",
  styleUrls: ["./confect-spinner.component.scss"],
})
export class ConfectSpinnerComponent {
  @Input({ required: true }) show = true;
}
