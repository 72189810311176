import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Me } from "@core/api-models/users.models";
import { ApiService } from "@core/api.service";
import { ProctorCompany } from "@core/models/proctor.types";

import { AccessService } from "./access.service";
import { CAuthService } from "./auth.service";
import { ProctorInfoService } from "./proctor-info.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class ProctorService {
  constructor(
    private router: Router,
    private api: ApiService,
    private accessService: AccessService,
    private us: UserService,
    public info: ProctorInfoService,
    private auth: CAuthService,
  ) {
    // Not signed in
    if (!this.auth.isAuthenticated()) {
      this.info.isProctor = false;
      sessionStorage.removeItem(this.info.PROCTOR_COMPANY_KEY);
      return;
    }
  }

  initProctor(me: Me) {
    this.info.isProctor = this.us.checkUserHasPermission(me, "proctor");

    // Make sure to remove cached proctor if user is not proctor right now
    if (!this.info.isProctor) {
      sessionStorage.removeItem(this.info.PROCTOR_COMPANY_KEY);
    }

    const companyRaw = sessionStorage[this.info.PROCTOR_COMPANY_KEY];
    if (companyRaw) {
      this.info.currentProctorCompany = JSON.parse(companyRaw);
    }
    this.info.proctorChanged.next(this.info.currentProctorCompany);
  }

  getCompanies() {
    return this.api.getProctorCompaniesInfo();
  }

  changeCompany(company: ProctorCompany) {
    sessionStorage[this.info.PROCTOR_COMPANY_KEY] = JSON.stringify(company);
    this.info.currentProctorCompany = company;
    this.info.proctorChanged.next(this.info.currentProctorCompany);
  }

  exitProctorMode() {
    sessionStorage.removeItem(this.info.PROCTOR_COMPANY_KEY);
    this.info.currentProctorCompany = null;
    this.accessService.unselectAccess();
    this.info.proctorChanged.next(this.info.currentProctorCompany);
  }

  openProctor() {
    this.accessService.unselectAccess();
    this.us.me().subscribe((me) => {
      if (this.us.checkUserHasPermission(me, "admin")) {
        this.router.navigate(["/admin"]);
        return;
      }
      this.router.navigate(["/proctor"]);
    });
  }
}
