import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  WritableSignal,
  signal,
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";

import { AccountImageMode } from "@core/api-models/users.models";
import { ProductFields, ProductFilter } from "@core/models/products.types";
import { AccessService } from "@core/services/access.service";
import { Paginator } from "@core/services/paginator";

import { CSelectComponent } from "@theme/@confect/components/form/elements/select/select.component";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Subject, debounceTime } from "rxjs";

@Component({
  selector: "ngx-product-filter",
  templateUrl: "./product-filter.component.html",
  styleUrls: ["./product-filter.component.scss"],
})
export class ProductFilterComponent implements OnInit {
  _filterOptions: ProductFields[] = [];
  searchResultProducts = [];
  bgStatusValue;

  paginators = {};

  @ViewChild("productSearchTemplate") productSearchTemplate: TemplateRef<any>;

  @ViewChild("imageModeSelect") imageModeSelect: CSelectComponent<{
    name: string;
    id: string;
  }>;

  // @ViewChild("productSearchModal") productSearchModal: CModalComponent;

  @Input() set filterOptions(to: ProductFields[]) {
    this._filterOptions = to;

    this.setupPaginators();
  }

  _selectedFilters: ProductFilter = {};
  @Input() set selectedFilters(to: any) {
    this._selectedFilters = this.migrateLegacyFilters(to ?? {});
    if (this._selectedFilters.num_additional_images != null) {
      this.addImageBoxChecked.update((addImages) =>
        addImages.map((_, i) =>
          this._selectedFilters.num_additional_images.includes(i),
        ),
      );
    } else {
      this.addImageBoxChecked.update(() => new Array(11).fill(false));
    }

    if (this._selectedFilters.has_properties != null) {
      this.hasProperty = this._selectedFilters.has_properties;
    }
    // Ugly hack to make the select update with late set filters :'(
    setTimeout(() => {
      if (this.imageModeSelect) {
        this.bgStatusValue = this._selectedFilters["bg_status"] ?? "";
      }
    }, 0);
    this.resolveHiddenFilterOptions();
  }

  _selectedFiltersForProductSearch = null;
  @Input() set selectedFiltersForProductSearch(to: any) {
    this._selectedFiltersForProductSearch = this.migrateLegacyFilters(to);
  }

  @Output() selectedFiltersChange = new EventEmitter<any>();

  @Input() initialSelectedFilter = null;

  showImageModeSettings = false;
  @Input() builtinsEnabled = true;
  @Input() productIdEnabled = true;

  @ViewChildren(MatExpansionPanel)
  filterChildren!: QueryList<MatExpansionPanel>;

  valueChanged = false;
  waitingToRefresh = false;

  refreshSubject = new Subject<boolean>();

  addImageBoxChecked: WritableSignal<boolean[]> = signal(
    new Array(11).fill(false),
  );

  hasProperty: { additional_fields: string[]; custom_fields: string[] } = {
    additional_fields: [],
    custom_fields: [],
  };

  constructor(
    private accessService: AccessService,
    private modalService: CPopupModalService,
  ) {}

  ngOnInit() {
    if (!this._selectedFiltersForProductSearch) {
      this._selectedFiltersForProductSearch = this._selectedFilters;
    }

    this.showImageModeSettings =
      this.accessService.current?.account.image_mode === AccountImageMode.MIX;

    this.resolveHiddenFilterOptions();
    this.setupPaginators();

    this.refreshSubject.pipe(debounceTime(700)).subscribe((_) => {
      this.selectedFiltersChange.emit(this._selectedFilters);
    });
  }

  updateBoxes(element) {
    const update = this.addImageBoxChecked();
    update[element] = !update[element];
    this.addImageBoxChecked.update(() => update);

    return update.every((value) => !value)
      ? ""
      : update.map((_, i) => i).filter((i) => update[i]);
  }

  updateHasProperty(element, group) {
    const index = this.hasProperty[group].indexOf(element);
    if (index === -1) {
      this.hasProperty[group].push(element);
      return this.hasProperty;
    }
    this.hasProperty[group].splice(index, 1);
    return this.hasProperty;
  }

  setupPaginators() {
    // Ready an index for each categorical option
    this.paginators = {};

    this._filterOptions
      .filter((option: ProductFields) => option.type === "categorical")
      .forEach((o: ProductFields) => {
        const sortedOptions = this.sortOptionsBySelected(o);
        this.paginators[o.name] = new Paginator(sortedOptions, "name", "", 10);
      });
  }

  sortOptionsBySelected(filterOption: ProductFields) {
    // Sort case insensitive
    const sortedOptions = [...filterOption.options].sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });

    // Split in top + bottom
    const top = sortedOptions.filter((fo) => this.hasOption(filterOption, fo));
    const rest = sortedOptions.filter(
      (fo) => !this.hasOption(filterOption, fo),
    );

    // Top then res
    return top.concat(rest);
  }

  // Helper for migrating filters using dot syntax for nested/grouped filters
  private migrateLegacyFilters(f) {
    const newFilter = {};

    for (const key of Object.keys(f)) {
      if (key.startsWith("additional_fields.")) {
        // Make sure object exists
        if (!newFilter["additional_fields"]) {
          newFilter["additional_fields"] = {};
        }
        const nestedKey = key.replace("additional_fields.", "");
        // Make sure nestedKey does not exist as new filter format takes precedence
        if (nestedKey in newFilter["additional_fields"]) {
          continue;
        }

        newFilter["additional_fields"][nestedKey] = f[key];
        continue;
      }

      // Move key to new filter
      newFilter[key] = f[key];
    }

    return newFilter;
  }

  private _addMissingFilterOptions(group, key, selectedValues) {
    // Find in filterOptions
    let filterOption = this._filterOptions.find(
      (fo) => fo.group === group && fo.key === key,
    );

    if (!filterOption) {
      // Add filterOption altogether
      filterOption = {
        group: group,
        key: key,
        name: key,
        options: selectedValues.map((sv) => {
          return { name: sv, key: sv };
        }),
        type: "categorical",
      };
      this._filterOptions.push(filterOption);
      return;
    }

    // Add all missing selectedValues to filterOption
    selectedValues.forEach((sv) => {
      if (!filterOption.options.find((o) => o.key == sv)) {
        filterOption.options.push({ name: sv, key: sv });
      }
    });
  }

  resolveHiddenFilterOptions() {
    // Adds any selected filter options not
    // currently in the prod feed to enable
    // deselection.
    for (const k of Object.keys(this._selectedFilters)) {
      // Not applicable for default and advanced options
      if (
        [
          "product_id",
          "max_price",
          "min_price",
          "on_sale",
          "use_original_products",
          "newer_than",
          "bg_status",
          "num_additional_images",
        ].includes(k.toLowerCase())
      )
        continue;

      // The element might both be a list of selected values (outer filter)
      // or an object containing several key => selected values (nested/grouped filter)
      const elem = this._selectedFilters[k];

      // Resolve nested and not-nested filters and make sure unknown selected values show for deselection
      if (!Array.isArray(elem)) {
        for (const nestedKey of Object.keys(elem)) {
          this._addMissingFilterOptions(k, nestedKey, elem[nestedKey]);
        }
      } else {
        this._addMissingFilterOptions(null, k, elem);
      }
    }

    this.setupPaginators();
  }

  numberSelectedOptions(filterOption) {
    const n = filterOption.key;
    const g = filterOption.group;

    const filterGroup =
      g == null ? this._selectedFilters : this._selectedFilters[g];

    if (!filterGroup) {
      return 0;
    }

    if (!(n in filterGroup)) {
      return 0;
    }

    const s = filterGroup[n];
    return s.length;
  }

  formattedSelectedOptions(filterOption) {
    const n = this.numberSelectedOptions(filterOption);
    return n > 0 ? "(" + n + ")" : "";
  }

  hasOption(filterOption, option) {
    const n = filterOption.key;
    const g = filterOption.group;

    const filterGroup =
      g == null ? this._selectedFilters : this._selectedFilters[g];

    if (!filterGroup) {
      return false;
    }

    if (!(n in filterGroup)) {
      return false;
    }

    const s = filterGroup[n];
    return s.includes(option.key);
  }

  checkProperty(property, group) {
    const hasProperty = this.hasProperty;
    if (group !== "additional_fields" && group !== "custom_fields") {
      return false;
    }
    return hasProperty[group].includes(property);
  }

  toggleOption(filterOption, option) {
    const n = filterOption.key;
    const g = filterOption.group;

    // Determine if this is a top-level filter, or a nested one
    let filterGroup =
      g == null ? this._selectedFilters : this._selectedFilters[g];

    // Default filtergroup is empty
    if (!filterGroup && g != null) {
      filterGroup = {};
    }

    // If filter name is not in filtergroup, add is as empty
    if (!(n in filterGroup)) {
      filterGroup[n] = [];
    }

    // If option already exists, in filter, we remove it
    if (this.hasOption(filterOption, option)) {
      filterGroup[n] = filterGroup[n].filter((i) => i !== option.key);
    } else {
      // Add filter
      filterGroup[n].push(option.key);
    }

    // If not options, it is now undefined
    if (filterGroup[n].length === 0) {
      delete filterGroup[n];
    }

    // If had a group, we put the modified filterGroup back
    if (g != null) {
      if (Object.keys(filterGroup).length > 0) {
        this._selectedFilters[g] = filterGroup;
      } else {
        delete this._selectedFilters[g];
      }
    }

    this.selectedFiltersChange.emit(this._selectedFilters);
  }

  // safe(v, defaultValue: any = "") {
  //   if (v === undefined) {
  //     return defaultValue;
  //   }
  //   return v;
  // }

  // delayedRefresh() {
  //   if (this.waitingToRefresh) {
  //     this.valueChanged = true;
  //     return;
  //   }

  //   this.waitingToRefresh = true;

  //   setTimeout(() => {
  //     if (this.valueChanged) {
  //       this.valueChanged = false;
  //       this.waitingToRefresh = false;
  //       this.delayedRefresh();
  //     } else {
  //       this.selectedFiltersChange.emit(this._selectedFilters);
  //       this.waitingToRefresh = false;
  //     }
  //   }, 1000);
  // }

  internalValueChanged(to, bound) {
    if (to === "") {
      delete this._selectedFilters[bound];
      this.refreshSubject.next(true);

      return;
    }

    this._selectedFilters[bound] = to;

    this.refreshSubject.next(true);
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  generalValueChange(key, to) {
    this._selectedFilters[key] = to;

    if (typeof to === "boolean" && to === false) {
      delete this._selectedFilters[key];
    }

    this.refreshSubject.next(true);
  }

  openSearchWindow() {
    const modal = this.modalService.template({
      template: this.productSearchTemplate,
    });

    modal?.outputs["modalClosed"].asObservable().subscribe({
      next: (res) => {},
    });
  }

  searchResult(pids) {
    this._selectedFilters["product_id"] = pids;
    this.refreshSubject.next(true);
  }

  resetSearchProducts() {
    delete this._selectedFilters["product_id"];
    this.refreshSubject.next(true);
  }

  filterProductCount() {
    const pids = this._selectedFilters["product_id"];
    if (pids != null) {
      if (pids.length > 0) {
        return pids.length;
      }
    }
    return "All";
  }

  bgStatusChange(to?: string) {
    if (to == null) {
      delete this._selectedFilters["bg_status"];
    } else {
      this._selectedFilters["bg_status"] = to;
    }

    this.selectedFiltersChange.emit(this._selectedFilters);
  }

  trackBy(idx, option) {
    return `${option.group}-${option.key}`;
  }
}
