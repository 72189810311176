import { Injectable, TemplateRef } from "@angular/core";

import { Observable } from "rxjs";

import { CModalInputComponent } from "../components/modal-input/modal-input.component";
import { CModalSuccessComponent } from "../components/modal-success/modal-success.component";
import { CModalTemplateComponent } from "../components/modal-template/modal-template.component";
import { CModalUploadComponent } from "../components/modal-upload/modal-upload.component";
import {
  CModalWarningComponent,
  LinkInfo,
} from "../components/modal-warning/modal-warning.component";
import { CModalService } from "./confect-modal.service";

export interface ModalInput {
  title?: string;
  text?: string;
  buttonText?: string;
  autoCloseTimeout?: number;
}

export interface WarningInput {
  title: string;
  text?: string;
  type?: string;
  confirmText?: string;
  cancelText?: string;
  autoCloseTimeout?: number;
  linkInfo?: LinkInfo;
}
export interface TemplateInput {
  template: TemplateRef<any>;
  showClose?: boolean;
  type?: string;
  disableScroll?: boolean;
  extra?: any;
}

export interface InputInput {
  title: string;
  placeholder?: string;
  type?: string;
  text?: string;
  value?: string;
  autoCloseTimeout?: number;
  confirmText?: string;
  cancelText?: string;
  invalidText?: string;
  fileTypes?: string[];
  validator?: (res: any) => boolean;
}

export interface UploadInput {
  title: string;
  text?: string;
  uploadText?: string;
  autoCloseTimeout?: number;
  buttonText?: string;
  invalidText?: string;
  fileTypes?: string[];
  multiple?: boolean;
  validator?: (res: any) => boolean;
  preConfirm?: (res: any) => Observable<any>;
}

@Injectable({ providedIn: "root" })
export class CPopupModalService {
  constructor(private modalService: CModalService) {}

  /**
   * Opens success/error popup-modal.
   * Is similar to the method `setupModal` of the `CModalSuccessComponent`.
   */
  public openPopup(
    success: boolean,
    showText: boolean,
    title?: string,
    text?: string,
    buttonText?: string,
    autoCloseTimeout?: number,
  ) {
    const modalRef = this.modalService.open({
      component: CModalSuccessComponent,
      inputs: {
        modalSuccess: success,
        showModalText: showText,
        modalTitle: title,
        modalText: text,
        buttonText: buttonText,
      },
    });

    if (autoCloseTimeout) {
      setTimeout(() => {
        modalRef.outputs["modalClosed"].emit(true);
        modalRef.outputs["modalClosed"].complete();
      }, autoCloseTimeout);
    }

    return modalRef;
  }

  public openTemplate(
    template: TemplateRef<any>,
    showClose: boolean = false,
    type: string = "modal",
    disableScroll: boolean = false,
    extra?: any,
  ) {
    const modalRef = this.modalService.open({
      component: CModalTemplateComponent,
      inputs: {
        showClose: showClose,
        template: template,
        extra: extra,
      },
      type: type,
      disableScroll: disableScroll,
    });

    return modalRef;
  }

  public openWarning(
    showText: boolean,
    title: string,
    text?: string,
    type?: string,
    autoCloseTimeout?: number,
    confirmText: string = "Yes",
    cancelText: string = "Cancel",
    linkInfo?: LinkInfo,
  ) {
    const modalRef = this.modalService.open({
      component: CModalWarningComponent,
      inputs: {
        showModalText: showText,
        modalTitle: title,
        modalText: text,
        type: type,
        linkInfo: linkInfo,
        confirmText: confirmText,
        cancelText: cancelText,
      },
    });

    if (autoCloseTimeout) {
      setTimeout(() => {
        modalRef.outputs["modalClosed"].emit(true);
        modalRef.outputs["modalClosed"].complete();
      }, autoCloseTimeout);
    }

    return modalRef;
  }
  public openInput(
    showText: boolean,
    title: string,
    placeholder: string = "",
    type?: string,
    text?: string,
    autoCloseTimeout?: number,
    confirmText: string = "Confirm",
    cancelText: string = "Cancel",
    invalidText: string = "Nothing Entered",
    fileTypes?: string[],
    validator: (res: any) => boolean = (res) => {
      return res ? true : false;
    },
    modalValue?: string,
  ) {
    const modalRef = this.modalService.open({
      component: CModalInputComponent,
      inputs: {
        showModalText: showText,
        modalTitle: title,
        modaPlaceholder: placeholder,
        modalInputType: type,
        modalText: text,
        confirmText: confirmText,
        cancelText: cancelText,
        modalInvalidText: invalidText,
        fileTypes: fileTypes,
        validator: validator,
        modalValue: modalValue,
      },
    });

    if (autoCloseTimeout) {
      setTimeout(() => {
        modalRef.outputs["modalClosed"].emit(true);
        modalRef.outputs["modalClosed"].complete();
      }, autoCloseTimeout);
    }

    return modalRef;
  }

  public openUpload(
    showText: boolean,
    title: string,
    text?: string,
    uploadText: string = "Drag file here or upload from local source.",
    autoCloseTimeout?: number,
    buttonText: string = "Select File",
    invalidText: string = "Wrong file type",
    fileTypes?: string[],
    multiple: boolean = true,
    validator: (res: any) => boolean = (res) => {
      return res ? true : false;
    },
    preConfirm?: (res: any) => Observable<any>,
  ) {
    const modalRef = this.modalService.open({
      component: CModalUploadComponent,
      inputs: {
        showModalText: showText,
        modalTitle: title,
        modalText: text,
        modalUploadText: uploadText,
        buttonText: buttonText,
        modalInvalidText: invalidText,
        fileTypes: fileTypes,
        multiple: multiple,
        validator: validator,
      },
      preConfirm: preConfirm,
    });

    if (autoCloseTimeout) {
      setTimeout(() => {
        modalRef.outputs["modalClosed"].emit(true);
        modalRef.outputs["modalClosed"].complete();
      }, autoCloseTimeout);
    }

    return modalRef;
  }

  /** Opens success popup-modal. */
  public success(input: ModalInput) {
    return this.openPopup(
      true,
      true,
      input.title,
      input.text,
      input.buttonText ?? "OK",
      input.autoCloseTimeout,
    );
  }

  /** Opens error popup-modal */
  public error(input: ModalInput) {
    return this.openPopup(
      false,
      true,
      input.title,
      input.text,
      input.buttonText ?? "OK",
      input.autoCloseTimeout,
    );
  }

  public warning(input: WarningInput) {
    return this.openWarning(
      true,
      input.title,
      input.text,
      input.type,
      input.autoCloseTimeout,
      input.confirmText,
      input.cancelText,
      input.linkInfo,
    );
  }

  public input(input: InputInput) {
    return this.openInput(
      true,
      input.title,
      input.placeholder,
      input.type,
      input.text,
      input.autoCloseTimeout,
      input.confirmText,
      input.cancelText,
      input.invalidText,
      input.fileTypes,
      input.validator,
      input.value,
    );
  }

  public upload(input: UploadInput) {
    return this.openUpload(
      true,
      input.title,
      input.text,
      input.uploadText,
      input.autoCloseTimeout,
      input.buttonText,
      input.invalidText,
      input.fileTypes,
      input.multiple,
      input.validator,
      input.preConfirm,
    );
  }

  public template(input: TemplateInput) {
    return this.openTemplate(
      input.template,
      input.showClose,
      input.type,
      input.disableScroll,
      input.extra,
    );
  }
}
