import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { ControlValueAccessor } from "@angular/forms";

@Component({
  selector: "confect-modal-input",
  templateUrl: "./modal-input.component.html",
})
export class CModalInputComponent implements OnInit, ControlValueAccessor {
  invalidInput = false;
  accept;

  //State
  modalInputValue = "";
  touched = false;

  @Input() set modalValue(to: string) {
    if (!to) {
      return;
    }
    this.modalInputValue = to;
  }
  @Input() modalTitle: string = "";
  @Input() modaPlaceholder: string = "";
  @Input() modalInputType: string = "text";
  @Input() showModalText: boolean = false;
  @Input() modalText: string = "";
  @Input() confirmText: string = "Confirm";
  @Input() cancelText: string = "Cancel";
  @Input() modalInvalidText: string = "Nothing Entered";
  @Input() set fileTypes(to: string[]) {
    if (to == null) {
      return;
    }
    this.accept = to.join(",");
  }

  @Output() modalClosed = new EventEmitter();

  @Input() validator = (res) => {
    return res ? true : false;
  };

  constructor() {}

  closeModal() {
    this.modalClosed.emit(false);
  }
  confirmModal() {
    this.invalidInput = !this.validator(this.modalInputValue);
    if (this.invalidInput) {
      return;
    }
    this.modalClosed.emit(this.modalInputValue);
  }

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  ngOnInit(): void {}

  writeValue(value: any) {
    this.modalInputValue = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  validateChange(event) {
    if (this.modalInputType !== "file") {
      return;
    }
    const file = event.target.files[0];

    this.invalidInput = !this.validator(file);
  }
}
