import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "confect-upload",
  templateUrl: "./upload.component.html",
  styleUrl: "./upload.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CUploadComponent,
      multi: true,
    },
  ],
})
export class CUploadComponent implements ControlValueAccessor {
  @Input() accept;
  @Input() buttonText = "Select File";
  @Input() text = "Drag file here or upload from local source.";
  @Input() name = "";
  @Input() multiple: boolean = true;
  @Input() isUploading = false;

  @Output() invalid = new EventEmitter();

  // State
  value;
  touched = false;

  // Callbacks
  protected onChange = (_value) => {};
  protected onTouched = () => {};

  constructor() {}

  writeValue(value: FileList) {
    if (value == null) {
      return;
    }
    const files = Array.from(value);

    if (files.length === 0) {
      return;
    }

    if (!files.every((file) => this.fileValidate(file))) {
      this.invalid.emit();
      return;
    }

    this.value = files;
    this.onChange(files);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  reset() {
    delete this.value;
  }

  fileValidate(file) {
    if (!this.accept) {
      return true;
    }

    const extension = "." + file.name.split(".").pop();
    const exactType = file.type;
    const dataType = file.type.split("/")[0] + "/*";
    const allowedTypes = this.accept.split(",");

    return (
      allowedTypes.includes(extension) ||
      allowedTypes.includes(exactType) ||
      allowedTypes.includes(dataType)
    );
  }
}
