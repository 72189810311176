<div class="flex items-center justify-end select-none pl-2.5">
  <div class="relative flex-grow">
    <div
      class="absolute h-1 -top-0.5 bg-slate-200 rounded-full"
      (mousedown)="setSlide($event)"
      [style.width.%]="98"
      #slideBox
    >
      <div
        class="h-full rounded-l-full bg-primary"
        [style.width.%]="constrainedVal()"
      ></div>
    </div>
    <div
      class="w-5 h-5 rounded-full absolute -top-2.5 -translate-x-2.5 z-10 bg-white hover:bg-slate-50 ring-inset ring-2"
      [ngClass]="{ 'ring-slate-300': !sliding, 'ring-primary': sliding }"
      [style.left.%]="(constrainedVal() * 98) / 100"
      (mousedown)="beginSlide($event)"
    ></div>
  </div>
  <div class="ml-3">
    <input
      #input
      class="py-1 px-3 focus:outline-none rounded-md text-sm border-none w-14 text-center"
      [readonly]="!writable"
      [ngClass]="{
        'bg-white ring-inset ring-2 ring-primary': focus,
        'bg-gray-200': !focus && type === 'dark',
        'bg-slate-100': !focus && type === 'normal',
        'bg-white': !focus && type === 'light',
        'caret-transparent': !typing,
        'caret-gray-700': typing,
        'pointer-events-none': !writable,
      }"
      (input)="
        writeValue(constrain($any($event).target.value, start(), end()));
        onChange(constrain($any($event).target.value, start(), end()))
      "
      [value]="roundedValue()"
      (blur)="focus = false; typing = false; onTouched()"
      (focus)="focus = true"
      [name]="name"
      (click)="typing = true"
      type="number"
    />
  </div>
</div>
