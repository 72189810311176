@if (_show && _parent != null) {
  <div
    class="fixed flex flex-col -translate-y-full -translate-x-1/2 items-center"
    [style.z-index]="500"
    [style.left.px]="left"
    [style.top.px]="top"
    (click)="$event.stopPropagation()"
  >
    <div
      class="rounded-sm bg-white px-0.5 py-0.5 ring-1 ring-inset ring-slate-200 shadow-sm mb-1 divide-x divide-x-slate-200 flex"
    >
      <ng-content />
    </div>
    <!-- <div
      class="aspect-square w-2 h-2 rotate-45 ring-2 ring-inset ring-primary bg-white mb-0.5"
    ></div> -->
  </div>
}
