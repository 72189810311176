import { Injectable } from "@angular/core";

import { ProctorCompany } from "@core/models/proctor.types";

import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProctorInfoService {
  PROCTOR_COMPANY_KEY = "company-account";

  proctorChanged = new Subject<ProctorCompany>();
  $proctorChanged = this.proctorChanged.asObservable();

  isProctor = false;

  currentProctorCompany: ProctorCompany = null;

  proctorModeActive(): boolean {
    return (
      sessionStorage[this.PROCTOR_COMPANY_KEY] != null &&
      this.currentProctorCompany != null
    );
  }
}
