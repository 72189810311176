<confect-card class="flex-grow" [withoutBorders]="noBorders">
  <confect-card-header>
    <div class="-ml-4 -mt-2">
      <div class="ml-4 mt-2 flex justify-between items-center space-x-2">
        <h2 class="flex-shrink-0">Search Products</h2>
        <div>
          <confect-button size="small" (click)="apply()">
            <confect-icon icon="done" iconClass="text-3xl"
              >Apply Selection</confect-icon
            >
          </confect-button>
        </div>
      </div>
    </div>
  </confect-card-header>
  <confect-card-body>
    <div class="flex flex-row h-full" style="min-height: 500px">
      <nav class="space-y-1 w-2/12 pr-5" aria-label="Sidebar">
        <a
          (click)="changeTab('Search')"
          [ngClass]="{ 'bg-gray-100': selectedTab === 'Search' }"
          class="hover:bg-gray-100 text-gray-600 hover:text-gray-900 cursor-pointer no-underline group flex items-center px-3 py-2 text-sm font-medium rounded-md select-none"
          ><confect-icon icon="search" iconClass="text-gray-500 mr-3 text-2xl"
            ><span>Search</span></confect-icon
          >
        </a>
        <a
          (click)="changeTab('Selected Products')"
          [ngClass]="{ 'bg-gray-100': selectedTab === 'Selected Products' }"
          class="hover:bg-gray-100 text-gray-600 hover:text-gray-900 cursor-pointer no-underline group flex items-center px-3 py-2 text-sm font-medium rounded-md select-none"
          ><confect-icon icon="files" iconClass="text-gray-500 mr-3 text-2xl"
            ><span class="whitespace-normal"
              >Selected Products ({{ selectedSet.size }})</span
            ></confect-icon
          >
        </a>
      </nav>

      <div class="w-10/12 pl-5 border-l">
        @if (selectedTab === "Search") {
          <div
            [ngClass]="{
              'invisible h-0':
                !isLoading &&
                searchResultProducts.length === 0 &&
                !searchString,
            }"
            class="relative flex-grow focus-within:z-10"
          >
            <div
              class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
            >
              <!-- Heroicon name: solid/search -->
              <svg
                class="h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              name="search"
              id="search"
              [(ngModel)]="searchString"
              (ngModelChange)="searchChange.next(true)"
              class="focus:ring-0 block w-full rounded-r-md rounded-l-md pl-10 border-gray-200"
              placeholder="Search for ID, name, brand, category, etc."
            />
          </div>
          <div
            (scroll)="onScroll($event)"
            class="overflow-hidden border-gray-200 sm:rounded-lg searchTableDiv overflow-y-auto"
            [class.border]="searchResultProducts.length > 0"
          >
            @if (searchResultProducts.length > 0) {
              <table
                class="w-full max-w-full divide-y divide-gray-200 table-fixed searchTable"
              >
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3"></th>
                    <th scope="col" class="px-6 py-3 w-1/12"></th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12"
                    >
                      Brand
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12"
                    >
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Odd row -->
                  @for (p of searchResultProducts; track p; let i = $index) {
                    <tr
                      class="bg-white"
                      [ngClass]="{
                        'bg-white': i % 2 === 0,
                        'bg-gray-50': i % 2 === 1,
                      }"
                    >
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <confect-checkbox
                          [value]="isSelected(p)"
                          (valueChange)="changeSelected(p)"
                        />
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <a target="_blank" [href]="p.cdn_image"
                          ><img [lazyLoad]="p.cdn_image"
                        /></a>
                      </td>
                      <td
                        class="px-6 py-4 text-sm whitespace-nowrap overflow-ellipsis overflow-hidden text-gray-500"
                      >
                        {{ p.ext_id }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap overflow-ellipsis overflow-hidden text-sm text-gray-500"
                      >
                        <a target="_blank" [href]="p.url">{{ p.name }}</a>
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap overflow-ellipsis overflow-hidden text-sm text-gray-500"
                      >
                        {{ p.brand }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {{ p.price }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            }
            @if (searchResultProducts.length === 0 && !isLoading) {
              <div
                class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-left"
              >
                @if (searchString.length > 0) {
                  <p class="text-left">There is nothing matching your query.</p>
                }
                <!-- Delayed because of debounced search request  -->
                <p
                  class="transition-all text-left"
                  style="transition-delay: 2000ms"
                  [class.invisible]="searchString.length > 0"
                >
                  Products list is empty.
                </p>
              </div>
            }
          </div>
        }

        @if (selectedTab === "Selected Products") {
          <ul
            role="list"
            class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8 selected-products__list"
          >
            @for (p of selectedProducts; track track($index, p)) {
              <li class="relative">
                <div
                  class="group block w-full aspect-w-10 aspect-h-7 rounded bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
                >
                  <a target="_blank" [href]="p.cdn_image"
                    ><div
                      [lazyLoad]="p.cdn_image"
                      alt=""
                      class="selected-product__image object-cover pointer-events-none group-hover:opacity-75"
                    ></div
                  ></a>
                  <confect-button
                    size="small"
                    type="white"
                    (click)="changeSelected(p)"
                    class="button-remove"
                  >
                    <confect-icon icon="delete" iconClass="text-xl" />
                  </confect-button>
                </div>
                <p
                  class="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none"
                >
                  <a target="_blank" [href]="p.url">{{ p.name }}</a>
                </p>
              </li>
            }
          </ul>
        }
      </div>
    </div>
  </confect-card-body>
</confect-card>
