@if (company != null) {
  <div [ngClass]="{ hidden: !editMode, block: editMode }">
    <form
      confect-form-inline-group
      (ngSubmit)="!submitted && billingGroup.valid && save()"
      [full]="true"
      [formGroup]="billingGroup"
      (input)="formChanged.emit($event)"
    >
      <confect-input
        title="Company name"
        [(ngModel)]="company.name"
        placeholder="Company ApS"
        formControlName="name"
      />
      <confect-input
        title="Billing email"
        [(ngModel)]="company.billing_email"
        placeholder="finance@company.com"
        formControlName="billing_email"
      />
      <confect-input
        title="Street"
        [(ngModel)]="company.address"
        placeholder="Esromgade 15"
        formControlName="street"
      />
      <confect-input
        title="Zip code"
        [(ngModel)]="company.zipcode"
        placeholder="2200"
        formControlName="zip"
      />
      <confect-input
        title="City"
        [(ngModel)]="company.city"
        placeholder="Copenhagen"
        formControlName="city"
      />
      <confect-select
        title="Country"
        [(ngModel)]="company.country"
        placeholder="Select country"
        [options]="COUNTRIES"
        bindLabel="name"
        bindValue="name"
        formControlName="country"
      />
      <confect-input
        title="VAT number (with country code)"
        [(ngModel)]="company.tax_number"
        placeholder="DKXXXXXXXX"
        formControlName="vat"
      />
      @if (billingGroup.errors?.vatRequired != null) {
        <div class="text-right font-medium text-sm -mt-2 mb-8">
          A VAT number is required
        </div>
      }
    </form>
    @if (showSave) {
      <div class="pt-5 flex justify-end">
        <confect-button
          [disabled]="submitted || billingGroup.invalid"
          [submit]="true"
          (click)="save()"
          >Save</confect-button
        >
      </div>
    }
  </div>
  @if (!editMode) {
    <div class="w-full flex">
      <div class="w-1/2 flex flex-col justify-start">
        <span class="text-sm text-gray-700 font-semibold">{{
          company.name
        }}</span>

        <span class="text-sm text-gray-700 h-[1.25rem]">
          @if (company.billing_email) {
            {{ company.billing_email }}
          } @else {
            <div class="h-full flex items-center">
              <div class="h-[0.5rem] w-52 bg-slate-100"></div>
            </div>
          }
        </span>
        <span class="text-sm text-gray-700">
          @if (company.tax_number) {
            VAT {{ company.tax_number }}
          }
        </span>
      </div>
      <div class="w-1/2 flex flex-col justify-start">
        <span class="text-sm text-gray-700 font-semibold">Billing Address</span>
        <span class="text-sm text-gray-700 flex h-[1.25rem]">
          @if (company.address) {
            {{ company.address | truncate: 30 }},
            @if (company.zipcode) {
              {{ company.zipcode }}
            }
          } @else {
            <div class="h-full flex items-center">
              <div class="h-[0.5rem] w-52 bg-slate-100"></div>
            </div>
          }
        </span>
        <span class="text-sm h-[1.25rem]">
          @if (company.city) {
            {{ company.city }},
            @if (company.country) {
              {{ company.country }}
            }
          } @else {
            <div class="h-full flex items-center">
              <div class="h-[0.5rem] w-52 bg-slate-100"></div>
            </div>
          }
        </span>
      </div>
    </div>
  }
}
