import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";

import { SettingsService } from "@core/api/settings.service";

import {
  StripeElementsOptions,
  StripeCardElementOptions,
  StripeCardCvcElement,
  StripeCardExpiryElement,
  StripeCardNumberElement,
  StripeElements,
} from "@stripe/stripe-js";
import { StripeService } from "ngx-stripe";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

declare let stripe;

@Component({
  selector: "ngx-stripe-card-input",
  templateUrl: "./stripe-card-input.component.html",
  styleUrls: ["./stripe-card-input.component.scss"],
})
export class StripeCardInputComponent implements OnInit, OnDestroy {
  @Input() hideSave = false;
  @Input() allowEdit = false;
  @Input() readOnly: boolean = false;
  @Input() editMode: boolean = false;
  @Output() editModeChange = new EventEmitter<boolean>();
  @Input() slim: boolean = false;

  stripeElements: StripeElements;
  cardNumber: StripeCardNumberElement;
  cardExpiry: StripeCardExpiryElement;
  cardCvc: StripeCardCvcElement;

  isSaving = false;
  currentError = null;

  cardTypes = [
    "amex",
    "diners",
    "discover",
    "eftpos_au",
    "jcb",
    "mastercard",
    "unionpay",
    "visa",
    "unknown",
  ];

  hasCardType: boolean = false;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        color: "#374151",
        fontWeight: "600",
        fontFamily: "Quicksand, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",

        ":focus": {
          color: "#374151",
        },

        "::placeholder": {
          color: "#d1d5db",
        },

        // ':focus::placeholder': {
        //   color: '#CFD7DF',
        // },
      },
      invalid: {
        color: "#c0392b",
        ":focus": {
          color: "#c0392b",
        },
        "::placeholder": {
          color: "#c0392b",
        },
      },

      complete: {
        color: "#27ae60",
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: "en",
  };

  CURRENT_CARD = undefined;

  isChanging = false;

  unsubscribe$ = new Subject();

  // Input validation
  numberComplete$ = new BehaviorSubject<boolean>(false);
  cardExpiryComplete$ = new BehaviorSubject<boolean>(false);
  cardCvcComplete$ = new BehaviorSubject<boolean>(false);
  checkCardChangeCompleted$ = combineLatest([
    this.numberComplete$,
    this.cardExpiryComplete$,
    this.cardCvcComplete$,
  ]);

  // If input changes and whether it is valid
  @Output() cardInputChanged = new EventEmitter<boolean>();

  // If we get a valid card
  @Output() validCardChanged = new EventEmitter<any>();

  @Output() cardUpdate = new EventEmitter<any>();

  constructor(
    private ss: SettingsService,
    private stripeService: StripeService,
  ) {}

  createCardNumber() {
    this.cardNumber = this.stripeElements.create("cardNumber", {
      style: this.cardOptions.style,
    });
    this.cardNumber.mount("#card-number");
    const validate = (event) => {
      this.numberComplete$.next(event.complete);
    };
    this.cardNumber.on("change", validate);
  }

  createCardExpiry() {
    this.cardExpiry = this.stripeElements.create("cardExpiry", {
      style: this.cardOptions.style,
    });
    this.cardExpiry.mount("#card-expiry");
    const validate = (event) => {
      this.cardExpiryComplete$.next(event.complete);
    };
    this.cardExpiry.on("change", validate);
  }

  createCardCvc() {
    this.cardCvc = this.stripeElements.create("cardCvc", {
      style: this.cardOptions.style,
    });
    this.cardCvc.mount("#card-cvc");
    const validate = (event) => {
      this.cardCvcComplete$.next(event.complete);
    };
    this.cardCvc.on("change", validate);
  }

  initStripe() {
    if (this.readOnly) {
      return;
    }
    setTimeout(() => {
      this.stripeService
        .elements(this.elementsOptions)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (elements) => {
            this.stripeElements = elements;
            this.createCardNumber();
            this.createCardExpiry();
            this.createCardCvc();
          },
          error: (err) => {},
        });
    }, 500);
  }

  ngOnInit() {
    this.refreshCurrentCard();
    this.checkCardChangeCompleted$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([number, expiry, cvc]) => {
        this.cardInputChanged.emit(number && expiry && cvc);
      });
    if (this.editMode) {
      this.initStripe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  refreshCurrentCard() {
    this.ss
      .getCurrentCard()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          this.CURRENT_CARD = res;
          this.hasCardType = this.cardTypes.includes(this.CURRENT_CARD.brand);
          this.validCardChanged.emit(true);
        },
        error: (_err) => {
          this.CURRENT_CARD = null;
          // this.validCardChanged.emit(false);
          this.initStripe();
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }

  changeCard() {
    this.isChanging = true;
    this.validCardChanged.emit(false);
    this.initStripe();
  }

  updateCard(token) {
    // Get intent
    this.ss
      .getCardIntent()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (intentSecret) => {
          const stripeInstance = this.stripeService.getInstance() as any;

          stripeInstance
            .handleCardSetup(intentSecret.client_secret, {
              payment_method_data: {
                "card[token]": token.id,
              },
            })
            .then((result) => {
              // Handle result.error or result.setupIntent
              if (result.setupIntent) {
                this.ss
                  .completeCardIntent(result)
                  .pipe(takeUntil(this.unsubscribe$))
                  .subscribe((res) => {
                    this.isChanging = false;
                    this.refreshCurrentCard();
                  });
                return;
              }

              this.isSaving = false;
              if (result.error) {
                this.currentError = result.error.message;
              }
            });
        },
        error: (_err) => {
          this.isSaving = false;
        },
      });
  }

  saveCard() {
    if (!this.cardNumber) {
      return;
    }

    this.isSaving = true;

    this.stripeService
      .createToken(this.cardNumber, {})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          if (result.token) {
            this.currentError = null;
            this.updateCard(result.token);

            this.cardUpdate.emit();

            return;
          }

          this.isSaving = false;
          if (result.error) {
            this.currentError = result.error.message;
          }
        },
        error: (_err) => {
          this.isSaving = false;
        },
      });
  }
}
