<div
  class="flex flex-col flex-grow border-r border-gray-200 bg-white overflow-y-auto"
>
  <ng-content select="[top]" />

  <div
    class="flex mt-5 items-center flex-shrink-0 px-4 cursor-pointer"
    [routerLink]="homeRoute"
  >
    <img class="h-8 w-auto" src="assets/logo.svg" alt="Confect" />
  </div>

  <div class="mt-5 flex-grow flex flex-col">
    <nav class="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
      @for (item of _items; track item; let i = $index) {
        @if (!item.children) {
          <div>
            <!-- Current: "bg-gray-100 text-gray-900", Default: "bg-white text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
            <a
              (click)="itemSelected(item)"
              [class.bg-gray-100]="item.selected"
              [class.opacity-40]="item.enabled === false"
              tabindex="0"
              class="pl-2 py-2 group side-menu-item"
              [ngClass]="{
                'opacity-40 pointer-events-none': item.enabled === false,
              }"
            >
              <!-- Current: "text-gray-600", Default: "text-gray-400 group-hover:text-gray-500" -->
              <!-- Heroicon name: outline/home -->
              <confect-icon
                [icon]="item.icon"
                iconClass="mr-3 text-2xl {{
                  item.fire ? 'text-orange-600' : 'text-gray-500'
                }}"
              >
                <div class="flex items-center">
                  {{ item.title }}
                  @if (item.label) {
                    <span
                      class="px-1.5 text-sm pt-1 pb-0.5 text-white bg-primary bg-opacity-70 rounded-md ml-2"
                      >{{ item.label }}</span
                    >
                  }
                </div>
              </confect-icon>
            </a>
          </div>
        }
        @if (item.children) {
          <div class="space-y-1">
            <button
              class="pl-2 pr-1 py-2 group side-menu-item"
              (click)="item.expanded = !item.expanded"
              [ngClass]="{
                'opacity-40 pointer-events-none': item.enabled === false,
              }"
            >
              <confect-icon
                [icon]="item.icon"
                iconClass="text-gray-500 mr-3 text-2xl"
              >
                {{ item.title }}
              </confect-icon>
              <svg
                [ngClass]="{
                  'text-gray-400 rotate-90': item.expanded,
                  'text-gray-300': !item.expanded,
                }"
                class="text-gray-300 ml-auto h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
              </svg>
            </button>
            <!-- Expandable link section, show/hide based on state. -->
            @if (item.expanded) {
              <div class="space-y-1">
                @for (child of item.children; track child) {
                  <a
                    (click)="itemSelected(child)"
                    [class.bg-gray-100]="child.selected"
                    tabindex="0"
                    class="pl-8 pr-2 py-2 font-normal side-submenu-item"
                  >
                    <confect-icon
                      [icon]="child.icon"
                      iconClass="text-gray-500 mr-3 text-2xl"
                    >
                      {{ child.title }}
                    </confect-icon>
                  </a>
                }
              </div>
            }
          </div>
        }
      }
    </nav>
  </div>

  <ng-content />
</div>
