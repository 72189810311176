import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "ngx-agency-fb-empty-state",
  templateUrl: "./agency-fb-empty-state.component.html",
})
export class AgencyFbEmptyStateComponent implements OnInit {
  constructor(private router: Router) {}

  @Input() mode: "white" | "black" = "white";

  ngOnInit(): void {}

  go() {
    this.router.navigate(["/agency", "integrations"], {
      queryParams: { activate: "facebook_business" },
    });
  }
}
