import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  EventEmitter,
} from "@angular/core";

import { CustomFieldsService } from "@core/services/custom-fields.service";
import { Paginator } from "@core/services/paginator";

import { CSlideOverComponent } from "@theme/@confect/components/slide-over/slide-over.component";

import { Observable } from "rxjs";

export interface CreativePreviewerPageRequest {
  page: number;
  filters: any;
  previewMode: string;
  quality?: string;
  stateIndex?: number;
}

export interface CreativePreviewerContent {
  url: string;
  result_type: string;
}

export interface CreativePreviewerResult {
  results?: CreativePreviewerContent[];
  progress: number;
}

export interface CreativePreviewerInterface {
  nextPage(
    request: CreativePreviewerPageRequest,
  ): Observable<CreativePreviewerResult>;

  generateShareLinkClicked();
}

export interface CreativePreviewerSettings {
  autoLoadInitial?: boolean;
  reportMode?: boolean;
  openMode?: boolean;
  showShareButton?: boolean;
  qualityToggle?: boolean;
  showFilters?: boolean;
  selectedFilters?: any;
  creativePreviewMode?: string;
  showQualityMessage?: boolean;
}

@Component({
  selector: "ngx-creatives-previewer",
  templateUrl: "./creatives-previewer.component.html",
  styleUrls: ["./creatives-previewer.component.scss"],
})
export class CreativesPreviewerComponent
  implements OnInit, OnDestroy, OnChanges
{
  paginator = new Paginator<any>([], "identifier", "", null);

  @Input() previewerSettings: CreativePreviewerSettings = {};
  @Input() cols: number = 3;

  @ViewChild("creativesFilters")
  private creativesFilters: CSlideOverComponent;

  get delegate(): CreativePreviewerInterface {
    return this._delegate;
  }

  @Input()
  set delegate(to) {
    this._delegate = to;
    this.previewMode = this.defaultMode;
  }
  @Input() resolution;
  @Output() closeWindow = new EventEmitter();

  autoLoadInitial = false;
  reportMode = false;
  openMode = false;
  showShareButton = false;
  qualityToggle = false;
  showFilters = false;
  selectedFilters = {};
  creativePreviewMode = "default";
  stateIndex = 0;

  filterOptions = [];

  defaultMode = "all";
  previewMode = "all";
  quality = "low";

  // true in all cases used, it can be safely removed
  showPreviewMode = true;

  page = 0;
  isLoadingNextPage = false;
  shouldLoadNextPage = true;

  previewItems: any[] = [];

  showSearch = false;
  filterString = "";

  _delegate: CreativePreviewerInterface = null;

  currentProgress = 0;

  filterChange(to) {
    this.paginator.changeQuery(this.filterString);
  }

  constructor(private fs: CustomFieldsService) {}

  ngOnInit() {
    this.fs.getFields().subscribe((res) => {
      this.filterOptions = res;
    });

    setTimeout(() => {
      if (this.autoLoadInitial) {
        this.reload();
      }
    }, 50);
  }

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.previewerSettings) {
      Object.keys(changes.previewerSettings.currentValue).forEach(
        (val) => (this[val] = changes.previewerSettings.currentValue[val]),
      );
    }
  }

  loadNextPage() {
    this.isLoadingNextPage = true;
    this.page += 1;
    if (this._delegate == null) {
      return;
    }
    // Ask delegate for next page
    this._delegate
      .nextPage({
        page: this.page,
        filters: this.selectedFilters,
        previewMode: this.previewMode,
        quality: this.qualityToggle ? this.quality : null,
        stateIndex: this.stateIndex,
      })
      .subscribe(
        (result) => {
          if (result.results == null) {
            // Only update progress
            this.currentProgress = result.progress;
            return;
          }

          this.currentProgress = 0;
          const items = result.results;

          // No more items
          if (items.length === 0) {
            this.shouldLoadNextPage = false;
            return;
          }

          this.previewItems = this.previewItems.concat(items);
          this.paginator.updateData(this.previewItems);

          // Show search if we have videos
          const hasVideos =
            this.previewItems.filter((pi) => pi.result_type === "video")
              .length > 0;

          this.showSearch = hasVideos;
          this.showFilters = !hasVideos && this.showFilters;
        },
        (error) => {
          this.shouldLoadNextPage = false;
        },
        () => {
          this.isLoadingNextPage = false;
        },
      );
  }

  reload() {
    this.page = 0;
    this.previewItems = [];
    this.paginator.updateData([]);
    this.shouldLoadNextPage = true;
    this.loadNextPage();
  }

  showCreativesFilters() {
    this.creativesFilters.expand();
  }

  closeCreativesFilter() {
    this.creativesFilters.hide();
  }

  previewClick() {
    if (this.isLoadingNextPage) {
      return;
    }
    this.reload();
  }

  getNumberOfAppliedFilters(obj) {
    let num = 0;

    for (const property in obj) {
      if (obj[property].constructor.toString().indexOf("Array") != -1) {
        num += obj[property].length;
      } else if (typeof obj[property] === "object" && obj[property] !== null) {
        if (typeof this.getNumberOfAppliedFilters(obj[property]) == "number") {
          num += this.getNumberOfAppliedFilters(obj[property]);
        }
      } else if (
        typeof obj[property] === "boolean" ||
        typeof obj[property] === "string"
      ) {
        num++;
      } else {
        return num;
      }
    }

    return num;
  }
}
