import { Component, EventEmitter, Input, Output } from "@angular/core";

export interface CategoryMenuStructure {
  name: string;
  icon?: string;
  collapsed?: boolean;
  options: string[];
}

export interface CategoryOption {
  category: string;
  option: string;
}

@Component({
  selector: "confect-category-menu",
  templateUrl: "./category-menu.component.html",
})
export class CCategoryMenuComponent {
  _structure: CategoryMenuStructure[];

  @Input() collapsable: boolean = true;
  @Input() set structure(to: CategoryMenuStructure[]) {
    this._structure = to.map((val) => {
      if (val.collapsed == null) {
        val.collapsed = true;
        return val;
      }
      return val;
    });
  }
  @Input() currentOption: CategoryOption;

  @Output() select = new EventEmitter();

  categorySelect(category) {
    if (category.options.length === 0) {
      this.currentOption = { category: category.name, option: "" };
      this.select.emit({ category: category.name, option: "" });
      return;
    }
    if (!this.collapsable) {
      return;
    }
    const index = this._structure.findIndex(
      (value) => value.name === category.name,
    );
    this._structure[index].collapsed = !this._structure[index].collapsed;
  }

  optionSelect(category, option) {
    this.currentOption = { category: category.name, option: option };
    this.select.emit({ category: category.name, option: option });
  }
}
