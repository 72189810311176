import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  WritableSignal,
  computed,
  signal,
} from "@angular/core";

import { CMath, Orientation, Point } from "@core/utils/confect-math";

import { Basis, Pane, SplitSpec } from "../split-view.component";
import { SplitContentDirective } from "../split-view.directive";

@Component({
  selector: "[sub-view]",
  templateUrl: "./split-sub-view.component.html",
})
export class SplitSubViewComponent implements AfterViewInit {
  _spec: SplitSpec;
  @Input({ required: true }) set spec(to: SplitSpec) {
    if (this.afterViewInit) {
      this.reload();
    }
    this._spec = to;
    this.basis.update(() => {
      return {
        value: CMath.constrain(to.layout.basis, to.layout.min, to.layout.max),
        unit: to.layout.unit,
      };
    });

    this.offset.update(() => to.offset ?? 0);

    this.pane = to.layout.pane;
    this.disableDrag = !to.draggable;
    this.isVerticalSplit = to.orientation === Orientation.Vertical;

    if (typeof to.split.first === "string") {
      this.firstID = to.split.first;
    } else {
      this.first = to.split.first;
    }

    if (typeof to.split.second === "string") {
      this.secondID = to.split.second;
    } else {
      this.second = to.split.second;
    }

    if (to.layout.pane == Pane.First) {
      this.firstMin = `${to.layout.min}${to.layout.unit}`;
      this.firstMax = `${to.layout.max}${to.layout.unit}`;
      this.secondMax = `calc(100% - ${to.layout.min}${to.layout.unit})`;
      this.secondMin = `calc(100% - ${to.layout.max}${to.layout.unit})`;
    } else {
      this.secondMin = `${to.layout.min}${to.layout.unit}`;
      this.secondMax = `${to.layout.max}${to.layout.unit}`;
      this.firstMax = `calc(100% - ${to.layout.min}${to.layout.unit})`;
      this.firstMin = `calc(100% - ${to.layout.max}${to.layout.unit})`;
    }

    this.loading = false;
  }

  @Output() specChange = new EventEmitter<SplitSpec>();

  @ViewChild("handle") handle: ElementRef<any>;
  @ContentChild(SplitContentDirective, { read: TemplateRef })
  contentTemplate: TemplateRef<any>;

  loading: boolean = true;
  afterViewInit: boolean = false;

  isVerticalSplit: boolean;
  first: SplitSpec;
  firstID: string;
  second: SplitSpec;
  secondID: string;
  basis: WritableSignal<Basis> = signal({ value: 0, unit: "px" });
  offset: WritableSignal<number> = signal(0);
  firstBasis: Signal<string> = computed(
    () =>
      `calc((${this.pane === Pane.Second ? "100% - " : ""}${this.basis().value}${this.basis().unit}) + ${this.offset()}px)`,
  );
  secondBasis: Signal<string> = computed(
    () =>
      `calc(100% - ((${this.pane === Pane.Second ? "100% - " : ""}${this.basis().value}${this.basis().unit}) + ${this.offset()}px))`,
  );
  disableDrag: boolean = true;
  pane: Pane = Pane.First;

  handleOrigin: number = 0;

  firstMin: string;
  firstMax: string;
  secondMin: string;
  secondMax: string;

  constructor(private viewRef: ViewContainerRef) {}

  startPos: number = 0;

  ngAfterViewInit(): void {
    this.afterViewInit = true;
    this.reload();
  }

  reload() {
    const bounding = this.handle.nativeElement.getBoundingClientRect();
    this.handleOrigin = this.isVerticalSplit
      ? (bounding.left + bounding.right) / 2
      : (bounding.top + bounding.bottom) / 2;
  }

  start = (pos: Point) => {
    this.startPos = pos[this.isVerticalSplit ? "x" : "y"] - this.handleOrigin;
  };

  move = (pos: Point) => {
    this.offset.update(
      () => this.startPos + pos[this.isVerticalSplit ? "x" : "y"],
    );
  };

  stop = () => {
    const copy: SplitSpec = JSON.parse(JSON.stringify(this._spec));
    copy.offset = this.offset();
    this.specChange.emit(copy);
  };
}
