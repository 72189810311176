<ng-container>
  <div class="mx-auto max-w-4xl py-4 px-4">
    <div class="overflow-hidden grid grid-cols-2">
      <div class="px-6 pt-14">
        <div class="lg:self-center flex flex-col space-y-8">
          <h2
            class="text-2xl font-bold tracking-tight"
            [ngClass]="{
              'text-white': mode === 'white',
              'text-black': mode === 'black',
            }"
          >
            Connect your Agency Facebook Business Manager
          </h2>
          <p
            class="mt-4 text-lg leading-6 text-white"
            [ngClass]="{
              'text-white': mode === 'white',
              'text-black': mode === 'black',
            }"
          >
            It’s free - and you will gain valuable analytics insights for all of
            your clients.
          </p>
          <confect-button size="xxlarge" (click)="go()"
            >Integrate now</confect-button
          >
        </div>
      </div>
      <div class="flex flex-col justify-center items-center h-full">
        <div class="aspect-w-5 aspect-h-3 -mt-6 md:aspect-w-2 md:aspect-h-1">
          <img
            class="object-contain"
            src="/assets/images/onboarding/fb-connection-agency.png"
          />
        </div>
      </div>
    </div>
  </div>
</ng-container>
