import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface HeaderInfoInterface {
  title: string;
  subtitle?: string;
  primaryButton?: string;
  secondaryButton?: string;
  primaryDisable?: boolean;
  secondaryDisable?: boolean;
}

@Component({
  selector: "confect-card-header",
  template: "<ng-content />",
})
export class CCardHeaderComponent {}

@Component({
  selector: "confect-card-acc",
  template: "<ng-content />",
})
export class CCardAccComponent {}

@Component({
  selector: "confect-card-body",
  template: "<ng-content />",
})
export class CCardBodyComponent {}

@Component({
  selector: "confect-card-footer",
  template: "<ng-content />",
})
export class CCardFooterComponent {}

@Component({
  selector: "confect-card",
  template: `
    <div
      class="divide-y divide-gray-200 overflow-hidden bg-white"
      [ngClass]="{
        'shadow sm:rounded-md': !withoutBorders,
        'divide-y': !withoutDivide,
        'h-full overflow-y-auto': fullSize,
      }"
    >
      <div
        class="header"
        [ngClass]="{
          'bg-white px-4 py-5 sm:px-6': !customHeader && headerInfo == null,
          'h-full': fullSize,
        }"
      >
        @if (headerInfo != null) {
          <confect-card-header>
            <div
              class="flex justify-between items-center px-5 py-3 min-h-[60px]"
            >
              <div class="flex flex-col items-left">
                <h2 class="text-left">{{ headerInfo.title }}</h2>
                <h6 class="text-gray-500 text-sm pl-0.5  text-left">
                  {{ headerInfo.subtitle }}
                </h6>
              </div>
              <div class="flex space-x-3">
                @if (headerInfo.secondaryButton != null) {
                  <confect-button
                    (click)="secondary.emit()"
                    type="white"
                    [disabled]="headerInfo.secondaryDisable ?? false"
                    >{{ headerInfo.secondaryButton }}</confect-button
                  >
                }
                @if (headerInfo.primaryButton != null) {
                  <confect-button
                    (click)="primary.emit()"
                    type="primary"
                    [disabled]="headerInfo.primaryDisable ?? false"
                    >{{ headerInfo.primaryButton }}</confect-button
                  >
                }
              </div>
            </div>
          </confect-card-header>
        }
        @if (headerInfo == null) {
          <ng-content select="confect-card-header" />
        }
      </div>
      <div
        class="acc bg-white "
        [ngClass]="{ 'px-4 sm:px-6': !withoutPadding }"
      >
        <ng-content select="confect-card-acc" />
      </div>
      <div
        [ngClass]="{ 'px-4 py-5 sm:px-6': !withoutPadding, 'h-full': fullSize }"
      >
        <ng-content select="confect-card-body" />
      </div>
      <div class="footer" [ngClass]="{ 'px-4 py-5 sm:px-6': !withoutPadding }">
        <ng-content select="confect-card-footer" />
      </div>
    </div>
  `,
  styleUrls: ["./card.component.scss"],
})
export class CCardComponent implements OnInit {
  @Input() withoutBorders = false;
  @Input() customHeader = false;
  @Input() withoutDivide = false;
  @Input() withoutPadding = false;
  @Input() headerInfo: HeaderInfoInterface | null;
  @Input() fullSize = false;

  @Output() primary = new EventEmitter();
  @Output() secondary = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
