<div class="intro flex flex-col items-center p-4 space-y-8">
  <img
    class="h-12"
    src="assets/images/logo-full.svg"
    style="height: 100%; display: block; margin: 0 auto"
  />

  @if (company != null) {
    <h5 class="mx-auto mt-8 pb-12">
      @if (company.trial.ended === false) {
        Add your billing information to start your
        {{ featureName ? featureName : "" }} trial
      }
    </h5>
  }

  <div class="w-full px-12">
    <h6 class="mb-4">Account Information</h6>
    <div>
      <ngx-billing-settings-form
        #accountForm
        (formChanged)="updateStatus()"
        (formSaved)="startTrialCallback($event)"
        [showSave]="false"
        [signup]="true"
      />
    </div>
  </div>

  <ngx-stripe-card-input
    #cardForm
    [hideSave]="hideCardSave"
    (cardInputChanged)="cardInputChanged($event)"
    (validCardChanged)="validCardChanged($event)"
    class="w-full max-w-screen-md"
  >
    @if (company?.trial.info === false) {
      <h6 class="mb-3">
        Please note that you will not be billed until the end of your trial.
      </h6>
    }
  </ngx-stripe-card-input>

  <div class="max-w-screen-md pb-4">
    <confect-button
      [disabled]="!readyStatus || isLoading"
      size="xlarge"
      (click)="startTrial()"
    >
      Start free{{ featureName ? " " + featureName + " " : " " }}trial
    </confect-button>
  </div>

  <div>
    NOTE: Nothing will be billed from your card until the end of the free trial.
  </div>
</div>

<confect-modal #modal>
  <confect-modal-success #modalSuccess (modalClosed)="modal.hide()" />
</confect-modal>
