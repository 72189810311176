import { Component, Input, OnInit } from "@angular/core";

const shuffle = <T>(array: T[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function getRandomTimer(min: number, max: number): number {
  return Math.round(min + Math.random() * (max - min));
}

const timer = (ms) => new Promise((res) => setTimeout(res, ms));

@Component({
  selector: "confect-loading",
  templateUrl: "./loading.component.html",
  styleUrls: ["./loading.component.scss"],
})
export class CLoadingComponent implements OnInit {
  _loading = false;
  _dynamicText: string[] = [];
  textQueue: string[] = [];
  static_text = "Loading...";
  _text = "Loading...";
  show = false;

  switchTimeout: NodeJS.Timeout;
  @Input() set dynamicText(to) {
    this._dynamicText = to;
  }

  @Input() set loading(to: boolean) {
    this._loading = to;

    if (this.switchTimeout) {
      clearTimeout(this.switchTimeout);
    }

    if (to) {
      setTimeout(() => {
        this.startLoading();
      });
    } else if (this._dynamicText) {
      this.show = false;
      this._text = "";
    }
  }
  @Input() width = 80;
  @Input() set text(to) {
    if (to == null) {
      return;
    }
    this.static_text = to;
  }

  constructor() {}

  ngOnInit(): void {}

  async startLoading() {
    await timer(100);
    if (!this._loading) {
      return;
    }
    this.show = true;
    if (!this._dynamicText) {
      this._text = this.static_text;
      return;
    }

    this.textQueue = shuffle(JSON.parse(JSON.stringify(this._dynamicText)));
    this.loadIndicator();
  }

  loadIndicator() {
    if (this.textQueue.length === 0) {
      this._text = this.static_text;
      return;
    }
    this._text = this.textQueue.pop();
    this.switchTimeout = setTimeout(
      () => {
        this.loadIndicator();
      },
      getRandomTimer(2500, 6000),
    );
  }
}
