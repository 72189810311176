import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { CompanyAccessInfo, Me } from "../api-models/users.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class UsersService extends ApiService {
  getMe(): Observable<Me> {
    return this.http.get<Me>(this.endpoint + "/users/me");
  }

  pingUser(): Observable<any> {
    return this.http.get(`${this.endpoint}/users/ping`);
  }

  getUserAccess(): Observable<CompanyAccessInfo[]> {
    return this.http.get<CompanyAccessInfo[]>(`${this.endpoint}/users/access`);
  }

  updateUserAccess() {
    this.getUserAccess().subscribe((access) => {
      this.accessService.updateAvailable(access);
    });
  }

  changeName(name): Observable<any> {
    const userName = { name };
    return this.http.patch(this.endpoint + "/users/me", userName);
  }

  changeEmail(email): Observable<any> {
    const emailObj = { email };
    return this.http.post(this.endpoint + "/users/change-email", emailObj);
  }

  changePassword(passwords): Observable<any> {
    return this.http.post(this.endpoint + "/users/change-password", passwords);
  }
}
