import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { fromEvent, throttleTime, filter } from "rxjs";

@Directive({
  selector: "[infiniteScroll]",
})
export class InfiniteScrollDirective implements AfterViewInit {
  @Input() scrollArgument = 0.4;
  @Input() scrollThrottle = 300;
  @Output("infiniteScroll") scrolled: EventEmitter<HTMLElement> =
    new EventEmitter<any>();

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, "scroll")
      .pipe(
        filter(({ target: { scrollTop, scrollHeight, clientHeight } }) => {
          return (
            scrollTop + clientHeight >=
            scrollHeight - clientHeight * Number(this.scrollArgument)
          );
        }),
        throttleTime(Number(this.scrollThrottle)),
      )
      .subscribe(({ target }) => this.scrolled.emit(target as HTMLElement));
  }
}
