import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "confect-modal-success",
  templateUrl: "./modal-success.component.html",
})
export class CModalSuccessComponent implements OnInit {
  @Input() modalSuccess = false;
  @Input() modalTitle = "";
  @Input() showModalText = false;
  @Input() modalText = "";
  @Input() buttonText = "";
  @Output() modalClosed = new EventEmitter();

  start = true;
  end = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.start = false;
      this.end = true;
    }, 100);
  }

  setupModal(
    success: boolean,
    showText: boolean,
    title?: string,
    text?: string,
    buttonText: string = "OK",
  ) {
    this.modalSuccess = success;
    this.showModalText = showText;
    this.modalTitle = title;
    this.modalText = text;
    this.buttonText = buttonText;
  }

  closeModal() {
    this.modalClosed.emit(true);
  }
}
