import { Component, Input } from "@angular/core";

@Component({
  selector: "[fullscreen]",
  templateUrl: "./show-fullscreen-wrapper.component.html",
  styleUrls: ["./show-fullscreen-wrapper.component.scss"],
})
export class ShowFullscreenWrapperComponent {
  @Input() backgroundColor = "#fff";
  @Input() allowOverflow = false;
}
