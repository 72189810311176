import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "[confect-accordion-item]",
  templateUrl: "./accordion-item.component.html",
})
export class CAccordionItemComponent implements OnInit {
  expanded = false;

  @Input() set expandInitial(to: boolean) {
    this.expanded = to;
  }

  constructor() {}

  ngOnInit(): void {}
}
