<div
  class="modal-full-container"
  [class.hidden]="hidden"
  [hidden]="hidden"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <!--
  Background overlay, show/hide based on modal state.

  Entering: "ease-out duration-300"
  From: "opacity-0"
  To: "opacity-100"
  Leaving: "ease-in duration-200"
  From: "opacity-100"
  To: "opacity-0"
  -->
  <div
    class="fixed w-full h-full inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
    aria-hidden="true"
    [ngClass]="{
      'ease-out duration-300 opacity-100': shown,
      'ease-in duration-200 opacity-0': !shown,
    }"
    (click)="interupt()"
  ></div>

  <!-- This element is to trick the browser into centering the modal contents. -->
  <span
    class="hidden sm:inline-block sm:align-middle sm:h-screen"
    aria-hidden="true"
    >&#8203;</span
  >

  <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
    From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
    From: "opacity-100 translate-y-0 sm:scale-100"
    To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
  <div
    class="bg-white modal-content-container rounded-md overflow-x-hidden inline-block align-bottom text-left transform transition-all sm:my-8 sm:align-middle"
    [ngClass]="{
      'opacity-100 translate-y-0 sm:scale-100': shown,
      'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !shown,
      wider: wider,
      'p-8 px-4 pt-5 pb-4 sm:p-6': !noPadding,
      'overflow-y-auto': !disableScroll,
      'overflow-y-hidden': disableScroll,
    }"
    [style.width]="width"
  >
    <ng-content />

    <!--
      The tag(ng-template) mustn't have any structural directive used in its parents.
      So it should present here at the first moment of the creation of the instance of this component.
      Otherwise it can't be found and initialised with @ViewChild.
      -->
    <ng-template #contentTarget />
    <div class="mt-3 flex flex-row-reverse">
      @if (primaryButtonText) {
        <confect-button type="primary">
          {{ primaryButtonText }}
        </confect-button>
      }
      @if (secondaryButtonText) {
        <confect-button type="secondary">
          {{ secondaryButtonText }}
        </confect-button>
      }
    </div>
  </div>
</div>
