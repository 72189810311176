import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AutomationInsights } from "@core/api-models/automation.types";

import { environment } from "environments/environment";
import { Observable, of } from "rxjs";

import {
  AnalyticsCatalogColumn,
  AnalyticsExtensionInfo,
  AnalyticsInfo,
  AnalyticsIntroOutput,
  AnalyticsSearchPayload,
  AnalyticsSearchPayloadV2,
  AnalyticsSearchResult,
  AnalyticsSearchResultV2,
  BenchmarkPayload,
  BenchmarkResponse,
  CatalogAnalyticsDP,
  DataStudioPageFilters,
  DataStudioRenderedPage,
  SearchFilterCategory,
} from "../api-models/analytics.models";
import {
  AdLookupResponse,
  AnalyticsCampaignObjective,
  FacebookAdsInformation,
  PredictionFilter,
} from "../models/analytics.types";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AnalyticsService extends ApiService {
  getAnalyticsInfo(): Observable<AnalyticsInfo> {
    return this.http.get<AnalyticsInfo>(`${this.endpoint}/analytics/info`);
  }

  getAgencyAnalyticsInfo(uuid: string): Observable<AnalyticsInfo> {
    return this.http.get<AnalyticsInfo>(
      `${this.endpoint}/analytics/info/${uuid}`,
    );
  }

  getAnalyticsObjectives(embed: string) {
    return this.http.get<string[] | null>(
      `${this.endpoint}/analytics/objectives?uuid=${embed}`,
    );
  }

  getAnalyticsIntro() {
    return this.http.get<AnalyticsIntroOutput>(
      `${this.endpoint}/analytics/intro`,
    );
  }

  getCatalogAnalytics(column: AnalyticsCatalogColumn, numDays: number) {
    return this.http.get<CatalogAnalyticsDP[]>(
      `${this.endpoint}/analytics/catalog_analytics`,
      {
        params: new HttpParams({
          fromObject: { column: column, n_days: numDays },
        }),
      },
    );
  }

  notifyAnalytics(adaccount: string = null): Observable<{ success: boolean }> {
    let prefix = "";
    if (adaccount) {
      prefix = `?adaccount=${adaccount}`;
    }
    return this.http.get<{ success: boolean }>(
      `${this.endpoint}/analytics/notify${prefix}`,
    );
  }

  getAnalyticsExtensionInfo(
    payload: FacebookAdsInformation,
  ): Observable<AnalyticsExtensionInfo> {
    return this.http.post<AnalyticsExtensionInfo>(
      `${this.endpoint}/analytics/extension-info`,
      payload,
    );
  }

  getAnalyticsDPAOverview(): Observable<AutomationInsights> {
    return this.http.get<AutomationInsights>(
      `${this.endpoint}/analytics/automation_state_insights`,
    );
  }

  getFakeAnalyticsInfo(): Observable<AnalyticsInfo> {
    return of({
      name: "Test",
      embed: "8899ef6b-1cf4-46bf-ab9f-d8b8b81acfdf",
      requested: true,
    });
  }

  getAnalyticsFBAdLookup(info: FacebookAdsInformation) {
    // return of({
    //   summary: {
    //     objective: "Conversions",
    //     action: "Purchases",
    //     stats: {
    //       rate: 0.4,
    //       design: 0.1,
    //       message: 0.8,
    //     },
    //   },
    //   campaigns: {
    //     "23851962800230269": {
    //       id: "23851962800230269",
    //       name: "Campaign A",
    //       objective: "Conversions",
    //       action: "Purchases",
    //       stats: {
    //         rate: 0.4,
    //         design: 0.1,
    //         message: 0.8,
    //       },
    //     },
    //   },
    //   // 'adsets': null,
    //   // 'ads': null
    // } as AdLookupResponse);

    return this.http.post<AdLookupResponse>(
      `${this.endpoint}/analytics/lookup-info`,
      info,
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class PredictAnalyticsService {
  endpoint = environment.PredictiveEndpoint;

  constructor(protected http: HttpClient) {}

  getPrediction(
    embed: string,
    file: File | ArrayBuffer,
    filters: PredictionFilter,
  ) {
    const formData = new FormData();

    let input: File | Blob = file as any;
    if (file instanceof ArrayBuffer) {
      input = new Blob([file]);
    }

    formData.append("file", input);
    formData.append("query", JSON.stringify(filters));

    return this.http.post<any>(
      `${this.endpoint}/predict/v2/${embed}`,
      formData,
    );
  }

  getURLPrediction(imageURL: string) {
    return this.http.get<any>(`${this.endpoint}/predict/feature_extract`, {
      params: new HttpParams({
        fromObject: {
          image: imageURL,
        },
      }),
    });
  }

  // getPrediction(file: File, filters: PredictionFilter): Observable<any> {
  //   return this.analytics.getAnalyticsInfo().pipe(
  //     tap((res) => {
  //       if (res.embed == null) {
  //         throw new Error("No analytics embed found");
  //       }
  //     }),
  //     switchMap((res) => this.loadPrediction(file, res.embed, filters))
  //   );
  // }
  getCategories(embed: string): Observable<SearchFilterCategory[]> {
    return this.http.get<SearchFilterCategory[]>(
      `${this.endpoint}/search/${embed}/categories`,
    );
  }
  getSingleContentPage(
    embed: string,
    mediaID: string | number,
    campaign_objective: AnalyticsCampaignObjective,
  ): Observable<any> {
    const p = new HttpParams({
      fromObject: {
        campaign_objective: campaign_objective,
      },
    });

    return this.http.get(
      `${this.endpoint}/dashboards/${embed}/single_content/${mediaID}`,
      { params: p },
    );
  }

  getMultiContentPage(
    embed: string,
    media: string,
    campaign_objective: AnalyticsCampaignObjective,
  ): Observable<any> {
    const p = new HttpParams({
      fromObject: {
        media: media,
        campaign_objective: campaign_objective,
      },
    });

    return this.http.get(
      `${this.endpoint}/dashboards/${embed}/multi_content_page`,
      {
        params: p,
      },
    );
  }

  getContentOverview(
    embedUUID: string,
    campaign_objective: AnalyticsCampaignObjective,
  ): Observable<any> {
    const p = new HttpParams({
      fromObject: {
        campaign_objective: campaign_objective,
      },
    });

    return this.http.get(
      `${this.endpoint}/dashboards/${embedUUID}/analytics_homepage`,
      { params: p },
    );
  }

  getAnalyticsSearch(
    embedUUID: string,
    payload: AnalyticsSearchPayload,
  ): Observable<AnalyticsSearchResult[]> {
    return this.http.post<AnalyticsSearchResult[]>(
      `${this.endpoint}/search/${embedUUID}`,
      payload,
    );
  }

  getAnalyticsSearchV2(
    embedUUID: string,
    payload: AnalyticsSearchPayloadV2,
  ): Observable<AnalyticsSearchResultV2> {
    return this.http.post<AnalyticsSearchResultV2>(
      `${this.endpoint}/search/v2/${embedUUID}`,
      payload,
    );
  }

  getAnalyticsBenchmark(
    embedUUID: string,
    payload: BenchmarkPayload,
  ): Observable<BenchmarkResponse> {
    return this.http.post<BenchmarkResponse>(
      `${this.endpoint}/benchmarks/${embedUUID}`,
      payload,
    );
  }

  getDataStudioPage(
    embedUUID: string,
    pageID: string,
    filters: DataStudioPageFilters | null = null,
    metric: string | null = null,
    dateGroup: string | null = null,
    campaign_objective: AnalyticsCampaignObjective | null = null,
  ) {
    return this.http.post<DataStudioRenderedPage>(
      `${this.endpoint}/data-studio/page`,
      {
        uuid: embedUUID,
        page_id: pageID,
        campaign_objective: campaign_objective,
        filters: filters,
        metric: metric,
        date_group: dateGroup,
      },
    );
  }

  getDataStudioFilters() {
    return this.http.get(`${this.endpoint}/data-studio/filters`);
  }

  getDatStudioFilterOptions(embedUUID: string, filters: DataStudioPageFilters) {
    return this.http.post(`${this.endpoint}/data-studio/filters/options`, {
      uuid: embedUUID,
      filters: filters,
    });
  }
}

export const displayFilterNames = {
  campaign_objective: {
    display: "Campaign Objective",
  },
  country_targeted: { display: "Targeted Country" },
  layout: { display: "Layout" },
  has_face: { display: "Face in image" },
  ocr_text_contains_numbers: {
    display: "Text contains numbers",
  },
  primary_saturated_color: {
    display: "Primary Saturated Color",
  },
  primary_color: { display: "Primary Color" },
  ocr_text_categories: { display: "Text Category" },
  media_type: { display: "Media Type" },
  temperature: { display: "Temperature" },
  saturation: { display: "Saturation" },
  brightness: { display: "Brightness" },
  ad_name: { display: "Ad Name" },
  adset_name: { display: "Adset Name" },
  campaign_name: { display: "Campaign Name" },
};

const deltaTexts = [
  "Much better",
  "Better",
  "A bit better",
  "No change",
  "A bit worse",
  "Worse",
  "Much worse",
];

const deltaColors = [
  "#006837",
  "#3ea758",
  "#a8d85b",
  "#cacaca",
  "#fcb357",
  "#e65035",
  "#a50126",
];
const deltaConditionals = [1, 0.45, 0.15, -0.1, -0.25, -0.5].reverse();

const scoreConditionals = [
  {
    rankValue: 0.8,
    avgValue: 1,
    color: "#006837",
    word: "very good",
  },
  {
    rankValue: 0.6,
    avgValue: 0.3,
    color: "#3EA758",
    word: "good",
  },
  {
    rankValue: 0.4,
    avgValue: -0.15,
    color: "#A6A6A6",
    word: "average",
  },
  {
    rankValue: 0.2,
    avgValue: -0.5,
    color: "#E65035",
    word: "bad",
  },
  {
    rankValue: 0.0,
    avgValue: -999,
    color: "#A50126",
    word: "very bad",
  },
];

export function determineScoreCond(rank) {
  return scoreConditionals.find((c) => rank >= c.rankValue);
}

export function determineAvgCond(avg) {
  return scoreConditionals.find((c) => avg >= c.avgValue);
}

export function determineImprovementStep(improvement) {
  if (improvement > 1) {
    return deltaConditionals.length - 1;
  }
  return deltaConditionals.findIndex((lim) => improvement <= lim);
}

export function determineDeltaColor(delta) {
  return deltaColors[determineImprovementStep(delta)];
}

export function determineImprovementText(improvement) {
  return deltaTexts[determineImprovementStep(improvement)];
}

export const ALL_ANALYTICS_CAMPAIGN_OBJECTIVES = [
  AnalyticsCampaignObjective.CONVERSIONS,
  AnalyticsCampaignObjective.LINK_CLICKS,
  AnalyticsCampaignObjective.LEAD_GENERATION,
  AnalyticsCampaignObjective.APP_INSTALLS,
  AnalyticsCampaignObjective.POST_ENGAGEMENT,
];

export const ANALYTICS_CAMPAIGN_OBJECTIVE_TO_DISPLAY = {
  [AnalyticsCampaignObjective.CONVERSIONS]: "Purchases",
  [AnalyticsCampaignObjective.LINK_CLICKS]: "Clicks",
  [AnalyticsCampaignObjective.LEAD_GENERATION]: "Leads",
  [AnalyticsCampaignObjective.APP_INSTALLS]: "App Installs",
  [AnalyticsCampaignObjective.POST_ENGAGEMENT]: "Post Engagement",
};

export function checkHasChromeExtension() {
  return (
    document.documentElement.getAttribute("extension-installed") === "true"
  );
}
