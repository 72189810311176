import { Component, Input } from "@angular/core";

export interface StepInfo {
  buttonText?: string;
  title?: string;
  step: number;
}

@Component({
  selector: "confect-stepper",
  templateUrl: "./stepper.component.html",
})
export class CStepperComponent {
  @Input() pages: number = 1;

  CURRENT_STEP: number = 1;

  constructor() {}

  next() {
    this.CURRENT_STEP = Math.min(this.CURRENT_STEP + 1, this.pages);
  }
  back() {
    this.CURRENT_STEP = Math.max(this.CURRENT_STEP - 1, 0);
  }
}
