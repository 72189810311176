import { Injectable } from "@angular/core";

import {
  ModalFullOptions,
  ModalOptions,
  ModalRef,
} from "@theme/@confect/models/modal.types";

import { Observable, Subject, switchMap, tap } from "rxjs";

@Injectable({ providedIn: "root" })
export class CModalService {
  /**
   * All the necessary data about the modal which should be opened is being
   * sent to the outlet-modal component through this subject.
   */
  private modalsSubject: Subject<ModalFullOptions> =
    new Subject<ModalFullOptions>();

  public get modalObservable(): Observable<ModalFullOptions> {
    return this.modalsSubject.asObservable();
  }

  /** Emits new modal data to open a modal. */
  open<C>(opts: ModalOptions): ModalRef {
    const modalRef: ModalRef = {
      modalOptions: opts,
      outputs: {},
    } as ModalRef;

    this.modalsSubject.next({
      ...opts,
      modalRef,
    });

    const closeOut = modalRef.outputs["modalClosed"];
    if (closeOut != null) {
      let newObs = opts.preConfirm
        ? closeOut.pipe(switchMap((res) => opts.preConfirm(res)))
        : closeOut;

      newObs = newObs.pipe(
        tap(() => {
          modalRef.close();
        }),
      );

      modalRef.outputs["preConfirmed"] = newObs;

      if (!opts.preConfirm) {
        newObs.subscribe();
      }
    }

    return modalRef;
  }
}
