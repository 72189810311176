import { Injectable } from "@angular/core";

import { AnalyticsSearchResult } from "@core/api-models/analytics.models";

@Injectable({
  providedIn: "root",
})
export class MediaCompareService {
  compareMedia: AnalyticsSearchResult[] = [];
  compareIDs = new Set<string>([]);
  show = false;

  constructor() {}

  add(item: AnalyticsSearchResult) {
    this.compareMedia.push(item);
    this.compareIDs.add(item.id);
  }

  clear() {
    this.compareMedia = [];
    this.compareIDs = new Set<string>([]);
  }

  remove(index: number) {
    if (this.compareMedia.length === 1) {
      this.compareMedia = [];
      this.compareIDs = new Set<string>([]);
      this.show = false;
    } else {
      this.compareIDs.delete(this.compareMedia[index].id);
      this.compareMedia.splice(index, 1);
    }
  }
}
