import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AccessService } from "@core/services/access.service";
import { CAuthService } from "@core/services/auth.service";
import { HotjarService } from "@core/services/hotjar.service";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Observable, forkJoin, of, tap } from "rxjs";

import {
  MediaLibraryItem,
  UploadMediaResponseInterface,
} from "../api-models/media.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class MediaService extends ApiService {
  getMedia(
    source,
    page,
    per_page,
    scope,
    searchString,
  ): Observable<MediaLibraryItem[]> {
    const params = {
      source: source,
      page: page,
      per_page: per_page,
      scope: scope,
      full_search_text: searchString,
    };
    return this.http.get<MediaLibraryItem[]>(this.endpoint + "/media", {
      params,
    });
  }

  constructor(
    http: HttpClient,
    auth: CAuthService,
    access: AccessService,
    hj: HotjarService,
    private popupService: CPopupModalService,
  ) {
    super(http, auth, access, hj);
  }

  createMedia(media, source): Observable<any> {
    return this.http.post(this.endpoint + "/media", {
      media: media,
      source: source,
    });
  }

  private determineMediaSource(file) {
    // Get image/ and video/
    const ft = file["type"];

    if (ft.includes("/") && ft.includes("image")) {
      return "image";
    }

    if (ft.includes("/") && ft.includes("video")) {
      return "video";
    }

    // get woff
    if (ft.includes("/") && ft.includes("font")) {
      return "font";
    }

    // If we haven't returned yet, we turn to checking the file ext instead of MIME
    const type = file["name"].split(".").pop();
    const fonts = new Set(["ttf", "otf", "woff", "woff2", "eot"]);
    const images = new Set(["jpg", "jpeg", "svg", "png", "gif", "tiff"]);
    const videos = new Set(["mp4", "wmv", "avi", ""]);
    if (fonts.has(type)) {
      return "font";
    } else if (images.has(type)) {
      return "image";
    } else if (videos.has(type)) {
      return "video";
    }
  }

  uploadMediaFile(
    file,
    source = null,
  ): Observable<UploadMediaResponseInterface> {
    let s = source;

    if (!s) {
      const mediaTypeToSource = {
        font: "uploaded_private_fonts",
        image: "uploaded_images",
        video: "uploaded_videos",
      };

      s = mediaTypeToSource[this.determineMediaSource(file)];
    }

    return new Observable((observer) => {
      function error(err) {
        observer.error(err);
      }

      const uncreatedMedia = { name: file["name"] };
      this.createMedia(uncreatedMedia, s).subscribe(
        (media) => {
          this.putFileToS3(file, media.presigned_url).subscribe(
            (_) => {
              this.updateMedia(media.id, {
                media: { active: true },
                source: media.source,
              }).subscribe(
                (_0) => {
                  // Queue thumbnail and emit result, then complete observable
                  this.queueMediaThumbail(media.id, media.source).subscribe();
                  observer.next({ source: media.source, id: media.id });
                  observer.complete();
                },
                (err) => error(err),
              );
            },
            (err) => error(err),
          );
        },
        (err) => error(err),
      );
    });
  }

  uploadMediaFileInteractive(fileTypes, type, multiple = true, source = null) {
    const preConfirm = (files) => {
      if (!files) {
        return of(null);
      }
      const observables = files.map((f) => this.uploadMediaFile(f, source));
      return forkJoin(observables).pipe(
        tap({
          next: (res) => {
            this.popupService.success({
              title: "Upload successful!",
              autoCloseTimeout: 1500,
            });
          },
          error: (err) => {
            this.popupService.error({ autoCloseTimeout: 1500 });
          },
        }),
      );
    };

    const uploadModal = this.popupService.upload({
      title: `Upload ${type}`,
      buttonText: `Select ${type}`,
      uploadText: "Drag file here or select from local directory.",
      fileTypes: fileTypes,
      multiple: multiple,
      preConfirm: preConfirm,
    });

    return uploadModal.outputs["preConfirmed"];
  }

  // return uploadModal.outputs["modalClosed"].asObservable().pipe(
  //   switchMap((files) => {
  //     if (!files) {
  //       return of(null).pipe();
  //     }
  //     const observables = files.map((f) => this.uploadMediaFile(f, source));
  //     return forkJoin(observables).pipe(
  //       tap({
  //         next: (res) => {
  //           this.popupService.success({
  //             title: "Upload successful!",
  //             autoCloseTimeout: 1500,
  //           });
  //         },
  //         error: (err) => {
  //           this.popupService.error({ autoCloseTimeout: 1500 });
  //         },
  //       }),
  //     );
  //   }),
  // );

  updateMedia(mediaID, settings): Observable<any> {
    return this.http.post(this.endpoint + "/media/" + mediaID, settings);
  }

  deactivateMedia(mediaID, source): Observable<any> {
    const options = this.params({ source: source });
    return this.http.delete(this.endpoint + "/media/" + mediaID, options);
  }

  getMediaById(mediaID, source): Observable<any> {
    return this.http.get(this.endpoint + `/media/${mediaID}?source=${source}`);
  }

  getMediaByFolder(folderID, mediaType): Observable<any> {
    return this.http.get(
      this.endpoint +
        `/media/by_folder?media_type=${mediaType}${
          folderID != null ? `&parent_folder_id=${folderID}` : ""
        }`,
    );
  }

  availableMediaSources(): Observable<any> {
    return this.http.get(this.endpoint + "/media/available-sources");
  }

  searchExternalMediaSource(source, query, page, per_page): Observable<any> {
    const params = {
      source: source,
      query: query,
      page: page,
      per_page: per_page,
    };
    return this.http.get(this.endpoint + "/media/search", { params });
  }

  suggestedExternalMediaSource(source): Observable<any> {
    const params = {
      source: source,
    };
    return this.http.get(this.endpoint + "/media/suggested", { params });
  }

  queueMediaThumbail(mediaId, source): Observable<any> {
    return this.http.post(
      this.endpoint + "/media/" + mediaId + "/queue-thumbnail",
      { source: source },
    );
  }

  mediaThumbRendered(mediaId, source): Observable<any> {
    const params = {
      source: source,
    };
    return this.http.get(
      this.endpoint + "/media/" + mediaId + "/thumbnail-rendered",
      { params },
    );
  }

  mediaTriggerDownload(mediaId, source): Observable<any> {
    const params = {
      source: source,
    };
    return this.http.get(
      this.endpoint + "/media/" + mediaId + "/trigger-download",
      { params },
    );
  }
}
