import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "subfieldDisplay", pure: true })
export class SubfieldPipe implements PipeTransform {
  transform(input: string): string {
    const parts = input.split(".");

    if (parts.length === 1) {
      return input;
    }

    // Remove first entry
    parts.splice(0, 1);

    // Return rest joined at .
    return parts.join(".");
  }
}
