<div
  class="max-w-screen-lg p-2 flex flex-col items-center justify-center bg-white rounded-md"
  style="width: 500px"
>
  <div class="flex items-center justify-center mb-8 w-[150px] h-[150px]">
    <img
      [src]="'/assets/icons/modal-icons/alert-circle.svg'"
      class="transition-all duration-300 aspect-square rounded-full"
      [ngClass]="{
        'w-[20px]': start,
        'w-[150px]': end,
      }"
    />
  </div>
  <h2 class="block text-center mb-4">
    @if (modalTitle) {
      <span>{{ modalTitle }}</span>
    }
  </h2>
  @if (showModalText) {
    <div class="text-center">
      @if (modalText) {
        <span>{{ modalText }}</span>
      }
      @if (linkInfo) {
        <span
          class="text-primary no-underline hover:text-primary-700 cursor-pointer"
          (click)="goTo(linkInfo.route)"
        >
          {{ linkInfo.text }}
        </span>
      }
    </div>
  }

  <div class="w-full flex items-center justify-center space-x-5 mt-8">
    <confect-button
      [type]="type === 'danger' ? 'white' : 'secondary'"
      (click)="closeModal()"
    >
      {{ cancelText }}
    </confect-button>
    <confect-button
      [type]="type === 'danger' ? 'danger' : 'primary'"
      (click)="confirmModal()"
    >
      {{ confirmText }}
    </confect-button>
  </div>
</div>
