import { Directive, TemplateRef } from "@angular/core";

@Directive({ selector: "[tab-design-tmp]" })
export class TabDesignDirective {
  constructor(public template: TemplateRef<any>) {}
}
@Directive({ selector: "[tab-content-tmp]" })
export class TabContentDirective {
  constructor(public template: TemplateRef<any>) {}
}
