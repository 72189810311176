import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewContainerRef,
  ComponentRef,
  ViewChild,
} from "@angular/core";

import { ContextMenuService } from "@theme/@confect/services/confect-context-menu.service";

import { CContextMenuComponent } from "../context-menu.component";

@Component({
  selector: "confect-context-menu-outlet",
  templateUrl: "./context-menu-outlet.component.html",
})
export class ContextMenuOutletComponent implements OnInit {
  /** The target point in template where all modal-components are bing inserted. */
  @ViewChild("contextTarget", { read: ViewContainerRef, static: true })
  contextContainerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private contextService: ContextMenuService,
  ) {}

  /** Destroys the `content` and the `modal` itself. */
  private static destroyContent(content: ComponentRef<any>): void {
    content.destroy();
  }

  ngOnInit(): void {
    this.handleNewModal();
  }

  /** Listens to `modalService.modalObservable` and loads/appends the modal-component. */
  private handleNewModal(): void {
    this.contextService.contextObservable.subscribe({
      next: (opts) => {
        opts.event.preventDefault();
        const contextComponent = this.loadComponent<CContextMenuComponent>(
          CContextMenuComponent,
          this.contextContainerRef,
          { options: opts.options },
        );

        setTimeout(() => {
          contextComponent.instance.rightClick(opts.event, opts.item);
        });
        contextComponent.instance.afterClose.subscribe({
          next: () => {
            ContextMenuOutletComponent.destroyContent(contextComponent);
          },
        });
        opts.contextRef.close = (evt) => contextComponent.instance.close(evt);
        opts.contextRef.afterClose = contextComponent.instance.afterClose;
      },
    });
  }
  /** Loads the `component` into `parentContainerRef`. */
  private loadComponent<C>(
    component: Type<any>,
    parentContainerRef: ViewContainerRef = this.contextContainerRef,
    inputs = {},
    outputs?,
  ): ComponentRef<C> {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory<C>(component);
    const componentRef = parentContainerRef.createComponent(
      componentFactory.componentType,
    );

    // Pass all @Input bindings
    componentFactory.inputs.forEach(({ propName, templateName }) => {
      componentRef.instance[propName] = inputs[templateName];
    });

    // Pass all @Output bindings
    if (outputs) {
      componentFactory.outputs.forEach(({ propName, templateName }) => {
        outputs[templateName] = componentRef.instance[propName];
      });
    }

    return componentRef as ComponentRef<C>;
  }
}
