import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "confect-checkbox",
  templateUrl: "./checkbox.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CCheckboxComponent,
    },
  ],
})
export class CCheckboxComponent implements OnInit, ControlValueAccessor {
  @Input() title = "";

  @Input() name = "";
  @Input() id = "";
  @Input() icon: string | null = null;
  @Input() disable: boolean = false;

  @Input() value: boolean = false;

  // Legacy value change
  @Output() valueChange = new EventEmitter<boolean>();

  touched = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
