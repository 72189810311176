<ng-template #item>
  <div class="flex relative group">
    <input
      class="block w-full shadow-sm focus:ring-primary focus:border-primary sm:text-sm rounded-md border border-gray-300 cursor-pointer"
      [ngxMatDatetimePicker]="picker"
      [min]="minDate"
      [max]="maxDate"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [readOnly]="true"
      [value]="value"
      (dateChange)="onChange($event.value)"
      (click)="picker.open()"
    />
    <div
      (click)="picker.open()"
      class="absolute inset-y-0 right-2 flex items-center justify-center"
    >
      <div
        class="w-7 h-7 cursor-pointer group-hover:bg-slate-200 rounded-md flex items-center justify-center"
      >
        <confect-icon icon="calendar_month" iconClass="text-xl" />
      </div>
    </div>
  </div>
  <ngx-mat-datetime-picker
    #picker
    [showSeconds]="false"
    [enableMeridian]="false"
    [hideTime]="hideTime"
  >
    @if (showButtons) {
      <ngx-mat-datepicker-actions>
        <div class="flex w-full items-center justify-between">
          <confect-button type="white" ngxMatDatepickerCancel
            >Cancel</confect-button
          >
          <confect-button type="primary" ngxMatDatepickerApply>
            Apply
          </confect-button>
        </div>
      </ngx-mat-datepicker-actions>
    }
  </ngx-mat-datetime-picker>
</ng-template>

@if (title !== "" && title != null) {
  <div
    class="grid gap-4 items-start pb-3"
    [ngClass]="{ 'grid-cols-5': compact, 'grid-cols-2': !compact }"
  >
    <div
      class="mt-px col-span-3 flex items-center h-full"
      [ngClass]="{ 'col-span-1': !compact, 'col-span-3': compact }"
    >
      <label [for]="name" class="block m-0">
        <confect-icon
          [icon]="icon"
          iconClass="text-xl font-medium text-gray-700 mr-2 -mt-1"
        >
          <span class="text-sm">{{ title }}</span>
        </confect-icon>
      </label>
    </div>
    <div [ngClass]="{ 'col-span-1': !compact, 'col-span-2': compact }">
      <ng-container *ngTemplateOutlet="item" />
    </div>
  </div>
}

@if (title === "" || title == null) {
  <ng-container *ngTemplateOutlet="item" />
}
