export interface FacebookAdsInformation {
  adaccount?: string;
  businessID?: string | null;
  campaigns?: string[] | null;
  adsets?: string[] | null;
  ads?: string[] | null;
  uuid?: string;
  trigger?: string;
}

export enum AnalyticsActionType {
  Purchases = "Purchases",
  Clicks = "Clicks",
  Add_To_Cart = "Add_To_Cart",
  Initiate_Checkout = "Initiate_Checkout",
  Post_Engagement = "Post_Engagement",
  Lead = "Lead",
  App_Install = "App_Install",
}

export enum AnalyticsCampaignObjective {
  CONVERSIONS = "CONVERSIONS",
  LINK_CLICKS = "LINK_CLICKS",
  LEAD_GENERATION = "LEAD_GENERATION",
  APP_INSTALLS = "APP_INSTALLS",
  POST_ENGAGEMENT = "POST_ENGAGEMENT",
  BRAND_AWARENESS = "BRAND_AWARENESS",
  OUTCOME_AWARENESS = "OUTCOME_AWARENESS",
}

export interface PredictionFilter {
  campaign_objective: AnalyticsCampaignObjective;
  country_targeted?: string[];
  ocr_text?: string;
  start_date?: Date | string;
  end_date?: Date | string;
}

export interface AdLookupStats {
  rate: number;
  rank: number;
  design: number;
  message: number;
}

export interface AdLookupItem {
  id: string;
  name: string;
  objective: string;
  action: string;
  stats: AdLookupStats;
}

export interface AdLookupResponse {
  summary: {
    objective: string;
    action: string;
    stats: AdLookupStats;
  };
  campaigns?: Record<string, AdLookupItem>;
  adsets?: Record<string, AdLookupItem>;
  ads?: Record<string, AdLookupItem>;
}
