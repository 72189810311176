import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewContainerRef,
  ComponentRef,
  ViewChild,
} from "@angular/core";

import {
  ModalFullOptions,
  ModalInputs,
  ModalOutputs,
} from "@theme/@confect/models/modal.types";

import { CModalService } from "../../services/confect-modal.service";
import { CModalComponent } from "../modal/modal.component";
import { CSlideOverComponent } from "../slide-over/slide-over.component";

@Component({
  selector: "confect-modal-outlet",
  templateUrl: "./modal-outlet.component.html",
})
export class CModalOutletComponent implements OnInit {
  /** The target point in template where all modal-components are bing inserted. */
  @ViewChild("modalTarget", { read: ViewContainerRef, static: true })
  modalContainerRef: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private modalService: CModalService,
  ) {}

  /** Destroys the `content` and the `modal` itself. */
  private static destroyModal(
    modal: ComponentRef<CModalComponent | CSlideOverComponent>,
    content: ComponentRef<any>,
  ): void {
    content.destroy();
    modal.destroy();
  }

  ngOnInit(): void {
    this.handleNewModal();
  }

  /** Listens to `modalService.modalObservable` and loads/appends the modal-component. */
  private handleNewModal(): void {
    this.modalService.modalObservable.subscribe({
      next: (opts: ModalFullOptions) => {
        const {
          component,
          inputs,
          modalRef,
          noPadding,
          width,
          primaryButtonText,
          secondaryButtonText,
          type,
          disableScroll,
        } = opts;

        // Load modal component which is the parent of the given content component.
        const modalComponent = this.loadComponent<
          CModalComponent | CSlideOverComponent
        >(
          (type ?? "modal") === "slide" ? CSlideOverComponent : CModalComponent,
          this.modalContainerRef,
          {
            show: true,
            hidden: false,
            noPadding,
            width,
            primaryButtonText,
            secondaryButtonText,
            disableScroll,
          },
          modalRef.outputs,
        );

        // Initialize the ModalComponent
        modalComponent.instance.show();
        modalComponent.instance.afterClose.subscribe({
          next: () =>
            CModalOutletComponent.destroyModal(
              modalComponent,
              contentComponent,
            ),
        });

        // Load given content component into parent modal component.
        const contentComponent = this.loadComponent(
          component,
          modalComponent.instance.contentContainerRef,
          inputs,
          modalRef.outputs,
        );

        // Set `afterClose` event emitter
        modalRef.afterClose = modalComponent.instance.afterClose;

        // Set close method
        modalRef.close = () => modalComponent.instance.hide();
      },
    });
  }

  /** Loads the `component` into `parentContainerRef`. */
  private loadComponent<C>(
    component: Type<any>,
    parentContainerRef: ViewContainerRef = this.modalContainerRef,
    inputs: ModalInputs = {},
    outputs?: ModalOutputs,
  ): ComponentRef<C> {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory<C>(component);
    const componentRef = parentContainerRef.createComponent(
      componentFactory.componentType,
    );

    // Pass all @Input bindings
    componentFactory.inputs.forEach(({ propName, templateName }) => {
      componentRef.instance[propName] = inputs[templateName];
    });

    // Pass all @Output bindings
    if (outputs) {
      componentFactory.outputs.forEach(({ propName, templateName }) => {
        outputs[templateName] = componentRef.instance[propName];
      });
    }

    return componentRef as ComponentRef<C>;
  }
}
