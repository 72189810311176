import { EventEmitter, Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";

export interface TooltipRef {
  close: () => void;
  switch: () => void;
  options: any;
  hovered: EventEmitter<boolean>;
  afterClose: EventEmitter<void>;
}

@Injectable({ providedIn: "root" })
export class TooltipService {
  /**
   * All the necessary data about the modal which should be opened is being
   * sent to the outlet-modal component through this subject.
   */
  private tooltipSubject: Subject<any> = new Subject<any>();

  public get tooltipObservable(): Observable<any> {
    return this.tooltipSubject.asObservable();
  }

  open<C>(opts) {
    const tooltipRef: TooltipRef = {
      options: opts,
    } as TooltipRef;

    this.tooltipSubject.next({
      ...opts,
      tooltipRef,
    });

    return tooltipRef;
  }
}
