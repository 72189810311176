import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

import moment from "moment";

import { EMAIL_DOMAIN_BLACKLIST } from "./email-domain-list";

export class CustomValidators {
  static validateStartDate(): ValidatorFn {
    return (control: AbstractControl): { invalid: string } | null => {
      return moment(control.value).isAfter()
        ? {
            invalid: `${moment(control.value).format(
              "DD/MM/YYYY",
            )} is not valid start date`,
          }
        : null;
    };
  }

  static fieldsMatch(a: string, b: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fieldA = control.get(a);
      const fieldB = control.get(b);

      if (fieldA && fieldB && fieldA.value === fieldB.value) {
        return null;
      }

      return { doesNotMatch: "Hi there" };
    };
  }

  static emailAllowed(): ValidatorFn {
    return (control: AbstractControl): { forbiddenEmail: string } | null => {
      const s: string = control.value;

      if (!s) {
        return null;
      }

      const sp = s.toLowerCase().split("@");

      if (sp.length !== 2) {
        return null;
      }

      if (EMAIL_DOMAIN_BLACKLIST.has(sp[1])) {
        return {
          forbiddenEmail: "Email is not a work email",
        };
      }

      return null;
    };
  }
}
