<div class="mt-4 mx-auto grid grid-cols-3 gap-y-6 gap-x-8">
  @for (option of options; track option) {
    <div
      class="relative bg-white rounded-lg shadow-sm p-4"
      [ngClass]="{
        'border-2 border-primary': option.value === _value,
        border: option.value !== _value,
      }"
    >
      <span class="flex flex-col items-center w-full space-y-4">
        <span class="block text-lg font-medium text-gray-900">
          {{ option.name }}
        </span>
        <div
          [lazyLoad]="option.image"
          class="block w-full bg-contain bg-no-repeat bg-center"
          [ngClass]="{ 'sm:h-20 2xl:h-48': onboarding, 'h-16': !onboarding }"
        ></div>
        <span class="mt-1 flex items-center text-sm text-gray-500">
          {{ option.description }}
        </span>
        <confect-button type="secondary" (click)="settingsChange(option.value)"
          >Select</confect-button
        >
      </span>
    </div>
  }
</div>
