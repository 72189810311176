import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

import { ProductsService } from "@core/api/products.service";

import { Subject, Subscription } from "rxjs";
import { debounceTime, map, takeUntil } from "rxjs/operators";

@Component({
  selector: "ngx-products-search",
  templateUrl: "./products-search.component.html",
  styleUrls: ["./products-search.component.scss"],
})
export class ProductsSearchComponent implements OnInit, OnDestroy {
  constructor(private ps: ProductsService) {}

  @Input() selectedProductsInput: any[] = [];
  @Output() selectedProductsChange = new EventEmitter<any>();
  @Output() applied = new EventEmitter();

  @Input() productFilters: any = {};
  @Input() noBorders = false;
  @Input() emitProductObjects = false;

  // Pagenation
  shouldLoadNextPage = true;
  isLoading = false;
  per_page = 30;
  page = 1;

  // Search
  searchResultProducts = [];
  searchString = "";

  // Selected
  selectedProducts = [];
  selectedSet = new Set();

  selectedTab = "Search";

  unsubscribe$ = new Subject();

  searchChange = new Subject<boolean>();
  currentSearchSub?: Subscription;

  ngOnInit() {
    // Never filter on product id
    delete this.productFilters["product_id"];

    // Add initially selected products to set
    this.updateSelection(this.selectedProductsInput);

    // Initial empty search
    this.search();

    this.searchChange
      .pipe(
        debounceTime(800),
        map((_) => this.search()),
        takeUntil(this.unsubscribe$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  updateSelection(newSelection: string[]) {
    this.selectedSet = new Set(newSelection);
    this.refreshProductsIfNeeded();
  }

  addSelection(product) {
    this.selectedSet.add(product.id);
    this.selectedProducts.push(product);
  }

  removeSelection(product) {
    this.selectedSet.delete(product.id);
    this.selectedProducts = this.selectedProducts.filter(
      (p) => p.id !== product.id,
    );
  }

  track(index, product) {
    return product.id;
  }

  search() {
    // Reset
    this.searchResultProducts = [];
    this.page = 1;
    this.shouldLoadNextPage = true;

    this.currentSearchSub?.unsubscribe();

    // New search
    this.loadNextPage();
  }

  loadNextPage() {
    this.isLoading = true;

    this.currentSearchSub = this.ps
      .searchProducts({
        text: this.searchString,
        page: this.page,
        page_size: this.per_page,
        product_filter: this.productFilters,
      })
      .subscribe((res) => {
        this.searchResultProducts.push(
          ...res.data.map((p) => {
            p["cdn_image"] = p["cdn_image"] + "?width=100";
            return p;
          }),
        );

        if (this.page === res.meta.num_pages) this.shouldLoadNextPage = false;
        else this.page++;

        this.isLoading = false;
      });
  }

  onScroll(event) {
    // Infinite scroll check
    const elem = event.target;
    if (
      elem.scrollTop + elem.clientHeight >=
      elem.scrollHeight - elem.clientHeight * 0.2
    ) {
      if (this.shouldLoadNextPage && !this.isLoading) {
        this.loadNextPage();
      }
    }
  }

  changeSelected(product) {
    if (this.selectedSet.has(product.id)) {
      this.removeSelection(product);
    } else {
      this.addSelection(product);
    }
  }

  isSelected(product) {
    return this.selectedSet.has(product.id);
  }

  apply() {
    if (this.emitProductObjects) {
      this.selectedProductsChange.emit(this.selectedProducts);
      return;
    }
    this.selectedProductsChange.emit(Array.from(this.selectedSet));
  }

  refreshProductsIfNeeded() {
    // If length match we might be okay
    if (this.selectedProducts.length === this.selectedSet.size) {
      // If we have every product in the selected Set they are equal and we can stop
      if (this.selectedProducts.every((p) => this.selectedSet.has(p.id))) {
        return;
      }
    }

    this.ps
      .searchProducts({
        page_size: this.selectedSet.size,
        product_filter: {
          product_id: Array.from(this.selectedSet),
        },
      })
      .subscribe({
        next: (res) => {
          this.selectedProducts = res.data.map((p) => {
            p["cdn_image"] = p["cdn_image"] + "?width=100";
            return p;
          });
        },
      });
  }

  changeTab(tab) {
    this.selectedTab = tab;
    if (tab === "Selected Products") {
      this.refreshProductsIfNeeded();
    }
  }
}
