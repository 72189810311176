import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { Me } from "@core/api-models/users.models";
import { UsersService } from "@core/api/users.service";

import { Observable } from "rxjs";
import { of } from "rxjs";
import { catchError, share, tap } from "rxjs/operators";

import { CAuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  currentUser: Me;
  shouldAllowAnon = false;

  private refreshObservable?: Observable<Me>;

  constructor(
    private usersService: UsersService,
    private router: Router,
    private authService: CAuthService,
  ) {
    this.authService.onAuthChange$.subscribe((_) => {
      this.currentUser = null;
    });
  }

  updateSleeknote() {
    if (!this.currentUser) {
      return;
    }

    const u = this.currentUser;

    window["sleeknoteSiteData"].push({
      user_id: u.id,
    });

    if (u.info) {
      for (const [key, entry] of Object.entries(u.info)) {
        window["sleeknoteSiteData"].push({ ["user_" + key]: entry });
      }
    }

    if (u.agency != null) {
      window["sleeknoteSiteData"].push({
        agency_id: u.agency.id,
      });

      for (const [key, entry] of Object.entries(u.agency.info)) {
        window["sleeknoteSiteData"].push({ ["agency_" + key]: entry });
      }
    }
  }

  me(fresh = false): Observable<Me> {
    if (this.refreshObservable) {
      return this.refreshObservable;
    }

    if (this.currentUser && !fresh) {
      return of(this.currentUser);
    }

    this.refreshObservable = this.usersService.getMe().pipe(
      share(),
      catchError((err) => {
        if (err.error.detail === "Invalid anon access") {
          // For now we clear and navigate them to login,
          // we could catch them on an info/error screen instead
          localStorage.clear();
          this.router.navigate(["/auth/login"]);
          return of();
        }

        throw err;
      }),
      tap((me) => {
        this.currentUser = me;
        this.updateSleeknote();
        this.refreshObservable = null;

        if (me.anon === true && sessionStorage.getItem("anon") !== "true") {
          // Sign out
          localStorage.clear();
          this.router.navigate(["/auth/login"]);
        }
      }),
    );
    return this.refreshObservable;
  }

  checkUserHasPermission(user: Me, permission: string) {
    return user.permissions[permission] === true;
  }
}
