import { Component, OnInit } from "@angular/core";

@Component({
  selector: "confect-page-wrapper",
  templateUrl: "./confect-page-wrapper.component.html",
})
export class CPageWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
