@if (paginator.searchString === "" && paginator.showPager) {
  <div
    class="flex flex-col items-center justify-center rounded-md bg-slate-100 p-2"
    [ngClass]="{
      'min-w-48': !allowPageButtons,
      'min-w-[27rem]': allowPageButtons,
    }"
  >
    <div class="flex items-center flex-grow justify-between w-full space-x-2">
      <div
        class="aspect-square flex items-center justify-center rounded-md"
        [ngClass]="{ 'hover:text-primary': paginator.hasPrevPage }"
      >
        <confect-icon-button
          [disabled]="!paginator.hasPrevPage"
          iconClass="text-3xl"
          icon="chevron_left"
          (click)="paginator.prevPage(); changed(paginator.CURRENT_PAGE)"
        />
      </div>

      @if (allowPageButtons) {
        @for (page of display; track page) {
          <div
            class="aspect-square px-2 h-10 w-10 flex items-center justify-center rounded-md"
            [ngClass]="{
              'text-gray-400 cursor-default': page.value == null,
              'hover:bg-primary-300 hover:text-white cursor-pointer':
                page.value != null && paginator.CURRENT_PAGE !== page.value,
              'bg-primary text-white cursor-pointer':
                page.value != null && paginator.CURRENT_PAGE === page.value,
            }"
            (click)="
              page.value != null && (paginator.CURRENT_PAGE = page.value);
              paginator.refresh();
              changed(paginator.CURRENT_PAGE)
            "
          >
            <div class="aspect-square m-1">
              {{ page.display }}
            </div>
          </div>
        }
      } @else {
        <div class="flex justify-center items-center">
          {{ paginator.CURRENT_PAGE }} of {{ paginator.pageTotal }}
        </div>
      }

      <div
        class="aspect-square flex items-center justify-end rounded-md"
        [ngClass]="{ 'hover:text-primary': paginator.hasMorePages }"
      >
        <confect-icon-button
          [disabled]="!paginator.hasMorePages"
          iconClass="text-3xl"
          icon="chevron_right"
          (click)="paginator.nextPage(); changed(paginator.CURRENT_PAGE)"
        />
      </div>
    </div>
  </div>
  @if (showItemCount) {
    <div class="mt-2 text-sm flex items-center justify-center text-gray-400">
      Showing {{ paginator.display.from }}-{{ paginator.display.to }} of
      {{ paginator.display.total }}
    </div>
  }
}
