import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { SettingsService } from "@core/api/settings.service";
import { COUNTRIES } from "@core/services/countries";
import { CustomValidators } from "@core/validators/validators";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { EU_COUNTRIES } from "./eu-countries";

function vatIfCountryIsEU(
  control: AbstractControl,
): { vatRequired: string } | null {
  const vat: string = control.get("vat")?.value ?? "";
  const country: string = (control.get("country")?.value ?? "").toLowerCase();

  if (EU_COUNTRIES.has(country) && vat.length < 5) {
    return {
      vatRequired: "Companies in the EU needs to provide a VAT number",
    };
  }

  return null;
}

@Component({
  selector: "ngx-billing-settings-form",
  templateUrl: "./billing-settings-form.component.html",
  styleUrls: ["./billing-settings-form.component.scss"],
})
export class BillingSettingsFormComponent implements OnInit, OnDestroy {
  VAT_REGEX =
    /^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))$/i;

  EXEMPT_COUNTRIES = new Set(["norway", "united states"]);

  // Whether the form should be in signup mode
  @Input() signup = false;

  @Input() showSave = true;

  @Input() editMode: boolean = true;

  @Output() formSaved = new EventEmitter<boolean>();
  @Output() formChanged = new EventEmitter<any>();

  billingGroup: FormGroup = new FormGroup(
    {
      name: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      billing_email: new FormControl("", {
        nonNullable: true,
        validators: [
          Validators.required,
          Validators.email,
          CustomValidators.emailAllowed,
        ],
      }),
      // address: new FormControl("", {
      //   nonNullable: true,
      //   validators: [Validators.required],
      // }),
      street: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      zip: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      city: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      country: new FormControl("", {
        nonNullable: true,
        validators: [Validators.required],
      }),
      vat: new FormControl("", {}),
    },
    { validators: [vatIfCountryIsEU] },
  );

  COUNTRIES = COUNTRIES;

  company: any = null;

  unsubscribe$ = new Subject();

  submitted: boolean = false;

  constructor(
    private ss: SettingsService,
    private popupModalService: CPopupModalService,
  ) {
    // Sort by their actual name
    this.COUNTRIES.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    );
  }

  formChangeSubscription: Subscription;

  vatRegex = null;

  ngOnInit(): void {
    this.updateCompany();
  }

  ngOnDestroy(): void {
    this.unsubscribeToChanges();
  }

  unsubscribeToChanges() {
    if (this.formChangeSubscription) {
      this.formChangeSubscription.unsubscribe();
    }
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  subscribeToChanges() {
    if (!this.billingGroup) {
      return;
    }

    this.unsubscribeToChanges();
    this.formChangeSubscription = this.billingGroup.valueChanges.subscribe(
      (change) => {
        // this.vatRegex = !this.EXEMPT_COUNTRIES.has(this.company.country)
        //   ? this.VAT_REGEX
        //   : null;
        this.formChanged.emit(this.company);
      },
    );
  }

  updateCompany() {
    this.ss
      .getCompany()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.company = res;
        setTimeout(() => {
          this.subscribeToChanges();
        }, 1);
      });
  }

  saveCompany() {
    this.ss
      .updateCompany(this.company)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (_) => {
          if (this.showSave) {
            this.popupModalService.success({ title: "Saved!" });
          }
          this.updateCompany();
          this.formSaved.emit(true);
          this.submitted = false;
        },
        error: (_) => {
          this.formSaved.emit(false);
          this.submitted = false;
        },
      });
  }

  save() {
    if (this.company === null) {
      this.formSaved.emit(false);
      return;
    }

    this.submitted = true;

    const vat: string = this.company.tax_number;

    const checkVAT = vat && !this.EXEMPT_COUNTRIES.has(this.company.country);

    if (checkVAT && !this.vatHasPrefix(vat)) {
      this.company.tax_number =
        this.COUNTRIES.find((country) => country.name === this.company.country)
          .alpha_2 + vat;
    }

    this.saveCompany();
  }

  vatHasPrefix(vat: string) {
    return vat.length > 0 && vat.match(/^[a-zA-Z]{2}/);
  }

  public signupSave() {
    this.save();
  }

  // public signupIsValid(): boolean {
  //   if (!this.accountForm) {
  //     return false;
  //   }
  //   return this.accountForm.valid && this.company.country != null;
  // }
}
