<ng-template #item>
  <div
    class="relative h-4 w-12 rounded-full cursor-pointer"
    [ngClass]="{
      'bg-primary': value,
      'bg-gray-200': !value,
      'transition-colors duration-300': initialized,
    }"
    (click)="writeValue(!value); onChange(value)"
  >
    <div
      class="absolute h-6 w-6 top-0 left-0 -translate-y-1 bg-white rounded-full ring-inset ring-2 ring-slate-300"
      [ngClass]="{
        'translate-x-6': value,
        'transition-transform duration-300': initialized,
      }"
    ></div>
  </div>
</ng-template>

@if (title !== "" && title != null) {
  <div class="sm:grid sm:grid-cols-8 sm:gap-4 sm:items-start sm:pb-3">
    <div class="sm:mt-px sm:col-span-5 flex items-center h-full">
      <label [for]="id" class="block">
        <confect-icon
          [icon]="icon"
          iconClass="text-xl font-medium text-gray-700 mr-2"
        >
          <span class="text-sm">{{ title }}</span>
        </confect-icon>
      </label>
    </div>
    <div class="sm:col-span-3 h-full">
      <div class="flex items-center justify-end h-full">
        <ng-container *ngTemplateOutlet="item" />
      </div>
    </div>
  </div>
}

@if (title === "" || title == null) {
  <ng-container *ngTemplateOutlet="item" />
}
