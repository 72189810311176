import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { MediaService } from "@core/api/media.service";
import { MediaLibraryItem } from "@core/models/media-library.types";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { MediaLibrarySourceService } from "./media-library-source.service";

@Component({
  selector: "ngx-media-library",
  templateUrl: "./media-library.component.html",
  styleUrls: ["./media-library.component.scss"],
})
export class MediaLibraryComponent implements OnInit, OnDestroy {
  title = "Media library";
  fileTypes;

  @ViewChild("slideover", { static: true }) slideover: TemplateRef<any>;

  _enabledMediaSources: { key: string; scope: string }[] = [];

  @Input() set enabledMediaSources(to: { key: string; scope: string }[]) {
    this._enabledMediaSources = to;
    this.title = this._enabledMediaSources.some((s) => s.key.includes("font"))
      ? "Font library"
      : "Media library";
    this.fileTypes = this._enabledMediaSources.some((s) =>
      s.key.includes("font"),
    )
      ? [".ttf", ".otf", ".woff", ".woff2"]
      : ["image/*", "video/*"];
  }

  @Output() mediaSelected = new EventEmitter<MediaLibraryItem>();

  mediaSources = [];
  isLoaded = false;

  medias = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  currentSource = null;

  slideOverRef;

  public source: MediaLibrarySourceService;

  @Input() transparencyCheckers = true;

  @Input() patternMode = false;

  unsubscribe$ = new Subject();

  constructor(
    private mediaService: MediaService,
    public popUpService: CPopupModalService,
  ) {
    this.source = new MediaLibrarySourceService(mediaService);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  show() {
    this.load();
    this.slideOverRef = this.popUpService.openTemplate(
      this.slideover,
      false,
      "slide",
    );
    this.slideOverRef.outputs["scroll"].asObservable().subscribe({
      next: (res) => {
        this.onScroll(res);
      },
    });
  }

  hide() {
    this.slideOverRef?.close();
    delete this.slideOverRef;
  }

  changeSource(source) {
    // Don't change source to same as current
    if (this.currentSource) {
      if (source.key === this.currentSource.key) {
        return;
      }
    }

    this.currentSource = source;
    this.source.configure(source.key, source.scope);
  }

  load() {
    this.mediaService
      .availableMediaSources()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res) => {
          const sourceLookup = new Map(res.map((s) => [s.key, s]));

          this.mediaSources = this._enabledMediaSources
            .map((ems) => {
              const s = sourceLookup.get(ems.key);
              if (!s) {
                return null;
              }
              s["scope"] = ems.scope;
              return s;
            })
            .filter((ems) => ems != null);

          if (this.mediaSources.length > 0) {
            this.changeSource(this.mediaSources[0]);
          }

          this.isLoaded = true;
        },
      });
  }

  uploadMedia() {
    if (!this.currentSource) {
      return;
    }

    this.mediaService
      .uploadMediaFileInteractive(this.fileTypes, "File")
      .subscribe({
        next: (files: any) => {
          files.forEach((file) => {
            this.source.refreshMedias();
            this.mediaService.getMediaById(file.id, this.source.key).subscribe({
              next: (res) => {
                this.mediaSelected.emit(res);
              },
            });
          });
        },
      });
  }

  deactivateMedia(media) {
    this.popUpService
      .warning({
        title: "Are you sure?",
        text: "This media will be deleted from your library.",
        confirmText: "Yes, delete it!",
        cancelText: "No, cancel!",
      })
      .outputs["modalClosed"].asObservable()
      .subscribe({
        next: (result) => {
          // Confirmed
          if (!result) {
            return;
          }
          this.mediaService
            .deactivateMedia(media.id, this.source.key)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: (res) => {
                this.source.media = this.source.media.filter(
                  (m) => m.id !== media.id,
                );
              },
              error: (err) => {
                // Deactivete unsuccessfull
                this.popUpService.error({});
              },
            });
        },
      });
  }

  onScroll(event) {
    const elem = event.target;
    if (
      elem.scrollTop + elem.clientHeight >=
      elem.scrollHeight - elem.clientHeight * 0.4
    ) {
      if (this.source.shouldLoadNextPage && !this.source.isLoading) {
        this.source.loadNextPage();
      }
    }
  }
}
