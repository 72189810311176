<div class="max-w-screen-lg p-2 bg-white rounded-md" style="width: 500px">
  @if (invalidInput) {
    <div class="mb-5">
      <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      >
        <strong class="font-bold">Invalid Input! </strong>
        <span class="block sm:inline"
          >Only file type{{ accept.split(",").length > 1 ? "s" : "" }} allowed
          {{ accept.split(",").length > 1 ? "are" : "is" }}:
          {{ accept.split(",").join(", ") }}</span
        >
      </div>
    </div>
  }

  <h2 class="block text-center mb-4">
    @if (modalTitle) {
      <span>{{ modalTitle }}</span>
    }
  </h2>
  @if (showModalText) {
    <div class="text-center">
      @if (modalText) {
        <span>{{ modalText }}</span>
      }
    </div>
  }
  @if (accept != null) {
    <div class="flex items-center justify-center">
      <confect-upload
        [(ngModel)]="modalInputValue"
        (ngModelChange)="confirmModal()"
        (invalid)="invalidInput = true"
        [text]="modalUploadText"
        [buttonText]="buttonText"
        [accept]="accept"
        [multiple]="multiple"
        [isUploading]="isUploading"
      />
    </div>
  }
</div>
