import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import escapeHTML from "escape-html";
import { Node, Text } from "slate";

export function headingName(text: string) {
  return text.toLowerCase().replaceAll(" ", "-");
}

export function serializeSlate(children: Node[]): string {
  return children
    .map((node, i: number) => {
      if (Text.isText(node)) {
        let text = `<span>${escapeHTML(node.text)}</span>`;

        if ((node as any).bold) {
          text = `<strong>${text}</strong>`;
        }

        if ((node as any).code) {
          text = `<code>${text}</code>`;
        }

        if ((node as any).italic) {
          text = `<em>${text}</em>`;
        }

        // Handle other leaf types here...

        return text;
      }

      if (!node) {
        return "";
      }

      const id = (node.children[0] as any).text ?? "";
      const idText = () => (id ? `id="${headingName(id)}"` : "");

      const n = node as any;
      switch ((node as any).type) {
        case "h1":
          return `<h1 ${idText()}>${serializeSlate(node.children)}</h1>`;
        case "h2":
          return `<h2 ${idText()}>${serializeSlate(node.children)}</h2>`;
        case "h3":
          return `<h3 ${idText()}>${serializeSlate(node.children)}</h3>`;
        case "h4":
          return `<h4 ${idText()}>${serializeSlate(node.children)}</h4>`;
        case "h5":
          return `<h5 ${idText()}>${serializeSlate(node.children)}</h5>`;
        case "h6":
          return `<h6 ${idText()}>${serializeSlate(node.children)}</h6>`;
        case "quote":
          return `<blockquote>${serializeSlate(node.children)}</blockquote>`;
        case "ul":
          return `<ul>${serializeSlate(node.children)}</ul>`;
        case "ol":
          return `<ol>${serializeSlate(node.children)}</ol>`;
        case "li":
          return `<li>${serializeSlate(node.children)}</li>`;

        case "HTMLBlock":
          return `<div>${(node as any)?.content}</div>`;

        case "upload":
          return `<div class="w-full">
            <img
              src="${(node as any).value.url}"
              class="mb-14 block h-auto max-w-full mx-auto max-h-[50vh]"
              style="max-height: 50vh"
            />
          </div>`;

        case "link":
          if (n.linkType === "internal") {
            if (n.doc.relationTo === "academy-categories") {
              n.url = `/articles/${n.doc.value.slug}`;
            } else if (n.doc.relationTo === "academy-articles") {
              n.url = `/articles/${n.doc.value.category[0].slug}/${n.doc.value.slug}`;
            } else if (n.doc.relationTo === "analytics-pages") {
              n.url = `#analytics-${n.doc.value.id}`;
            } else {
              return "";
            }
          }

          return `<a
            href="${escapeHTML(n.url)}"
            target="${n?.newTab === true ? "_blank" : "_self"}"
          >
            ${serializeSlate(node.children)}
          </a>`;

        default:
          return `<p>${serializeSlate(node.children)}</p>`;
      }
    })
    .reduce((a, b) => {
      return a + b;
    }, "");
}

@Pipe({
  name: "slate",
  pure: true,
})
export class SlatePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  transform(input: Node[], args?: any): any {
    return this.sanitizer.bypassSecurityTrustHtml(serializeSlate(input));
  }
}
