import { Injectable } from "@angular/core";

import { ProductsService } from "@core/api/products.service";
import { ProductFields } from "@core/models/products.types";

import { Observable, of, share, tap } from "rxjs";

import { AccessService } from "./access.service";
import { ProctorService } from "./proctor.service";

@Injectable({
  providedIn: "root",
})
export class CustomFieldsService {
  private FIELDS: ProductFields[] = null;

  private refreshObservable?: Observable<ProductFields[]>;

  constructor(
    private ps: ProductsService,
    private accessService: AccessService,
    private proctorService: ProctorService,
  ) {
    this.accessService.accessChanged$.subscribe((_ac) => {
      this.getFields(true).subscribe();
    });

    this.proctorService.info.$proctorChanged.subscribe((_pc) => {
      this.getFields(true).subscribe();
    });
  }

  getFields(refresh = false): Observable<ProductFields[]> {
    if (this.refreshObservable != null) {
      return this.refreshObservable;
    }

    if (this.FIELDS != null && !refresh) {
      return of(this.FIELDS);
    }

    this.refreshObservable = this.ps.getProductLookupOptions().pipe(
      share(),
      tap((fields) => {
        this.FIELDS = fields;
        this.refreshObservable = null;
      }),
    );
    return this.refreshObservable;
  }
}
