import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";

import { Orientation } from "@core/utils/confect-math";

import { SplitContentDirective } from "./split-view.directive";

export enum Pane {
  First = 0,
  Second = 1,
}

export interface SplitSpec {
  split: {
    first: SplitSpec | string;
    second: SplitSpec | string;
  };
  orientation: Orientation;
  layout: SurfaceLayout;
  draggable: boolean;
  offset?: number;
}

export interface SurfaceLayout {
  min: number;
  basis: number;
  max: number;
  unit: string;
  pane: Pane;
}

export interface Basis {
  value: number;
  unit: string;
}

export interface Surface {
  top: number;
  left: number;
  width: number;
  height: number;
  id: string;
}

export interface SplitSettings {
  pixel: boolean;
}

@Component({
  selector: "confect-split-view",
  templateUrl: "./split-view.component.html",
})
export class SplitViewComponent implements OnInit {
  _spec: SplitSpec;
  initSpec: SplitSpec;
  @Input({ required: true }) set spec(to: SplitSpec) {
    this._spec = to;
    this.initSpec = JSON.parse(JSON.stringify(to));
  }

  @Output() specChange = new EventEmitter<SplitSpec>();

  @ContentChild(SplitContentDirective, { read: TemplateRef })
  contentTemplate: TemplateRef<any>;

  hide: boolean = false;

  resizeObserver = new ResizeObserver(() => {
    this.reset();
    this.reload();
  });

  constructor() {}

  reload() {
    this.hide = true;
    setTimeout(() => {
      this.hide = false;
    });
  }

  reset() {
    this._spec = JSON.parse(JSON.stringify(this.initSpec));
  }

  ngOnInit(): void {
    this.resizeObserver.observe(document.body);
  }
}
