<ngx-feature-banner
  topText="As you haven’t started your trial yet, only 50 products are
  available in Confect."
  bottomText="To unlock all of them, please start a free trial of the full
  version."
  featureKey="feed"
/>

@if (showAdd || addOnly) {
  <confect-card>
    <confect-card-header>
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h2>
            {{ newFeed.id == null ? "Add Feed" : "Edit feed: " + newFeed.name }}
          </h2>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0 space-x-2">
          <confect-button
            size="large"
            (click)="cancelAdd()"
            type="white"
            [disabled]="addOnly"
          >
            Cancel
          </confect-button>
        </div>
      </div>
    </confect-card-header>
    <confect-card-body>
      <form
        confect-form-inline-group
        [buttonTitle]="newFeed.id == null ? 'Add' : 'Save'"
        [showButton]="true"
        (ngSubmit)="saveFeed(newFeed)"
      >
        <confect-input
          name="name"
          title="Feed name"
          placeholder="Default"
          [(ngModel)]="newFeed.name"
        />
        <confect-input
          name="url"
          title="Feed URL"
          placeholder="https://website.com/feed.xml"
          [(ngModel)]="newFeed.url"
        />
        <ngx-feed-image-settings />
      </form>
    </confect-card-body>
  </confect-card>
}

<div class="mb-4"></div>

@if (!addOnly) {
  <confect-card
    [headerInfo]="{
      title: 'Data Source',
      subtitle: 'Connect your products to Confect',
      primaryButton: 'Add data source',
      primaryDisable: !allowNewFeed || showAdd || addOnly,
    }"
    (primary)="startAdd()"
  >
    <!-- <confect-card-header>
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h2>Data sources</h2>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0 space-x-2 flex items-center">
          <confect-icon-button
            icon="help_outlined"
            iconClass="text-3xl text-primary"
            (click)="helpModal.show()"
          >
          </confect-icon-button>
          <confect-button
            size="large"
            (click)="startAdd()"
            [disabled]="!allowNewFeed || showAdd || addOnly"
          >
            Add data source
          </confect-button>
        </div>
      </div>
    </confect-card-header> -->
    <confect-card-body>
      <div class="flex flex-col">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div id="sleekbox-settings-data-sources" class="w-full mb-6"></div>
            <div class="overflow-hidden border border-gray-200 sm:rounded-lg">
              <table
                class="w-full max-w-full divide-y divide-gray-200 table-fixed"
              >
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12"
                    >
                      URL
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last updated
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Odd row -->
                  @for (
                    feed of FEEDS;
                    track trackByID(i, feed);
                    let i = $index
                  ) {
                    <tr
                      class="bg-white"
                      [ngClass]="{
                        'bg-white': i % 2 === 0,
                        'bg-gray-50': i % 2 === 1,
                      }"
                    >
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        {{ feed.name }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 overflow-ellipsis overflow-hidden"
                      >
                        {{ feed.url }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <span
                          [class.success]="
                            feedStatuses[feed.id].color === 'GREEN'
                          "
                          [class.warning]="
                            feedStatuses[feed.id].color === 'YELLOW'
                          "
                          [class.error]="feedStatuses[feed.id].color === 'RED'"
                          >{{ feedStatuses[feed.id].message }}</span
                        >
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        {{ feed["lu"] }}
                      </td>
                      <td
                        class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                      >
                        <!-- <confect-action-button
                            title="Refresh"
                            (action)="refreshFeed(feed)"
                            (dropdownItemSelected)="
                              dropdownItemSelected(feed, $event)
                            "
                          ></confect-action-button> -->
                        <div class="flex">
                          <div
                            class="flex divide-x-2 divide-gray-200 rounded-md ring-2 ring-gray-200 justify-end items-center"
                          >
                            <div
                              class="text-center flex rounded-l-md items-center h-full px-2 py-1 cursor-pointer hover:bg-slate-50"
                              (click)="refreshFeed(feed)"
                            >
                              Refresh
                            </div>
                            <div
                              class="text-center h-full rounded-r-md cursor-pointer hover:bg-slate-50"
                            >
                              <confect-icon-button
                                icon="more_vertical px-2 py-1"
                                iconClass="text-2xl"
                                [dropdownItems]="dropdownItems"
                                (dropdownItemSelected)="
                                  dropdownItemSelected(feed, $event)
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <div class="flex flex-col space-y-2 mt-8 px-2">
              <p>
                Please note that Confect does not support duplicate product ids
                in separate data sources.
              </p>
              <p>
                If you wish to market the same products in different markets, or
                have another use-case requiring multiple feeds containing the
                same products, you need to do this by creating more accounts.
              </p>
              <p>Contact the live chat if you want to add new data sources.</p>
            </div>
            <!-- <ngx-access-warning
              [slim]="false"
              [enableFeedWarning]="true"
            ></ngx-access-warning> -->
          </div>
        </div>
      </div>
    </confect-card-body>
  </confect-card>
}

<div class="mb-12"></div>

@if (FAILED_FEEDS.length > 0 || WARNING_FEEDS.length > 0) {
  <div class="w-full">
    <confect-card>
      <confect-card-header>Feed diagnostics</confect-card-header>
      <confect-card-body>
        @if (FAILED_FEEDS.length > 0) {
          <h6 class="feed-diag-header" style="color: #e74c3c">
            Requires fixing:
          </h6>
          <mat-accordion>
            @for (f of FAILED_FEEDS; track trackByID($index, f)) {
              <ngx-feed-settings-error [feed]="f" />
            }
          </mat-accordion>
        }
        @if (WARNING_FEEDS.length > 0) {
          <h6 class="feed-diag-header" style="color: #f1c40f">
            Non-critical warnings:
          </h6>
          <mat-accordion>
            @for (f of WARNING_FEEDS; track trackByID($index, f)) {
              <ngx-feed-settings-error [feed]="f" />
            }
          </mat-accordion>
        }
      </confect-card-body>
    </confect-card>
  </div>
}

<confect-modal #helpModal width="800px">
  <!-- COPY THIS :) -->
  <div class="flex items-center justify-between">
    <div class="flex flex-col mx-auto space-y-2 w-5/12">
      <h4 class="text-center">
        Find feed URL on
        <span style="color: #3b5998">Facebook</span>
      </h4>
      <hr />
      <p>
        <b>1.</b>
        Go to
        <a target="_blank" href="https://www.facebook.com/products/">
          Facebook Catalogs
        </a>
      </p>
      <p>
        <b>2.</b>
        Click on the desired feed
      </p>
      <p>
        <b>3.</b>
        Click on
        <mark>product data sources</mark>
        under
        <mark>configure</mark>
      </p>
      <p>
        <b>4.</b>
        Find the correct source and copy the link in the
        <mark>last updated</mark>
        column
      </p>
    </div>
    <div class="flex flex-col space-y-2 mx-auto w-5/12">
      <h4 class="text-center">
        Find feed URL on
        <span style="color: #4e81ee">G</span>
        <span style="color: #e1183a">o</span>
        <span style="color: #f5c228">o</span>
        <span style="color: #4e81ee">g</span>
        <span style="color: #24bf5a">l</span>
        <span style="color: #e1183a">e</span>
      </h4>
      <hr />
      <p>
        <b>1.</b>
        Go to
        <a
          target="_blank"
          href="https://merchants.google.com/mc/products/sources"
        >
          Feeds in Google Merchant Center
        </a>
      </p>
      <p>
        <b>2.</b>
        Click on the desired feed
      </p>
      <p>
        <b>3.</b>
        Go to
        <mark>settings</mark>
      </p>
      <p>
        <b>4.</b>
        Click on
        <mark>fetch schedule</mark>
        and copy the link in
        <mark>File URL</mark>
      </p>
    </div>
  </div>
  <div class="flex justify-end mt-5">
    <confect-button type="white" (click)="helpModal.hide()"
      >Close</confect-button
    >
  </div>

  <!-- COPY THIS :) -->
</confect-modal>

<confect-modal #modal>
  <confect-modal-success #modalSuccess (modalClosed)="modal.hide()" />
</confect-modal>

<confect-modal #feedDeletion (afterClose)="feedDeletionForm.resetForm()">
  <div
    style="width: 600px; max-width: 600px"
    class="bg-white rounded-md p-8 flex flex-col space-y-8"
  >
    <p class="text-lg font-bold mb-4">
      Deletion of your feed "{{ selectedFeed?.name }}"
    </p>

    <div class="flex flex-col space-y-8">
      <p class="font-bold">
        Warning: You are about to delete one of your feeds. Please make make
        sure of the following:
      </p>

      <p>
        If you are attempting to swap the feed for another, you should edit the
        current one and change the URL. By deleting and recreating a new feed,
        you risk forcing a
        <span class="font-bold"
          >complete reprocessing of all your product images.</span
        >
        While this is happening, all of your products will not be available in
        your automations for several hours.
      </p>

      <p>
        If you have multiple data sources on this account, and no longer needs
        this one at all, deleting is the correct course of action. Please only
        delete it if you are sure this is the case. Otherwise ask the live chat
        for directions.
      </p>
    </div>

    <form
      confect-form-inline-group
      [full]="true"
      #feedDeletionForm="ngForm"
      [showButton]="true"
      buttonTitle="Delete feed"
      [disabled]="feedDeletionForm.form.value.confirmation !== true"
      (ngSubmit)="confirmFeedDeletion(feedDeletionForm)"
    >
      <confect-checkbox
        title="I confirm that I still wish to continue"
        name="confirmation"
        ngModel
      />
      <!-- <confect-input
          title="Name"
          placeholder="Name"
          name="name"
          ngModel
          required
          minlength="2"
        ></confect-input> -->
    </form>
  </div>
</confect-modal>
