import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { SettingsService } from "@core/api/settings.service";
import { UsersService } from "@core/api/users.service";
import { AccessService } from "@core/services/access.service";
import { UserService } from "@core/services/user.service";

import { CModalSuccessComponent } from "@theme/@confect/components/modal-success/modal-success.component";
import { CModalComponent } from "@theme/@confect/components/modal/modal.component";
import { StripeCardInputComponent } from "@theme/components";
import { BillingSettingsFormComponent } from "@theme/components/billing-settings-form/billing-settings-form.component";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "ngx-intro-payment",
  templateUrl: "./intro-payment.component.html",
  styleUrls: ["./intro-payment.component.scss"],
})
export class IntroPaymentComponent implements OnInit, OnDestroy {
  @ViewChild("accountForm", { static: true })
  accountForm: BillingSettingsFormComponent;

  @ViewChild("cardForm", { static: true }) cardForm: StripeCardInputComponent;

  readyStatus = false;
  hideCardSave = true;

  cardInputValid = false;
  hasValidCard = false;

  trialActivated = false;

  company;

  unsubscribe$ = new Subject();

  @Input() featureName: string;
  @Input() v2: boolean = false;

  @ViewChild("modal") modal: CModalComponent;
  @ViewChild("modalSuccess") modalSuccess: CModalSuccessComponent;

  @Output() complete = new EventEmitter<any>();

  isLoading = false;

  constructor(
    private ss: SettingsService,
    public accessService: AccessService,
    private us: UserService,
    private usersService: UsersService,
  ) {}

  ngOnInit(): void {
    this.updateStatus();
    this.us
      .me(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.updateStatus();
      });

    this.usersService
      .getUserAccess()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((a) => this.accessService.updateAvailable(a));

    this.ss
      .getCompany()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((c) => {
        this.company = c;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  updateStatus() {
    const account = this.accountForm.billingGroup.valid;
    this.readyStatus = account && (this.cardInputValid || this.hasValidCard);
  }

  cardInputChanged(valid: boolean) {
    this.cardInputValid = valid;
    this.updateStatus();
  }

  validCardChanged(valid: boolean) {
    this.hasValidCard = valid;
    this.updateStatus();

    if (this.trialActivated) {
      this.isLoading = false;
      this.startTrial();
    }
  }

  startTrial() {
    if (!this.hasValidCard && this.cardInputValid) {
      this.trialActivated = true;
      this.isLoading = true;
      this.cardForm.saveCard();
      return;
    } else if (!this.hasValidCard) {
      this.modalSuccess.setupModal(
        false,
        true,
        "Card error",
        "Please check card info and try again",
      );
      this.modal.show();
      setTimeout(() => {
        this.modal.hide();
      }, 1500);
      this.trialActivated = false;
      this.isLoading = false;
      return;
    }

    this.isLoading = true;
    this.accountForm.signupSave();
  }

  startTrialCallback(success) {
    if (!success) {
      this.isLoading = false;

      return;
    }

    this.updateStatus();
    if (!this.readyStatus) {
      this.isLoading = false;

      return;
    }

    this.us
      .me(true)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (me) => {
          this.complete.emit(me);
          setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        },
        error: (_) => {
          this.isLoading = false;
        },
      });
  }
}
