import {
  Component,
  Input,
  Output,
  TemplateRef,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "confect-modal-template",
  templateUrl: "./modal-template.component.html",
})
export class CModalTemplateComponent {
  @Input() template: TemplateRef<any>;
  @Input() showClose: boolean = true;
  @Input() set extra(to: any) {
    this.templateContent = {
      ...(to != null && { extra: to }),
      close: this.close,
    };
  }
  @Output() modalClosed = new EventEmitter();

  templateContent: any;

  close = (bool) => {
    this.closeModal(bool);
  };

  constructor() {}

  closeModal(bool) {
    this.modalClosed.emit(bool);
  }
}
