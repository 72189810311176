<div class="flex flex-row h-full" style="min-height: 500px">
  <nav
    class="space-y-1 w-fit pr-5"
    aria-label="Sidebar"
    style="min-width: 250px"
  >
    <ng-content select="[ngxVerticalTab]" />
  </nav>
  <div class="border-l flex-1 pl-8">
    <ng-content />
  </div>
</div>
