import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";

@Directive({
  selector: "[uploadImage]",
})
export class UploadImageDirective implements OnDestroy {
  @HostBinding("class.fileover") fileOver = false;
  @HostBinding("class.fileDragging") fileDragging = false;
  @Output() fileDropped = new EventEmitter();

  @HostListener("dragover", ["$event"])
  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener("drop", ["$event"])
  ondrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    files.length && this.fileDropped.emit(files);
    this.fileOver = false;
    this.fileDragging = false;
  }

  @HostListener("body:dragover", ["$event"])
  onBodyDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileDragging = true;
  }

  @HostListener("body:drop", ["$event"])
  onBodyDrop(event: DragEvent) {
    event.preventDefault();
    this.fileDragging = false;
  }
  ngOnDestroy(): void {
    this.fileOver = false;
    this.fileDragging = false;
  }
}
