<ng-template #item>
  <input
    type="checkbox"
    [disabled]="disable"
    [name]="name"
    [id]="id"
    class="focus:ring-primary h-6 w-6 text-primary border-gray-300 rounded cursor-pointer"
    [ngClass]="{ 'bg-gray-300': disable }"
    [checked]="value"
    (input)="
      onChange($any($event).target.checked);
      valueChange.emit($any($event).target.checked)
    "
    (blur)="onTouched()"
  />
</ng-template>

@if (title !== "" && title != null) {
  <div class="sm:grid sm:grid-cols-8 sm:gap-4 sm:items-start sm:pb-3">
    <div class="sm:mt-px sm:col-span-5 flex items-center h-full">
      <label [for]="id" class="block">
        <confect-icon
          [icon]="icon"
          iconClass="text-xl font-medium text-gray-700 mr-2"
        >
          <span class="text-sm">{{ title }}</span>
        </confect-icon>
      </label>
    </div>
    <div class="sm:col-span-3 h-full">
      <div class="flex items-center justify-end h-full">
        <ng-container *ngTemplateOutlet="item" />
      </div>
    </div>
  </div>
}

@if (title === "" || title == null) {
  <ng-container *ngTemplateOutlet="item" />
}
