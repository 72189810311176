import { Component, Input, OnInit, Output, ViewChild } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { DropdownPosition, NgSelectComponent } from "@ng-select/ng-select";

@Component({
  selector: "confect-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CSelectComponent,
    },
  ],
})
export class CSelectComponent<T> implements OnInit, ControlValueAccessor {
  @Input() title = "";

  @Input() placeholder = "";
  @Input() name = "";
  @Input() id = "";

  @Input() compact = false;
  @Input() icon;
  @Input() inputIcon;
  @Input() inputText;
  @Input() options: T[] = [];

  @Input() bindValue = "id";
  @Input() bindLabel = "name";
  @Input() bindSubLabel = null;

  @Input() multiple? = false;
  @Input() groupBy?: string;

  @Input() searchable = true;
  @Input() clearable = false;
  @Input() dropdownPosition: DropdownPosition = "auto";

  // Label before the option inside the select
  @Input() preLabel?: string;

  // Label showing no matter what is selected
  @Input() staticLabel?: string;

  @Input() size: "base" | "xlarge" | "extra-height" = "base";
  @Input() styling: "standard" | "subtle" = "standard";

  @Output() change = new EventEmitter<any>();

  @ViewChild("selector") selector: NgSelectComponent;

  focused: boolean = false;

  // State
  _value = "";
  set value(to) {
    this._value = to;
  }
  get value() {
    return this._value;
  }

  touched = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {}

  // NgForm
  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
