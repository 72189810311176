var ATTR_TIMEAGO_TID = 'timeago-id';
/**
 * get the datetime attribute, `datetime` are supported.
 * @param node
 * @returns {*}
 */
export function getDateAttribute(node) {
  return node.getAttribute('datetime');
}
/**
 * set the node attribute, native DOM
 * @param node
 * @param timerId
 * @returns {*}
 */
export function setTimerId(node, timerId) {
  // @ts-ignore
  node.setAttribute(ATTR_TIMEAGO_TID, timerId);
}
/**
 * get the timer id
 * @param node
 */
export function getTimerId(node) {
  return parseInt(node.getAttribute(ATTR_TIMEAGO_TID));
}
