import { Pipe, PipeTransform } from "@angular/core";

import { CMath } from "@core/utils/confect-math";

@Pipe({
  name: "shortNumber",
  pure: true,
})
export class ShortNumberPipe implements PipeTransform {
  transform(input: any, args?: any): any {
    const suffixes = ["k", "M", "G", "T", "P", "E"];

    if (Number.isNaN(input)) {
      return null;
    }

    if (input < 10) {
      return CMath.round(input, args ?? 3);
    }

    if (input < 1000) {
      return Math.round(input);
    }

    const exp = Math.floor(Math.log(input) / Math.log(1000));

    return (input / Math.pow(1000, exp)).toFixed(args) + suffixes[exp - 1];
  }
}
