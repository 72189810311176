@if (viewType != null && previewItems?.length > 0) {
  <div
    class="grid"
    [ngClass]="{
      'grid-cols-1':
        viewType === 'preview' && previewItems[0].result_type === 'video',
      'grid-cols-2':
        cols === 2 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
      'grid-cols-3':
        cols === 3 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
      'grid-cols-4':
        cols === 4 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
      'grid-cols-5':
        cols === 5 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
      'gap-5':
        gap === 5 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
      'gap-8':
        gap === 8 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
      'gap-12':
        gap === 12 &&
        !(viewType === 'preview' && previewItems[0].result_type === 'video'),
    }"
  >
    @if (viewType === "output" || previewItems[0].result_type === "image") {
      @for (item of previewItems; track item) {
        <div class="flex flex-col space-y-2">
          <div
            class="bg-slate-50 border border-gray-200 rounded-md p-5"
            [ngClass]="{
              'hover:border-gray-300 hover:bg-slate-100 cursor-pointer':
                viewType === 'output',
            }"
            (click)="viewType === 'output' ? itemClick.emit(item) : null"
          >
            @if (previewItems[0].result_type === "video") {
              <video
                class="rounded object-contain object-center"
                [controls]="false"
                [src]="item.url"
              ></video>
            } @else {
              <img
                [src]="item.url"
                class="rounded object-contain object-center"
              />
            }
          </div>
          <ng-template
            [ngTemplateOutlet]="itemInfo || default"
            [ngTemplateOutletContext]="{
              item: item,
            }"
          />
          <ng-template #default />
        </div>
      }
    }
    @if (viewType !== "output" && previewItems[0].result_type === "video") {
      <video
        class="aspect-square w-10/12 ring-1 ring-slate-200 mx-auto h-full"
        [controls]="viewType === 'preview'"
        [src]="previewItems[0].url"
      ></video>
    }
  </div>
}
