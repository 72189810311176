<button
  (click)="buttonClick($event)"
  name="submitButton"
  [type]="submit ? 'submit' : 'button'"
  class="button"
  [ngClass]="{ 'button-block': block === true, disabled: disabled }"
  #anchor
>
  <confect-icon [icon]="icon" [iconClass]="iconClass">
    <ng-content />
  </confect-icon>
</button>

@if (showMenu) {
  <confect-dropdown
    #dropdown
    [items]="dropdownItems"
    [anchor]="anchor"
    [listen]="showMenu"
    (dropdownItemSelected)="itemSelected($event)"
  />
}
