import { Component, Inject, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";

import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateAdapter,
} from "@angular-material-components/datetime-picker";
import {
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NgxMatMomentAdapter,
} from "@angular-material-components/moment-adapter";

export interface DateParse {
  dateInput: string;
}
export type DateDisplay = DateParse & {
  monthYearLabel?: string;
  dateA11yLabel?: string;
  monthYearA11yLabel?: string;
  enableMeridian?: boolean;
};
export class CustomDateAdapter {
  showTime = false;

  private _parse: DateParse = {
    dateInput: this.showTime ? "DD/MM/YYYY LT" : "DD/MM/YYYY",
  };
  private _display: DateDisplay = {
    dateInput: this.showTime ? "DD/MM/YYYY LT" : "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMM YYYY",
    enableMeridian: false,
  };

  set parse(parse: DateParse) {
    this._parse = Object.assign({}, this._parse, parse);
  }

  get parse(): DateParse {
    return this._parse;
  }

  set display(display: DateDisplay) {
    this._display = Object.assign({}, this._display, display);
  }

  get display(): DateDisplay {
    return this._display;
  }

  updateDateFormat(parse: DateParse, display?: DateDisplay) {
    this.parse = parse;
    if (!display) {
      display = parse;
    }
    this.display = display;
  }

  update() {
    this._parse = {
      dateInput: this.showTime ? "DD/MM/YYYY LT" : "DD/MM/YYYY",
    };
    this._display = {
      dateInput: this.showTime ? "DD/MM/YYYY LT" : "DD/MM/YYYY",
      monthYearLabel: "MMM YYYY",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MMM YYYY",
      enableMeridian: false,
    };
  }
}

@Component({
  selector: "confect-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CCalendarComponent,
    },
    {
      provide: NgxMatDateAdapter,
      useClass: NgxMatMomentAdapter,
    },
    {
      provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
    {
      provide: NGX_MAT_DATE_FORMATS,
      useClass: CustomDateAdapter,
    },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class CCalendarComponent implements OnInit, ControlValueAccessor {
  DATE_FORMAT_DEFAULT = "dd-mm-yy";

  @Input() title = "";

  @Input() placeholder = "";
  @Input() name = "";
  // @Input() id = "";
  @Input() icon: string | null = null;
  @Input() compact = false;
  @Input() centered = false;
  @Input() disabled = false;

  @Input() showButtons = false;

  @Input() set showTime(to: boolean) {
    this.hideTime = !to;
    this.config.showTime = to;
    this.config.update();
  }
  @Input() timeOnly = false;

  @Input() minDate = null;
  @Input() maxDate = null;

  @Input() view = "day";

  // State
  value: Date = null;
  hideTime = true;
  touched = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  constructor(@Inject(NGX_MAT_DATE_FORMATS) public config: CustomDateAdapter) {}

  ngOnInit(): void {}

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
