import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { CDropdownItem } from "../dropdown/dropdown.component";

@Component({
  selector: "confect-action-button",
  templateUrl: "./action-button.component.html",
})
export class CActionButtonComponent implements OnInit, OnDestroy {
  @Input() items: CDropdownItem[][] = [];
  @Input() threeDotsDropdown = false;
  @Input() disable: boolean = false;

  @ViewChild("anchor", { static: false }) anchor!: ElementRef;

  showMenu = false;

  @Input() enableDefaultAction = true;

  @Output() dropdownItemSelected = new EventEmitter<CDropdownItem>();
  @Output() action = new EventEmitter<MouseEvent>();

  @Input() title = "Open";

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.showMenu = false;
    this.determineListen();
  }

  determineListen() {
    if (this.showMenu) {
      window.addEventListener("mousedown", this.mouseDown, true);
    } else {
      window.removeEventListener("mousedown", this.mouseDown);
    }
  }

  menuToggle(event: MouseEvent) {
    this.showMenu = !this.showMenu;
    this.determineListen();
  }

  itemSelected(selection: CDropdownItem) {
    this.showMenu = false;
    this.determineListen();
    this.dropdownItemSelected.emit(selection);
  }

  mouseDown = (event: MouseEvent) => {
    const target = event.target as any;
    const targetParent = target.parentElement;

    const role = target["attributes"]["role"];
    const isMenuItem = role?.value === "menuitem";

    const targetIsMenu = target.id === "option-menu";
    const parentIsMenu = targetParent.id === "option-menu";

    if (!targetIsMenu && !parentIsMenu && !isMenuItem) {
      this.showMenu = false;
    }

    this.determineListen();
  };
}
