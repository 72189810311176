<div
  class="fixed inset-0 overflow-hidden"
  style="z-index: 1050"
  [class.hidden]="hidden"
>
  <div class="absolute inset-0 overflow-hidden">
    <div
      class="h-[100vh] flex-grow bg-gray-400 transition-all duration-500 ease-in-out opacity-0"
      [ngClass]="{
        'opacity-25': expanded,
        'opacity-0': !expanded,
      }"
      (click)="hide(); this.cancel.emit()"
    ></div>
    <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
      <div
        class="max-w-md transform transition ease-in-out duration-500 sm:duration-700 translate-x-full"
        [ngClass]="{
          'translate-x-0': expanded,
          'translate-x-full': !expanded,
        }"
      >
        <div
          class="h-full flex flex-col py-6 bg-white overflow-y-scroll"
          (scroll)="onScroll($event)"
        >
          <div class="px-4 sm:px-6">
            <div class="flex items-start justify-between">
              <h2
                id="slide-over-heading"
                class="text-lg font-medium text-gray-900"
              >
                {{ header }}
              </h2>
              <div class="ml-3 h-7 flex items-center">
                <confect-button
                  type="white"
                  (click)="hide(); this.cancel.emit()"
                >
                  <span class="sr-only">Close panel</span>
                  <confect-icon
                    icon="clear"
                    iconClass="text-xl text-gray-400"
                  />
                </confect-button>
              </div>
            </div>
          </div>
          <div class="mt-6 relative flex-1 px-4 sm:px-6">
            <ng-content />
            <ng-template #contentTarget />
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
