<div class="flex flex-col space-y-16 items-center">
  @for (intg of integrations; track intg) {
    <div class="w-[600px]">
      <div
        [ngClass]="{
          'flex items-center justify-between': !singleMode,
        }"
      >
        <div
          class="flex flex-col space-y-2"
          [ngClass]="{ 'justify-center items-center mb-4': singleMode }"
        >
          @if (!singleMode) {
            <h6 class="text-lg font-semibold">
              {{ intg.display }}
            </h6>
          }
          @if (intg.issues != null) {
            <p class="text-red-700 font-bold text-lg">Not working</p>
          }
          @if (intg.issues == null) {
            @if (intg.all_completed) {
              <p class="text-green-700 font-bold text-lg">
                @if (!intg.owner_name) {
                  Connected
                } @else {
                  Connected as {{ intg.owner_name }}
                }
              </p>
            }
            @if (!intg.state.auth_step.completed && !singleMode) {
              <p class="text-red-700 font-bold text-lg">Not connected</p>
            }
          }
          @if (intg.issues != null) {
            <div>
              This integration does not seem to be working.
              <span
                class="underline cursor-pointer whitespace-nowrap"
                (click)="currentIssues = intg.issue_messages; issueModal.show()"
                >Click here</span
              >
              for more info.
            </div>
          }
        </div>
        <div
          class="flex flex-col justify-center"
          [ngClass]="{ 'items-center': singleMode }"
        >
          <confect-button
            (click)="activateIntegration(intg)"
            [type]="singleMode ? 'primary' : 'white'"
            [size]="singleMode ? 'xxlarge' : 'xlarge'"
          >
            {{
              intg.all_completed
                ? "Reconnect"
                : !intg.state.auth_step.completed
                  ? singleMode
                    ? "Connect " + intg.display
                    : "Connect"
                  : !intg.state.setup_step.completed &&
                      intg.state.setup_step.required
                    ? "Continue setup"
                    : "Connect"
            }}
          </confect-button>
          @if (
            intg.state.auth_step.completed &&
            !intg.state.setup_step.completed &&
            intg.state.setup_step.required
          ) {
            <span
              (click)="activateIntegration(intg, true, true)"
              class="text-center mt-1 text-sm font-bold cursor-pointer hover:underline"
              >Reconnect</span
            >
          }
        </div>
      </div>
    </div>
  }

  @if (!singleMode) {
    <div id="sleekbox-settings-integrations" class="w-full"></div>
  }
</div>

<confect-modal #issueModal>
  <div
    style="width: 600px; max-width: 600px"
    class="bg-white rounded-md p-4 relative flex flex-col space-y-8"
  >
    <h6 class="self-center">
      The following errors were reported for this integration:
    </h6>

    @for (issue of currentIssues; track issue) {
      <p>
        <span class="font-bold">{{ issue.issue }}: </span
        >{{ issue.information?.message }}
      </p>
    }

    <div>
      <p class="text-sm mt-12 text-gray-500 text-center">
        If you removed any of the permissions when you authenticated the Confect
        app in Facebook, you need to
        <a
          href="https://www.facebook.com/settings?tab=business_tools&section=active"
          target="_blank"
          >click here</a
        >
        and enable all permissions for Confect.
      </p>
    </div>
  </div>
</confect-modal>

<confect-modal #infoModal>
  <div
    style="width: 600px; max-width: 600px; min-height: 300px"
    class="bg-white rounded-md p-8 relative"
  >
    @if (currentInfoData.length > 0) {
      <div
        confect-form-inline-group
        [full]="true"
        [showButton]="true"
        [saveEmit]="true"
        [disabled]="isSavingStep"
        (save)="saveStep()"
      >
        @for (d of currentInfoData; track d) {
          @if (d.type === "string") {
            <confect-input
              [ngModel]="rawInfo[d.name]"
              (ngModelChange)="infoChange(d, $event)"
              [title]="d.display"
              [placeholder]="d.display"
            />
          }
          @if (d.type === "option") {
            <confect-select
              [title]="d.display"
              [ngModel]="rawInfo[d.name]"
              (change)="infoChange(d, $event)"
              placeholder="Choose one"
              [options]="d.options"
              bindValue="display"
              bindLabel="display"
              dropdownPosition="auto"
            />
          }
        }
      </div>
      <div class="text-sm text-gray-500 py-4">
        If your wanted adaccount / business does not show up, please check if
        you gave Confect all requested permissions in Facebook. To fix this
        click
        <a
          href="https://www.facebook.com/settings/applications/app_details/?app_id=414750789470592"
          target="_blank"
          >here</a
        >
        and remove the Confect app, then try reconnecting.
      </div>
    }
  </div>
</confect-modal>
