<div class="space-y-4 sm:space-y-2">
  @if (title !== "") {
    <div [ngClass]="{ 'sm:grid sm:grid-cols-4': !full, 'w-full': full }">
      <div
        class="border-b border-gray-200 pb-2"
        [ngClass]="{
          'sm:col-span-2 sm:col-start-2': !full,
        }"
      >
        @if (title !== "") {
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ title }}
          </h3>
        }
        @if (description !== "") {
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{ description }}
          </p>
        }
      </div>
    </div>
  }

  <div
    class="grid"
    [ngClass]="{
      'grid-cols-4': !full,
      'grid-cols-2': full,
    }"
  >
    <div class="sm:col-span-2" [ngClass]="{ 'sm:col-start-2': !full }">
      <ng-content />
    </div>
  </div>

  @if (showButton) {
    <div class="sm:grid sm:grid-cols-4">
      <div
        class="sm:col-span-1 pt-2"
        [ngClass]="{ 'sm:col-start-3': !full, 'sm:col-start-4': full }"
      >
        <div class="float-right">
          <confect-button
            (click)="saveEmit ? save.emit(true) : null"
            [submit]="true"
            [disabled]="disabled"
            >{{ buttonTitle }}</confect-button
          >
        </div>
      </div>
    </div>
  }
</div>
