import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

@Component({
  selector: "confect-slide-over",
  templateUrl: "./slide-over.component.html",
})
export class CSlideOverComponent implements OnInit {
  @Input() header = "";

  @Output() scroll = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();
  @Output() afterClose = new EventEmitter<any>();
  @Output() escaped = new EventEmitter<any>();

  expanded = false;
  hidden = true;

  @ViewChild("contentTarget", { read: ViewContainerRef, static: true })
  public contentContainerRef: ViewContainerRef;

  constructor() {}

  ngOnInit(): void {}

  show() {
    this.hidden = false;

    // Make sure slideover is translated away before expanding,
    // otherwise animation will not work.
    setTimeout(() => {
      this.expanded = true;
    }, 1);
  }

  expand() {
    this.hidden = false;

    // Make sure slideover is translated away before expanding,
    // otherwise animation will not work.
    setTimeout(() => {
      this.expanded = true;
    }, 1);
  }

  hide() {
    this.expanded = false;
    setTimeout(() => {
      // Hide after the animation
      this.hidden = true;
      this.afterClose.emit();
    }, 700);
  }

  onScroll(event) {
    this.scroll.emit(event);
  }

  interupt() {
    if (this.expanded) {
      this.hide();
      this.escaped.emit();
    }
  }

  @HostListener("window:keydown.esc", ["$event"])
  escape(_e) {
    if (this.expanded) {
      this.hide();
      this.escaped.emit();
    }
  }
}
