<div>
  @if (showFilters) {
    <div class="flex items-center justify-end mb-5">
      <confect-button type="white" (click)="showCreativesFilters()">
        <span class="text-sm"
          >Filters ({{ getNumberOfAppliedFilters(selectedFilters) }})</span
        >
      </confect-button>
    </div>
  }
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div>
          <ngx-design-previewer
            [previewItems]="previewItems"
            [viewType]="'preview'"
            [cols]="cols"
          />

          @if (isLoadingNextPage) {
            <div class="mt-5">
              <mat-progress-bar
                [mode]="
                  currentProgress === -1 ? 'indeterminate' : 'determinate'
                "
                [value]="currentProgress"
              />
            </div>
          }
        </div>

        @if (
          !isLoadingNextPage &&
          shouldLoadNextPage &&
          page >= 1 &&
          previewItems[0]?.result_type !== "video"
        ) {
          <div class="mt-5 flex justify-center">
            <confect-button
              size="large"
              type="secondary"
              (click)="loadNextPage()"
              >Load More</confect-button
            >
          </div>
        }
      </div>
    </div>
  </div>
</div>
