import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  TemplateRef,
} from "@angular/core";

import { ItemInfoDirective } from "./design-previewer.directive";

@Component({
  selector: "ngx-design-previewer",
  templateUrl: "./design-previewer.component.html",
})
export class DesignPreviewerComponent {
  @ContentChild(ItemInfoDirective, { read: TemplateRef })
  itemInfo: TemplateRef<any>;

  @Input() previewItems: any[] = [];
  @Input() cols = 4;
  @Input() gap = 5;
  @Input() viewType: "preview" | "output" = "output";

  @Output() itemClick = new EventEmitter();

  constructor() {}
}
