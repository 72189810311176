import { Injectable } from "@angular/core";

import { ProductFilter } from "@core/models/products.types";

@Injectable({
  providedIn: "root",
})
export class FilterSpecDisplayService {
  toList(filterSpec: ProductFilter): string[] {
    if (filterSpec === undefined) {
      return [];
    }

    // Flatten the filters
    const f = {};
    for (const key of Object.keys(filterSpec)) {
      const elem = filterSpec[key];

      if (Array.isArray(elem) || key === "has_properties") {
        f[key] = elem;
      } else if (typeof elem === "object") {
        for (const nestedKey of Object.keys(elem)) {
          f[nestedKey] = elem[nestedKey];
        }
      } else {
        f[key] = elem;
      }
    }

    let res = [];

    for (const key of Object.keys(f)) {
      const count = f[key].length;

      // Special case
      if (key === "on_sale") {
        res.push("is on sale");
        continue;
      }

      if (key === "bg_status") {
        const v = f[key];

        if (!v) {
          continue;
        }

        switch (v) {
          case "REMOVED":
            res.push("image background is removed");
            break;
          case "NOT_REMOVED":
            res.push("image is with background");
            break;
        }

        continue;
      }

      if (key === "num_additional_images") {
        const v: any[] | null | undefined = f[key];
        if (!v) {
          continue;
        }

        res.push(`Has exactly ${v.join(" or ")} additional images`);
        continue;
      }

      if (key === "has_properties") {
        const allHas = (
          filterSpec.has_properties.additional_fields ?? []
        ).concat(filterSpec.has_properties.custom_fields ?? []);

        allHas.forEach((has) => {
          res.push(`has field '${has}'`);
        });
        continue;
      }

      let val = "";
      switch (count) {
        case 0:
          break;
        case 1:
          val = `${key} = ${f[key]}`;
          if (val.length > 40) {
            val = val.slice(0, 40) + "...";
          }
          res.push(val);
          break;
        default:
          val = `${key} = [${f[key]}]`;
          if (val.length > 39) {
            val = val.slice(0, 39) + "...]";
          }
          res.push(val);
      }
    }

    if (res.length === 0) {
      res = ["All products"];
    }

    return res;
  }
}
