<ng-template #slideover let-close="close">
  <div class="flex space-x-3 mb-3 items-center">
    <confect-input
      class="flex-grow"
      placeholder="Search medias"
      [(ngModel)]="source.searchString"
      (keyup.enter)="source.refreshMedias()"
    />
    <confect-button (click)="uploadMedia()">Upload</confect-button>
  </div>

  @if (mediaSources.length > 1) {
    <div class="flex items-center justify-center mb-3">
      <span class="relative z-0 inline-flex shadow-sm rounded-md">
        @for (source of mediaSources; track source.key; let i = $index) {
          <button
            type="button"
            class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-primary"
            (click)="changeSource(source)"
            [ngClass]="{
              'rounded-l-md': i === 0,
              'rounded-r-md': i === mediaSources.length - 1,
              'bg-gray-400 text-white': currentSource?.key === source.key,
              'bg-white hover:bg-gray-50 text-gray-700':
                currentSource?.key !== source.key,
            }"
          >
            {{ source.title }}
          </button>
        }
      </span>
    </div>
  }

  <ul class="grid gap-6 grid-cols-2 pb-4">
    @for (media of source.media; track media.id) {
      <li
        class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
      >
        <div class="flex-1 flex flex-col p-3">
          @if (media.type === "video") {
            <div class="w-32 h-32 flex-shrink-0 mx-auto">
              <video [src]="media.image" autoplay muted loop></video>
            </div>
          } @else {
            <div
              class="w-32 h-32 flex-shrink-0 mx-auto"
              [class.checkered]="transparencyCheckers"
            >
              <div
                class="bg-transparent w-full h-full"
                [lazyLoad]="media.image"
                [ngClass]="{
                  'bg-repeat': patternMode,
                  'bg-contain bg-no-repeat bg-center': !patternMode,
                }"
                alt=""
              ></div>
            </div>
          }

          <h3
            class="mt-3 text-gray-900 text-sm font-medium overflow-ellipsis overflow-hidden w-32 mx-auto"
          >
            {{ media.name }}
          </h3>
        </div>
        <div>
          <div class="-mt-px flex divide-x divide-gray-200">
            <div class="flex flex-grow">
              <a
                class="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-2 text-md text-gray-700 font-bold border border-transparent rounded-bl-lg hover:text-gray-500 cursor-pointer"
                (click)="mediaSelected.emit(media); close()"
              >
                Select
              </a>
            </div>
            <div class="-ml-px">
              <confect-icon-button
                icon="delete_outlined"
                iconClass="text-lg p-3 text-gray-500"
                (click)="deactivateMedia(media)"
              />
            </div>
          </div>
        </div>
      </li>
    }
  </ul>
</ng-template>
