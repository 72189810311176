<div
  class="origin-top-left fixed mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 z-30"
  role="menu"
  aria-orientation="vertical"
  aria-labelledby="options-menu"
  [style.right.px]="_right"
  [style.top.px]="_top"
  #menu
>
  @for (group of items; track group) {
    <div class="py-1">
      @for (item of group; track item) {
        <a
          (click)="itemSelected(item)"
          class="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer w-full h-full"
          role="menuitem"
        >
          @if (item.icon) {
            <confect-icon
              [icon]="item.icon"
              iconClass="mr-1 text-gray-400 group-hover:text-gray-500 text-xl"
            />
          }
          {{ item.title }}
        </a>
      }
    </div>
  }
</div>
