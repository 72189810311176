import { Pipe, PipeTransform } from "@angular/core";

import { CMath } from "@core/utils/confect-math";

@Pipe({
  name: "cRound",
  pure: true,
})
export class CRoundPipe implements PipeTransform {
  transform(input: number, decimals: number): any {
    return CMath.round(input, decimals);
  }
}
