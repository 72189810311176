import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AccountFeatureInfo } from "@core/api-models/settings.models";
import { SettingsService } from "@core/api/settings.service";
import { AccessService } from "@core/services/access.service";

import { Subject } from "rxjs";

@Component({
  selector: "ngx-feature-banner",
  templateUrl: "./feature-banner.component.html",
})
export class FeatureBannerComponent implements OnInit, OnDestroy {
  currentAccount: number;
  info: AccountFeatureInfo;

  @Input() topText = "";
  @Input() bottomText = "";

  @Input() featureKey?: string;

  show = false;
  unsubscribe$ = new Subject();

  @Input() tooltip?: string;
  constructor(
    private ss: SettingsService,
    private access: AccessService,
    private router: Router,
  ) {
    this.currentAccount = this.access.current.account.id;
  }

  checkTrials() {
    this.ss.getAccountFeatures(this.currentAccount).subscribe((f) => {
      this.info = f.filter((t) => t.key === "feed")[0];
    });
  }

  checkShow() {
    this.show = this.featureKey
      ? !this.access.hasFeature(this.featureKey)
      : false;
  }

  ngOnInit(): void {
    this.checkShow();
    this.checkTrials();
    this.access.accessChanged$.subscribe((ch) => {
      this.checkShow();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
  }

  startTrial() {
    if (this.access.current.company.subscription_v2 !== true) {
      this.router.navigate(["/settings", "features"], {
        queryParams: { activate: this.featureKey },
      });
    } else {
      this.router.navigate(["/settings", "billing"]);
    }
  }
}
