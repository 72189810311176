<div
  #tooltip
  class="fixed z-[1050] transition-all duration-300"
  [ngClass]="{
    'opacity-100 ': show,
    'opacity-0 ': !show,
    'pl-[10px]': _position() === 'right',
    'pr-[10px]': _position() === 'left',
    'pt-[10px]': _position() === 'below',
    'pb-[10px]': _position() === 'above',
    invisible: tooltipBox() == null,
  }"
  [style.top.px]="top()"
  [style.left.px]="left()"
  (mouseenter)="hover(true)"
  (mouseleave)="hover(false)"
>
  <div
    class="rounded-md px-3 py-2 bg-gray-700 text-white max-w-[500px] text-wrap"
  >
    <ng-template
      [ngTemplateOutlet]="template || default"
      [ngTemplateOutletContext]="{ close: close }"
    />
  </div>
</div>
<ng-template #default
  ><div [innerHTML]="tooltipText | safe: 'html'"></div
></ng-template>
