<ng-template #item>
  <!-- max-w-lg max-w-xs -->

  @if (suffix == null) {
    <div
      (click)="input.click(); input.focus()"
      class="input flex w-full border-none sm:text-sm rounded-md ring-inset cursor-text bg-white"
      [ngClass]="{
        'ring-2 ring-primary': focused,
        'ring-1 ring-slate-200': !focused && styling !== 'subtle',
        'hover:ring-1 hover:ring-slate-200': !focused && styling === 'subtle',
      }"
    >
      <div
        class="flex flex-shrink text-gray-400 items-center justify-center"
        [ngClass]="{ 'pl-2': inputIcon != null || inputText != null }"
      >
        <confect-icon
          drag
          [dragStart]="dragStart"
          [dragMove]="dragMove"
          [dragDisable]="!dragToAdjust"
          [ngClass]="{
            'cursor-ew-resize': dragToAdjust,
            'select-none': !dragToAdjust,
          }"
          [icon]="inputIcon"
          >{{ inputText }}</confect-icon
        >
      </div>
      <input
        #input
        class="w-full h-full sm:text-sm"
        [ngClass]="{ inputIcon: inputIcon != null || inputText != null }"
        [type]="type"
        [name]="name"
        [placeholder]="placeholder"
        [ngClass]="{ 'text-center': centered, 'bg-gray-100': readonly }"
        [value]="roundedValue()"
        [disabled]="disabled"
        (input)="onChange(parseInput($event))"
        (blur)="onTouched()"
        [min]="min"
        [max]="max"
        (click)="clicked($event)"
        [readonly]="readonly"
        (focus)="focused = true"
        (blur)="focused = false"
        [accept]="accept"
      />
    </div>
  }

  @if (suffix != null) {
    <div class="mt-1 flex rounded-md">
      <div
        (click)="input.click(); input.focus()"
        class="input flex-1 min-w-0 flex w-full border-none rounded-none rounded-l-md sm:text-sm ring-inset cursor-text bg-white"
        [ngClass]="{
          'ring-2 ring-primary': focused,
          'ring-1 ring-slate-200': !focused && styling !== 'subtle',
          'hover:ring-1 hover:ring-slate-200': !focused && styling === 'subtle',
        }"
      >
        <div
          class="flex flex-shrink text-gray-400 items-center justify-center"
          [ngClass]="{ 'pr-2': inputIcon != null || inputText != null }"
        >
          <confect-icon [icon]="inputIcon">{{ inputText }}</confect-icon>
        </div>
        <input
          #input
          class="w-full h-full sm:text-sm"
          [type]="type"
          [name]="name"
          [id]="name"
          [placeholder]="placeholder"
          [ngClass]="{ 'text-center': centered, 'bg-gray-100': readonly }"
          [value]="roundedValue()"
          [disabled]="disabled"
          (input)="onChange(parseInput($event))"
          (blur)="onTouched()"
          (click)="clicked($event)"
          [min]="min"
          [max]="max"
          (focus)="focused = true"
          (blur)="focused = false"
          [readonly]="readonly"
          [accept]="accept"
        />
      </div>
      <span
        class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
      >
        {{ suffix }}
      </span>
    </div>
  }
</ng-template>

@if (title !== "" && title != null) {
  <div
    class="grid gap-4 items-start pb-3"
    [ngClass]="{ 'grid-cols-5': compact, 'grid-cols-2': !compact }"
  >
    <div
      class="mt-px col-span-3 flex items-center h-full"
      [ngClass]="{ 'col-span-1': !compact, 'col-span-3': compact }"
    >
      <label [for]="name" class="block m-0">
        <confect-icon
          [icon]="icon"
          iconClass="text-xl font-medium text-gray-700 mr-2"
        >
          <span class="text-sm">{{ title }}</span>
        </confect-icon>
      </label>
    </div>
    <div [ngClass]="{ 'col-span-1': !compact, 'col-span-2': compact }">
      <ng-container *ngTemplateOutlet="item" />
    </div>
  </div>
}

@if (title === "" || title == null) {
  <ng-container *ngTemplateOutlet="item" />
}
