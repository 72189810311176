import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import {
  IntegrationInfo,
  IntegrationIssue,
  IntegrationStateInfoData,
  IntegrationStateInfoRedirect,
  IntegrationStepInfo,
} from "@core/api-models/integration.models";
import { IntegrationService } from "@core/api/integration.service";
import { UserService } from "@core/services/user.service";

import { CModalComponent } from "@theme/@confect/components/modal/modal.component";

@Component({
  selector: "ngx-integrations-helper",
  templateUrl: "./integrations-helper.component.html",
  styleUrls: ["./integrations-helper.component.scss"],
})
export class IntegrationsHelperComponent implements OnInit {
  // @Input() agency = false;

  @Input() location: "settings" | "agency" | "onboarding" = "settings";

  integrations: IntegrationInfo[] = [];

  currentInfoData: IntegrationStateInfoData[] = [];
  currentInfo = {};
  rawInfo = {};

  currentIntegration?: IntegrationInfo;
  currentStep?: "auth" | "setup";
  currentStepInfo?: IntegrationStepInfo;

  isSavingStep = false;

  @ViewChild("infoModal") infoModal: CModalComponent;

  @Input() singleMode?: "facebook_business" | null = null;

  @Output() integrationFinalized = new EventEmitter<IntegrationInfo>();

  currentIssues: IntegrationIssue[] = [];

  get agency(): boolean {
    return this.location === "agency";
  }

  constructor(
    private is: IntegrationService,
    private route: ActivatedRoute,
    private us: UserService,
  ) {}

  ngOnInit(): void {
    this.updateIntegrations(true);
  }

  updateIntegrations(autoActivate = false) {
    this.is.getIntegrations(this.agency).subscribe((res) => {
      this.integrations = res
        .filter((i) => {
          return this.singleMode == null || i.name === this.singleMode;
        })
        .map((intg) => {
          intg["issue_messages"] = Object.values(intg.issues ?? {});
          return intg;
        });

      if (!autoActivate) {
        return;
      }

      const a = this.route.snapshot.queryParamMap.get("activate");
      if (!a) {
        return;
      }

      const intg = this.integrations.find((i) => i.name === a);
      if (!intg) {
        return;
      }

      this.activateIntegration(intg, false);
    });
  }

  askForInfo(to: IntegrationStateInfoData[]) {
    this.currentInfoData = to;
    this.currentInfo = {};
    this.rawInfo = {};
    to.forEach((d) => {
      this.currentInfo[d.name] = d.type === "string" ? "" : null;
      this.rawInfo[d.name] = d.type === "string" ? "" : null;
    });
    this.infoModal.show();
  }

  infoChange(info: IntegrationStateInfoData, to: any) {
    this.currentInfo[info.name] = info.type === "option" ? to.contents : to;
    this.rawInfo[info.name] =
      info.type === "option" ? to.display : this.currentInfo[info.name];
  }

  activateIntegration(
    intg: IntegrationInfo,
    allowRestart = true,
    forceRestart = false,
  ) {
    this.currentIntegration = intg;
    this.currentStep = null;
    this.currentStepInfo = null;

    if (
      !intg.state.auth_step.completed ||
      (intg.all_completed && allowRestart) ||
      forceRestart
    ) {
      this.currentStep = "auth";
      this.is
        .startIntegrationAuth(
          this.agency,
          intg.name,
          this.location === "onboarding" ? "/onboarding" : null,
        )
        .subscribe((info) => {
          if (info.type === "redirect") {
            const c = info.content as IntegrationStateInfoRedirect;
            window.location.href = c.url;
          } else if (info.type === "info") {
            const c = info.content as IntegrationStateInfoData[];
            this.askForInfo(c);
          }
        });
    } else if (!intg.state.setup_step.completed) {
      this.currentStep = "setup";
      this.is
        .startIntegrationSetup(this.agency, intg.name)
        .subscribe((info) => {
          this.currentStepInfo = info;
          if (info.type === "info") {
            const c = info.content as IntegrationStateInfoData[];
            this.askForInfo(c);
          }
        });
    }
  }

  saveStep() {
    const stepCallbackSuccess = (res) => {
      this.infoModal.hide();
      // Check for next step and move on?
      if (this.currentStep === "auth") {
        this.currentIntegration.state.auth_step.completed = true;

        if (this.currentIntegration.state.setup_step.required) {
          this.currentIntegration.state.setup_step.completed = false;
          // Continue to setup
          this.activateIntegration(this.currentIntegration, false);
          return;
        }
      }

      this.integrationFinalized.emit(this.currentIntegration);

      // If done or no next step, reset
      this.currentIntegration = null;
      this.currentStep = null;
      this.currentStepInfo = null;
      this.updateIntegrations();
    };

    // Save current step
    this.isSavingStep = true;
    const obs =
      this.currentStep === "auth"
        ? this.is.finalizeIntegrationAuth(
            this.agency,
            this.currentIntegration.name,
            this.currentInfo,
          )
        : this.is.finalizeIntegrationSetup(
            this.agency,
            this.currentIntegration.name,
            this.currentInfo,
          );
    obs.subscribe({
      next: (res) => {
        stepCallbackSuccess(res);
        this.us.me(true).subscribe();
      },
      error: (err) => {
        this.isSavingStep = false;
        this.us.me(true).subscribe();
      },
      complete: () => (this.isSavingStep = false),
    });
  }
}
