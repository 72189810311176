<div
  class="max-w-screen-lg p-2 bg-white rounded-md flex flex-col items-center justify-center"
  style="width: 500px"
>
  <div class="flex items-center justify-center mb-8 w-[150px] h-[150px]">
    <img
      [src]="
        modalSuccess
          ? '/assets/icons/modal-icons/check.svg'
          : '/assets/icons/modal-icons/x.svg'
      "
      class="transition-all duration-300 aspect-square rounded-full border-[4px] border-solid p-5"
      [ngClass]="{
        'w-[20px]': start,
        'w-[150px]': end,
        'border-green-600': modalSuccess,
        'border-red-600': !modalSuccess,
      }"
    />
  </div>

  <h2 class="block text-center mb-4">
    @if (modalTitle) {
      <span>{{ modalTitle }}</span>
    }
    @if (!modalTitle && modalSuccess) {
      <span>Done!</span>
    }
    @if (!modalTitle && !modalSuccess) {
      <span>Oops...</span>
    }
  </h2>
  @if (showModalText) {
    <div class="text-center">
      @if (modalText) {
        <span>{{ modalText }}</span>
      }
      @if (!modalText && !modalSuccess) {
        <span>Something went wrong! Please try again later.</span>
      }
    </div>
  }
  <div class="w-full flex items-center justify-center mt-8">
    <confect-button type="secondary" (click)="closeModal()">
      {{ buttonText }}
    </confect-button>
  </div>
</div>
