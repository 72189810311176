<div class="h-full w-full flex flex-col divide-y">
  <div
    class="w-full flex space-x-2"
    #header
    [ngClass]="{
      'justify-between': _tabs.length > tabCount,
      'justify-start': _tabs.length <= tabCount,
      'mx-2': padding,
    }"
  >
    @for (tab of _tabs; let i = $index; track tab) {
      @if (i < tabCount - 2 || _tabs.length <= tabCount) {
        <div class="flex flex-shrink" (click)="setCurrentTab(tab.id)">
          <ng-template
            [ngTemplateOutlet]="tabTemplate || defaultTabTemplate"
            [ngTemplateOutletContext]="{ tab: tab, currentTab: currentTab() }"
          />
        </div>
      }
    }
    @if (_tabs.length > tabCount) {
      <div class="flex flex-shrink" (click)="setCurrentTab(lastTab().id)">
        <ng-template
          [ngTemplateOutlet]="tabTemplate || defaultTabTemplate"
          [ngTemplateOutletContext]="{
            tab: lastTab(),
            currentTab: currentTab(),
          }"
        />
      </div>

      <div (click)="more.rightClick($event, currentTab())">
        <ng-template
          [ngTemplateOutlet]="tabTemplate || defaultTabTemplate"
          [ngTemplateOutletContext]="{
            tab: { icon: 'more_horizontal', name: 'More', id: 'more' },
          }"
        />
      </div>
    }
  </div>
  <div class="w-full h-full overflow-y-auto overflow-x-hidden py-4">
    <ng-template
      [ngTemplateOutlet]="contentTemplate"
      [ngTemplateOutletContext]="{ currentTab: currentTab() }"
    />
  </div>
</div>

<ng-template #defaultTabTemplate let-tab="tab" let-currentTab="currentTab">
  <div
    class="cursor-pointer aspect-square w-16 h-16 rounded-md flex flex-col items-center justify-center my-2"
    [ngClass]="{
      'bg-primary-100 text-primary': tab.id === currentTab,
      'text-gray-500 hover:text-gray-700 hover:bg-slate-100':
        tab.id !== currentTab,
    }"
  >
    <confect-icon [icon]="tab.icon" iconClass="text-2xl" />
    <div class="text-[0.65rem]/[0.65rem] text-wrap text-center">
      {{ tab.name }}
    </div>
  </div>
</ng-template>
<confect-context-menu #more [options]="contextOptions" />
