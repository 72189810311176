import { Injectable } from "@angular/core";

import { SearchFilterCategory } from "@core/api-models/analytics.models";
import { displayFilterNames } from "@core/api/analytics.service";

@Injectable({
  providedIn: "root",
})
export class MediaFilterService {
  filter = [];
  sort_by = "end_date";
  asce = false;
  categories: SearchFilterCategory[];
  keys;
  sort_keys = [
    { display: "Newest First", key: "start_date" },
    { display: "Best First", key: "score" },
    { display: "Most Impressions", key: "impressions" },
  ];

  constructor() {}

  clear() {
    this.filter = [];
    this.sort_by = "start_date";
    this.asce = true;
    this.categories = null;
    this.keys = null;
  }

  setCategories(categories: SearchFilterCategory[]): void {
    this.categories = categories;
    this.categories.forEach((element) => {
      const keys = [];
      element.display = displayFilterNames[element.value]?.display;
      element.keys.forEach((value) => {
        keys.push({
          key: value.key,
          display: value.display,
        });
      });
    });

    const keys = [];
    this.categories.forEach((category) => {
      category.keys.forEach((value) => {
        keys.push({
          key: { category: category.value, value: value.key },
          category: category.display,
          display: value.display,
          disabled: false,
        });
      });
    });
    this.keys = keys;
  }
  update() {
    this.filter = JSON.parse(JSON.stringify(this.filter));
  }

  add(item) {
    this.filter.push(item);
    this.update();
  }

  remove(item) {
    this.filter.splice(this.filter.indexOf(item), 1);
    this.update();
  }

  copy() {
    return JSON.parse(JSON.stringify(this.filter));
  }
}
