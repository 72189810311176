import { Injectable } from "@angular/core";

import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CScrollService {
  scroll = new Subject<any>();
  $scroll = this.scroll.asObservable();

  constructor() {}
}
