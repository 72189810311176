import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";

export interface contextMenuOption {
  display: string;
  function: any;
  icon: string;
  hide: any;
  disable?: any;
  disableMsg?: string;
}

@Component({
  selector: "confect-context-menu",
  templateUrl: "./context-menu.component.html",
  styleUrl: "./context-menu.component.scss",
})
export class CContextMenuComponent {
  stayOpen = false;

  _options: contextMenuOption[];
  @Input() set options(to: contextMenuOption[]) {
    this._options = to;
    this._options.map((option) => {
      option.disable =
        option.disable ??
        ((item) => {
          return false;
        });
      return option;
    });
  }
  @Output() afterClose = new EventEmitter<any>();

  get menuOpen() {
    return this.matMenuTrigger?.menuOpen;
  }

  get item() {
    return this.matMenuTrigger?.menuData.item;
  }

  // we create an object that contains coordinates
  menuTopLeftPosition = { x: "0", y: "0" };
  @ViewChild(MatMenuTrigger) public matMenuTrigger: MatMenuTrigger;

  rightClick(event, item) {
    event.preventDefault();

    if (!this.matMenuTrigger.menuOpen) {
      window.addEventListener("click", this.closeMenu, false);
    }

    // we record the mouse position in our object
    const position = { x: event.clientX + "px", y: event.clientY + "px" };

    this.menuTopLeftPosition = position;
    setTimeout(() => {
      this.matMenuTrigger.updatePosition();
    }, 1);

    // we open the menu
    // we pass to the menu the information about our object
    this.matMenuTrigger.menuData = { item: item };

    // we open the menu
    setTimeout(() => {
      !this.matMenuTrigger.menuOpen && this.matMenuTrigger.openMenu();
    }, 1);
  }

  closeMenu = (event: MouseEvent) => {
    if (!this.matMenuTrigger.menuOpen || this.stayOpen) {
      this.stayOpen = false;
      return;
    }
    this.matMenuTrigger.closeMenu();
    window.removeEventListener("click", this.closeMenu, false);
    this.afterClose.emit();
  };
  close(event) {
    this.closeMenu(event);
  }
}
