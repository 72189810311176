import { Component, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "confect-card-acc-search",
  templateUrl: "./card-acc-search.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CCardAccSearchComponent,
    },
  ],
})
export class CCardAccSearchComponent implements ControlValueAccessor {
  @Input() placeholder = "";

  // State
  value = "";
  touched = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  constructor() {}

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
