import {
  Component,
  ElementRef,
  Input,
  OnInit,
  AfterContentInit,
  WritableSignal,
  signal,
  Signal,
  computed,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Point } from "@core/utils/confect-math";

@Component({
  selector: "confect-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CInputComponent,
    },
  ],
})
export class CInputComponent
  implements OnInit, ControlValueAccessor, AfterContentInit
{
  @Input() title = "";

  @Input() type = "text";
  @Input() styling: "standard" | "subtle" = "standard";
  @Input() placeholder = "";
  @Input() name = "";
  // @Input() id = "";
  @Input() icon: string | null = null;
  @Input() compact = false;
  @Input() centered = false;
  @Input() disabled = false;

  @Input() inputIcon;
  @Input() inputText;

  @Input() dragToAdjust: boolean = false;

  @Input() suffix = null;

  @Input() min?: number = null;
  @Input() max?: number = null;

  @Input() decimals: number = 2;

  @Input() readonly = false;
  @Input() selectOnClick = false;

  @Input() accept;

  @Input() autofocus: boolean = false;

  @ViewChild("input") input: ElementRef<any>;

  focused: boolean = false;

  cache: number = 0;

  // State
  value: WritableSignal<any> = signal(null);
  roundedValue: Signal<any> = computed(() => {
    if (
      isNaN(Number(this.value())) ||
      this.value() == null ||
      this.value() === ""
    ) {
      return this.value() ?? null;
    }

    const factor = Math.pow(10, this.decimals);
    return Math.round(this.value() * factor) / factor;
  });

  touched = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  constructor(private elem: ElementRef) {}

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    setTimeout(() => {
      /* Stupid way of looping all input elements below, as Angular does not support references to elements in an ng-template */
      /* https://github.com/angular/angular/issues/14842 */
      if (this.autofocus) {
        for (const inp of Array.from(
          (this.elem.nativeElement as HTMLDivElement).getElementsByTagName(
            "input",
          ),
        )) {
          inp.focus();
        }
      }
    });
  }

  writeValue(value: any) {
    if (value == null) {
      this.value.update(() => null);
    }
    this.value.update(() => value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  clicked(event) {
    if (this.selectOnClick) {
      event.target.select();
    }
  }
  parseInput(event: any) {
    if (this.type === "file") {
      return event.target.files[0];
    }
    return event.target.value;
  }

  dragStart = (pos: Point) => {
    this.cache = this.roundedValue();
  };

  dragMove = (pos: Point) => {
    this.value.update(() => this.cache + pos.x);
    this.onChange(this.value());
  };
}
