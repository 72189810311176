import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
  WritableSignal,
  computed,
  signal,
} from "@angular/core";

@Component({
  selector: "confect-tooltip",
  templateUrl: "./tooltip.component.html",
})
export class CTooltipComponent implements AfterViewInit {
  @Input() template: TemplateRef<any>;
  @Input() tooltipText: string;

  _parentPosition: WritableSignal<any> = signal(null);
  @Input({ required: true }) set parentPosition(to: any) {
    if (!to) {
      return;
    }
    this._parentPosition.update(() => to);
  }

  _position: WritableSignal<"above" | "below" | "left" | "right" | null> =
    signal(null);
  @Input({ required: true }) set position(
    to: "above" | "below" | "left" | "right",
  ) {
    if (!to) {
      return;
    }
    this._position.update(() => to);
  }

  tooltipBox: WritableSignal<any | null> = signal(null);

  @ViewChild("tooltip") tooltipElement: ElementRef<any>;

  top: Signal<number | null> = computed(() => {
    if (!this.tooltipBox()) {
      return null;
    }

    let offsetBottom = 0;
    let offsetTop = 0;

    const parentCenter =
      (this._parentPosition().bottom + this._parentPosition().top) / 2;
    const tooltipCenter =
      (this.tooltipBox().bottom - this.tooltipBox().top) / 2;

    switch (this._position()) {
      case "above":
        return this._parentPosition().top - this.tooltipBox().height;
      case "below":
        return this._parentPosition().bottom;
      default:
        offsetBottom = Math.min(
          window.innerHeight -
            (parentCenter - tooltipCenter + this.tooltipBox().height) -
            10,
          0,
        );
        offsetTop = Math.max(0, tooltipCenter - parentCenter + 10);

        return parentCenter - tooltipCenter + offsetBottom + offsetTop;
    }
  });

  left: Signal<number | null> = computed(() => {
    if (!this.tooltipBox()) {
      return null;
    }

    let offsetRight = 0;
    let offsetLeft = 0;

    const parentCenter =
      (this._parentPosition().left + this._parentPosition().right) / 2;
    const tooltipCenter =
      (this.tooltipBox().right - this.tooltipBox().left) / 2;

    switch (this._position()) {
      case "left":
        return this._parentPosition().left - this.tooltipBox().width;
      case "right":
        return this._parentPosition().right;
      default:
        offsetRight = Math.min(
          window.innerWidth -
            (parentCenter - tooltipCenter + this.tooltipBox().width) -
            10,
          0,
        );
        offsetLeft = Math.max(0, tooltipCenter - parentCenter + 10);

        return parentCenter - tooltipCenter + offsetRight + offsetLeft;
    }
  });

  show: boolean = false;

  @Output() afterClose = new EventEmitter<any>();
  @Output() hovered = new EventEmitter<any>();

  ngAfterViewInit(): void {
    this.tooltipBox.update(() =>
      this.tooltipElement?.nativeElement.getBoundingClientRect(),
    );
    this.show = true;
  }

  hover(bool: boolean) {
    this.hovered.emit(bool);
  }

  switch = () => {
    this.show = !this.show;
  };

  close = () => {
    this.afterClose.emit();
  };
}
