import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "confect-toggle",
  templateUrl: "./toggle.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CToggleComponent,
    },
  ],
})
export class CToggleComponent implements OnInit {
  @Input() title = "";

  @Input() name = "";
  @Input() id = "";
  @Input() icon: string | null = null;
  @Input() disable: boolean = false;

  @Input() value: boolean;

  // Legacy value change
  @Output() valueChange = new EventEmitter<boolean>();

  touched = false;

  initialized = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.initialized = true;
    });
  }

  writeValue(value: boolean) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }
}
