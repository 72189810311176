interface CMathInterface {
  constrain: (value: number, min: number, max: number) => number;
  argMax: (array: any) => number;
  argMin: (array: any) => number;
  euclideanDist: (a: Point, b: Point) => number;
  dist: (a: number, b: number) => number;
  subtract: (a: Point, b: Point) => Point;
  add: (a: Point, b: Point) => Point;
  clip: (a: number, min: number, max: number) => number;
  intersection: (a: Interval, b: Interval) => Interval;
  isSubsetOf: (sub: Interval, sup: Interval) => boolean;
  mod: (a: number, n: number) => number;
  getDivisors: (integer: number) => number[];
  aspectRatio: (a: number, b: number, denominator?: number) => Ratio;
  sort: (arr: number[], asc?: boolean) => number[];
  rng: (seed: null | number) => void;
  randInt: () => number;
  randFloat: () => number;
  randRange: (start: number, end: number) => number;
  randChoice: (array: any[]) => any;
  round: (num: number, deciamls: number) => number;
  angle: (point1: Point, point2: Point, center: Point) => number;
}

let rng = new RNG(null);

function RNG(seed) {
  // LCG using GCC's constants
  this.m = 0x80000000; // 2**31;
  this.a = 1103515245;
  this.c = 12345;

  this.state = seed ? seed : Math.floor(Math.random() * (this.m - 1));
}
RNG.prototype.nextInt = function () {
  this.state = (this.a * this.state + this.c) % this.m;
  return this.state;
};
RNG.prototype.nextFloat = function () {
  // returns in range [0,1]
  return this.nextInt() / (this.m - 1);
};
RNG.prototype.nextRange = function (start, end) {
  // returns in range [start, end): including start, excluding end
  // can't modulu nextInt because of weak randomness in lower bits
  const rangeSize = end - start;
  const randomUnder1 = this.nextInt() / this.m;
  return start + Math.floor(randomUnder1 * rangeSize);
};
RNG.prototype.choice = function (array) {
  return array[this.nextRange(0, array.length)];
};

const argFact = (compareFn) => (array) =>
  array.map((el, idx) => [el, idx]).reduce(compareFn)[1];

export const CMath: CMathInterface = {
  constrain: (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
  },
  argMax: argFact((min, el) => (el[0] > min[0] ? el : min)),
  argMin: argFact((max, el) => (el[0] < max[0] ? el : max)),
  clip: (a: number, min: number, max: number) => {
    return Math.max(Math.min(a, max), min);
  },
  euclideanDist: (a: Point, b: Point) => {
    const c = CMath.subtract(a, b);
    return Math.sqrt(Math.pow(c.x, 2) + Math.pow(c.y, 2));
  },
  subtract: (a: Point, b: Point) => {
    const c: Point = { x: a.x - b.x, y: a.y - b.y };
    return c;
  },
  add: (a: Point, b: Point) => {
    const c = { x: a.x + b.x, y: a.y + b.y };
    return c;
  },
  dist: (a: number, b: number) => {
    return Math.abs(a - b);
  },
  mod: (a: number, n: number) => ((a % n) + n) % n,
  intersection: (a: Interval, b: Interval) => {
    const min = a.start < b.start ? a : b;
    const max = min == a ? b : a;

    if (min.end < max.start) {
      return null;
    }

    const intersection: Interval = {
      start: max.start,
      end: min.end < max.end ? min.end : max.end,
    };

    return intersection;
  },
  isSubsetOf: (sub: Interval, sup: Interval) => {
    if (sub.start >= sup.start && sub.end <= sup.end) {
      return true;
    }
    return false;
  },
  getDivisors: (integer: number) => {
    const factors = [];

    for (let i = 2; i <= integer; i++) {
      if (integer % i === 0) {
        factors.push(i);
      }
    }

    return factors;
  },
  aspectRatio: (a: number, b: number, denominator?: number) => {
    const bprimes = CMath.getDivisors(b);
    const aprimes = CMath.getDivisors(a);
    const cd = aprimes.filter((aprime) => bprimes.includes(aprime));
    const deno = (denominator ?? cd.length === 0) ? 1 : Math.max(...cd);

    return {
      a: a / deno,
      b: b / deno,
      str: `${a / deno}:${b / deno}`,
      ratio: a / b,
    };
  },
  sort: (arr: number[], asc: boolean = true) => {
    const copy: number[] = JSON.parse(JSON.stringify(arr));
    return copy.sort((a, b) => (asc ? a - b : b - a));
  },
  rng: (seed: null | number) => {
    rng = new RNG(seed);
  },
  randInt: () => rng.nextInt(),
  randFloat: () => rng.nextFloat(),
  randRange: (start: number, end: number) => rng.nextRange(start, end),
  randChoice: (array: any[]) => rng.choice(array),
  round: (num: number, decimals: number) => {
    const factor = Math.pow(10, decimals);
    return Math.round(num * factor) / factor;
  },
  angle: (point1: Point, point2: Point, center: Point) => {
    const result =
      Math.atan2(point2.y - center.y, point2.x - center.x) -
      Math.atan2(point1.y - center.y, point1.x - center.x);
    return (result / Math.PI) * 180;
  },
};

export interface Interval {
  start: number;
  end: number;
}

export interface Point {
  x: number;
  y: number;
}

export interface Ratio {
  a: number;
  b: number;
  str: string;
  ratio: number;
}
