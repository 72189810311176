<div
  class="max-w-screen-lg p-2 flex flex-col items-center justify-center bg-white rounded-md"
  style="width: 500px"
>
  @if (invalidInput) {
    <div class="mb-5">
      <div
        class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      >
        <strong class="font-bold">Invalid Input! </strong>
        <span class="block sm:inline">{{ modalInvalidText }}</span>
      </div>
    </div>
  }

  <h2 class="block text-center mb-2">
    @if (modalTitle) {
      {{ modalTitle }}
    }
  </h2>
  @if (showModalText) {
    <div class="text-center text-sm text-gray-500 w-8/12">
      @if (modalText) {
        <span>{{ modalText }}</span>
      }
    </div>
  }
  <div class="mt-5">
    <confect-input
      [(ngModel)]="modalInputValue"
      [type]="modalInputType"
      [placeholder]="modaPlaceholder"
      (keydown.enter)="confirmModal()"
      (input)="validateChange($event)"
      [accept]="accept"
      [autofocus]="true"
    />
  </div>
  <div class="w-full flex items-center justify-center space-x-5 mt-8">
    <confect-button type="secondary" (click)="closeModal()">
      {{ cancelText }}
    </confect-button>
    <confect-button type="primary" (click)="confirmModal()">
      {{ confirmText }}
    </confect-button>
  </div>
</div>
