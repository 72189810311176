import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";

import { Me } from "@core/api-models/users.models";
import { AgencyService } from "@core/api/agency.service";
import { UsersService } from "@core/api/users.service";
import { AccessService } from "@core/services/access.service";

import { CModalComponent } from "@theme/@confect/components/modal/modal.component";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

@Component({
  selector: "ngx-account-request-modal",
  templateUrl: "./account-request-modal.component.html",
})
export class AccountRequestModalComponent implements OnInit {
  @ViewChild("modal", { static: true }) modal: CModalComponent;

  @Input() title = "Create new Confect Account for a client";
  @Input() subTitle = "All new accounts will get a free trial period";

  @Input() fbClientUUID: string | null = null;

  @Output() afterClose = new EventEmitter<any>();

  isCreating = false;
  me: Me;

  constructor(
    private us: UsersService,
    private ms: CPopupModalService,
    private a: AgencyService,
    private access: AccessService,
  ) {}

  ngOnInit(): void {
    this.us.getMe().subscribe((res) => {
      this.me = res;
    });
  }

  requestAccount(form: NgForm) {
    if (!form.valid) {
      return;
    }

    const companyName = form.form.controls.name.value;
    const companyDomain = form.form.controls.domain.value;
    const obs = this.fbClientUUID
      ? this.a.agencyCreateFBClientCompanyTrial(
          this.fbClientUUID,
          companyName,
          companyDomain,
        )
      : this.a.agencyCreateCompanyTrial(companyName, companyDomain);

    this.isCreating = true;
    obs.subscribe({
      next: (res) => {
        this.ms.success({
          title: "New client has been created!",
          text: "You will now be taken to onboarding.",
          autoCloseTimeout: 2000,
        });
        this.isCreating = false;
        this.modal.hide();

        setTimeout(() => {
          this.us.getUserAccess().subscribe((access) => {
            this.access.updateAvailable(access);
            this.access.switchAccessByIds(res.company, res.account);
          });
        }, 2100);
      },
      error: (_err) => {
        const prefix = this.fbClientUUID ? `${_err.error["detail"]} ` : "";
        this.ms.error({
          title: "Failed creating new client!",
          text: `${prefix}You can contact our support in the live chat for help.`,
          autoCloseTimeout: null,
        });
        this.isCreating = false;
        this.modal.hide();
      },
    });
  }

  show() {
    if (!this.me) {
      return;
    }
    if (!this.me.agency) {
      return;
    }

    this.modal.show();
  }
}
