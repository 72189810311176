<div class="h-screen flex overflow-hidden bg-gray-100">
  <div class="flex flex-shrink-0">
    <ng-content select="[menu]" />
  </div>
  <div class="flex flex-col w-0 flex-1 overflow-hidden">
    <main
      id="main"
      class="flex-1 relative overflow-y-auto focus:outline-none"
      tabindex="0"
      (scroll)="cScroll.scroll.next($event)"
    >
      <confect-page-wrapper>
        <ng-content />
      </confect-page-wrapper>
    </main>
  </div>
</div>
