import { Pipe, PipeTransform } from "@angular/core";

import moment from "moment";

@Pipe({ name: "ngxFilterFormat", pure: true })
export class FilterFormatPipe implements PipeTransform {
  transform(item, format, _filter): string {
    const filterInfo = _filter[item.column][0];
    let operator = filterInfo.operator.split("_").join(" ") + " ";
    let value = filterInfo.value ?? "...";
    let extra = "";
    const delimeter =
      Array.isArray(value) && Number.isInteger(Number(value[0]))
        ? " and "
        : ", ";
    const out = String(Array.isArray(value) ? value.join(delimeter) : value);

    if (item.column === "Date") {
      const start = moment(new Date(value[0])).format("DD-MM-YYYY");
      const end = moment(new Date(value[1])).format("DD-MM-YYYY");
      extra = "from ";
      value =
        operator === "equals " ? format["Date"][value] : `${start} to ${end}`;
      operator = "";
    } else if (item.column === "Campaign_Objective") {
      value = format["Campaign_Objective"][value];
    } else {
      value = out.length > 25 ? out.slice(0, 22) + "..." : out;
    }

    return `${extra}${operator}${value}`;
  }
}
