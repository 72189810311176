import { EventEmitter, Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";

export interface ContextRef {
  close: (event) => void;
  options: any;
  afterClose: EventEmitter<void>;
}

@Injectable({ providedIn: "root" })
export class ContextMenuService {
  /**
   * All the necessary data about the modal which should be opened is being
   * sent to the outlet-modal component through this subject.
   */
  private contextSubject: Subject<any> = new Subject<any>();

  public get contextObservable(): Observable<any> {
    return this.contextSubject.asObservable();
  }

  open<C>(opts) {
    const contextRef: ContextRef = {
      options: opts,
    } as ContextRef;

    this.contextSubject.next({
      ...opts,
      contextRef,
    });

    return contextRef;
  }
}
