import { Component, Input, OnInit } from "@angular/core";

import { Feed } from "@core/api-models/feeds.models";

@Component({
  selector: "ngx-feed-settings-error",
  templateUrl: "./feed-settings-error.component.html",
})
export class FeedSettingsErrorComponent implements OnInit {
  error: { key: string; description: string; errorData: any } = null;

  feedInfo: {
    errorKey: string;
    feed: Feed;
  };

  @Input() set feed(to: { errorKey: string; feed: Feed }) {
    this.feedInfo = to;

    const k = this.feedInfo.errorKey;
    this.error = {
      key: k,
      description: this.ERROR_DESCRIPTIONS[k],
      errorData: this.feedInfo.feed.error_info[k],
    };
  }

  ERROR_DESCRIPTIONS = {
    FormatError: "Feed is in an invalid format",
    DownloadError: "Feed could not be downloaded.",
    EmptyError: "The feed is completely empty",
    InvalidProductsError: "The feed contains invalid products.",
    OtherError: "A known error occurred.",
    NoProductsOnSaleWarning: "No products in the feed are on sale.",
  };

  constructor() {}

  ngOnInit(): void {}
}
