<ng-template #item>
  <div
    uploadImage
    (fileDropped)="writeValue($event)"
    class="flex transition-colors duration-500 group items-center justify-center w-[500px] h-[300px] bg-gray-100 rounded-lg border-2 border-gray-400 border-dashed"
  >
    @if (value != null && !isUploading) {
      <div class="flex items-center justify-center">
        <confect-icon
          icon="attachment_diagonal"
          iconClass="text-2xl"
          class="text-lg"
          >{{ value?.name }}</confect-icon
        >
        <confect-icon-button
          (click)="reset()"
          icon="clear"
          iconClass="text-2xl"
        />
      </div>
    }
    @if (value == null && !isUploading) {
      <div
        class="flex items-center justify-center flex-col drop-file pointer-events-none"
      >
        <confect-icon icon="upload_on_cloud" class="text-[50px]" />
        <span class="text-lg mb-5 upload-text">{{ text }}</span>
        <span class="text-lg mb-5 drop-file-text hidden"
          >Release file to select!</span
        >
        <label for="file">
          <div class="upload-button pointer-events-auto">
            <confect-button type="white" (click)="uploader.click()"
              ><confect-icon
                icon="publish"
                iconClass="text-2xl"
                class="text-lg"
                >{{ buttonText }}</confect-icon
              ></confect-button
            >
          </div>
        </label>
      </div>
    }
    @if (isUploading) {
      <div class="flex flex-col items-center justify-center">
        Uploading files
        <div
          class="flex h-6 w-6 items-center justify-center space-x-2 animate-spin"
        >
          <div
            class="relative w-full h-full rounded-full overflow-hidden bg-primary-300"
          >
            <div
              class="absolute rotate-45 translate-y-[17px] w-full h-full bg-primary"
            ></div>
            <div
              class="absolute translate-x-1 translate-y-1 w-4 h-4 bg-gray-100 rounded-full"
            ></div>
          </div>
        </div>
      </div>
    }
  </div>
  <input
    type="file"
    [accept]="accept"
    (change)="writeValue($any($event).target.files)"
    [id]="name"
    [multiple]="multiple"
    [name]="name"
    #uploader
    hidden
  />
</ng-template>

<ng-container *ngTemplateOutlet="item" />
