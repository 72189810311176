import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

export interface CDropdownItem {
  action?: string;
  title: string;
  icon?: string;
}

// Document coords with support for scrolling (which should not happen, but eh)
function getCoords(elem: any) {
  const box = elem.getBoundingClientRect();

  return {
    top: box.top, // + window.pageYOffset,
    right: box.right - window.pageXOffset,
    bottom: box.bottom, // + window.pageYOffset,
    left: box.left + window.pageXOffset,
  };
}

@Component({
  selector: "confect-dropdown",
  templateUrl: "./dropdown.component.html",
})
export class CDropdownComponent implements OnInit, OnDestroy {
  @Input() items: CDropdownItem[][] = [];
  @Output() dropdownItemSelected = new EventEmitter<CDropdownItem>();

  @Input() anchor: HTMLElement | undefined | null = null;
  @ViewChild("menu", { static: true }) menu!: ElementRef;

  _listen = true;

  @Input() set listen(to: boolean) {
    this._listen = to;
  }

  _right = 0;
  _top = 0;

  constructor() {}

  ngOnInit(): void {
    this.calculatePosition();
    window.addEventListener("scroll", this.onScroll, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    this.calculatePosition();
  };

  getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollTop > 0) {
      return node;
    } else {
      return this.getScrollParent(node.parentNode);
    }
  }

  calculatePosition() {
    if (!this.anchor) {
      return;
    }

    const anchorBounds = getCoords(this.anchor);

    const anchorRight = anchorBounds.right ?? 0;
    const anchorBottom = anchorBounds.bottom ?? 0;
    this._right = this.anchor.ownerDocument.body.clientWidth - anchorRight;

    let newTop = anchorBottom;

    // If new top makes the dropdown leave the screen, we should make sure it stays inside
    if (this.items) {
      const h = 44 * this.items.reduce((prev, l) => prev + l.length, 0) + 8;
      const overlap = newTop + h - this.anchor.ownerDocument.body.clientHeight;
      if (overlap > 0) {
        newTop -= overlap + 12;
      }
    }

    this._top = newTop; // st;
  }

  itemSelected(item: CDropdownItem) {
    this.dropdownItemSelected.emit(item);
  }

  @HostListener("window:resize")
  onResize() {
    this.calculatePosition();
  }
}
