<div class="flex flex-col space-y-1">
  @for (category of _structure; track category) {
    <button
      [ngClass]="{
        'pointer-events-none': !collapsable && category.options.length > 0,
      }"
      (click)="categorySelect(category)"
      class="py-2 pl-2 pr-1 w-full flex justify-between items-center text-sm font-medium rounded-md cursor-pointer text-gray-600 no-underline focus:outline-none focus:ring-2 focus:ring-primary"
      [ngClass]="{
        'bg-white hover:text-gray-900 hover:bg-gray-50':
          currentOption?.category !== category.name &&
          currentOption?.option === '',
        'bg-slate-100':
          currentOption?.category === category.name &&
          currentOption?.option === '',
      }"
    >
      <confect-icon
        iconClass="text-gray-500 mr-3 text-2xl"
        [icon]="category.icon"
        ><span [ngClass]="{ 'py-1.5': category.icon == null }">{{
          category.name
        }}</span></confect-icon
      >
      @if (collapsable && category.options?.length > 0) {
        <confect-icon
          [icon]="category.collapsed ? 'caret_right' : 'caret_down'"
          iconClass="text-gray-500 text-2xl"
        />
      }
    </button>
    @if (
      (category?.options?.length > 0 && !category.collapsed) || !collapsable
    ) {
      @for (option of category.options; track option) {
        <button
          (click)="optionSelect(category, option)"
          class="pl-8 pr-2 py-2 w-full flex items-center text-sm font-normal rounded-md cursor-pointer text-gray-600 no-underline focus:outline-none focus:ring-2 focus:ring-primary"
          [ngClass]="{
            'bg-white  hover:text-gray-900 hover:bg-gray-50':
              currentOption?.category !== category.name ||
              currentOption?.option !== option,
            'bg-slate-100':
              currentOption?.category === category.name &&
              currentOption?.option === option,
          }"
        >
          {{ option }}
        </button>
      }
    }
  }
</div>
