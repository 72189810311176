<div
  class="flex items-center justify-center space-y-8 pb-12"
  [ngClass]="{ 'flex-col': size === 'default' }"
>
  @if (info?.img) {
    <img class="max-h-[35vh]" [src]="info.img" />
  }

  @if (info.animation) {
    <div
      [id]="info.animationID ?? 'animation'"
      class="w-[80%] h-[50vh] 3xl:h-[35vh]"
    ></div>
  }
  <div class="flex flex-col">
    <div class="text-center">
      <h3 class="mb-1">{{ info?.title }}</h3>
      <p class="text-gray-500 max-w-md mx-auto">
        {{ info?.description }}
      </p>
    </div>
    <div class="flex items-center justify-center mt-5 space-x-4">
      @if (info?.buttonText != null) {
        <confect-button
          type="primary"
          size="xlarge"
          [routerLink]="info?.route"
          (click)="action.emit(true)"
        >
          {{ info?.buttonText }}
        </confect-button>
      }

      @if (info?.secondaryButtonText != null) {
        <confect-button
          type="secondary"
          size="xlarge"
          [routerLink]="info?.secondaryRoute"
          (click)="secondaryAction.emit(true)"
        >
          {{ info?.secondaryButtonText }}
        </confect-button>
      }
    </div>
  </div>
</div>
