import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

@Component({
  selector: "confect-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class CModalComponent implements OnInit {
  @Input() wider = false;
  @Input() noPadding = false;
  @Input() width: string;
  @Input() primaryButtonText: string;
  @Input() secondaryButtonText: string;

  @Input() disableScroll = false;

  hidden = true;
  shown = false;

  @Output() afterClose = new EventEmitter<void>();
  @Output() escaped = new EventEmitter<void>();

  /**
   * The target point in the template where the content of the modal can be inserted from outside.
   * Being used by the modal-outlet component to insert the content into an already appended instance.
   */
  @ViewChild("contentTarget", { read: ViewContainerRef, static: true })
  public contentContainerRef: ViewContainerRef;

  ngOnInit(): void {}

  show() {
    this.hidden = false;

    // Make sure slideover is translated away before expanding,
    // otherwise animation will not work.
    setTimeout(() => {
      this.shown = true;
    }, 1);
  }

  hide() {
    this.shown = false;
    setTimeout(() => {
      // Hide after the animation
      this.hidden = true;
      this.afterClose.emit();
    }, 200);
  }

  interupt() {
    if (this.shown) {
      this.hide();
      this.escaped.emit();
    }
  }

  @HostListener("window:keydown.esc", ["$event"])
  escape(_e) {
    if (this.shown) {
      this.hide();
      this.escaped.emit();
    }
  }
}
