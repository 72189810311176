<div class="w-full h-full p-3">
  <div
    class="flex flex-col bg-white rounded-md w-full h-full px-3 py-2 gap-y-2 border-gray-100 border-2"
  >
    <div class="flex justify-between items-center">
      <div class="flex gap-4 items-center">
        <span class="text-gray-700 font-semibold">{{ cardTitle }}</span>
        @if (subtitle) {
          <span class="text-gray-400 text-xs">{{ subtitle }}</span>
        }
      </div>
      @if (buttonText) {
        <div>
          <confect-button
            type="white"
            size="xsmall"
            (click)="buttonClick.emit()"
            >{{ buttonText }}</confect-button
          >
        </div>
      }
    </div>
    <div class="mt-5">
      <ng-content />
    </div>
  </div>
</div>
