export class Paginator<T> {
  data: T[];

  searchKey: string;
  searchString: string;

  PER_PAGE = 20;
  CURRENT_PAGE = 1;

  showPager = false;

  filteredData: T[] = [];
  paginatedData: T[] = [];

  hasMorePages = false;
  hasPrevPage = false;
  pageTotal = 1;

  display = {
    from: 0,
    to: 0,
    total: 0,
  };

  constructor(
    data: T[],
    searchKey: string,
    initialSearch: string = "",
    perPage: number = 20,
  ) {
    this.PER_PAGE = perPage;

    this.data = data;
    this.searchKey = searchKey;
    this.searchString = initialSearch;
    this.refresh();
  }

  changeQuery(s: string) {
    this.searchString = s;
    this.CURRENT_PAGE = 1;
    this.refresh();
  }

  updateData(to: T[]) {
    this.data = to;
    this.refresh();
  }

  // Search filter
  calculateFilteredData(): T[] {
    const d = this.data;

    if (d == null) {
      return [];
    } else if (!this.searchString) {
      return d;
    } else {
      return d.filter((di) =>
        di[this.searchKey]
          .toLowerCase()
          .includes(this.searchString.toLowerCase()),
      );
    }
  }

  // Pagination slicer
  calculatePaginatedData(): T[] {
    // Pagination off
    if (!this.PER_PAGE) {
      return this.filteredData;
    }

    const skip = (this.CURRENT_PAGE - 1) * this.PER_PAGE;
    const upper = this.CURRENT_PAGE * this.PER_PAGE;
    return this.filteredData.slice(skip, upper);
  }

  // Should show pager
  calculateShowPager(): boolean {
    if (!this.PER_PAGE) {
      return false;
    }
    return this.filteredData.length > this.PER_PAGE;
  }

  // Has more pages (can press next)
  calculateHasMorePages(): boolean {
    if (!this.PER_PAGE) {
      return false;
    }
    return this.CURRENT_PAGE * this.PER_PAGE < this.filteredData.length;
  }

  // Has prev page (can go back)
  calculateHasPrevPage(): boolean {
    return this.CURRENT_PAGE > 1;
  }

  calculatePageTotal(): number {
    if (!this.PER_PAGE) {
      return 1;
    }
    return Math.ceil(this.filteredData.length / this.PER_PAGE);
  }

  // Go back one page
  prevPage() {
    if (!this.hasPrevPage) {
      return;
    }
    this.CURRENT_PAGE -= 1;
    this.refresh();
  }

  // Go forward one page
  nextPage() {
    // If no more pages, do nothing
    if (!this.hasMorePages) {
      return;
    }
    this.CURRENT_PAGE += 1;
    this.refresh();
  }

  // Refresh pages
  refresh() {
    this.filteredData = this.calculateFilteredData();
    this.paginatedData = this.calculatePaginatedData();
    this.showPager = this.calculateShowPager();
    this.hasMorePages = this.calculateHasMorePages();
    this.hasPrevPage = this.calculateHasPrevPage();
    this.pageTotal = this.calculatePageTotal();

    this.display = {
      from: Math.min(
        (this.CURRENT_PAGE - 1) * this.PER_PAGE + 1,
        this.filteredData.length,
      ),
      to: Math.min(this.CURRENT_PAGE * this.PER_PAGE, this.filteredData.length),
      total: this.filteredData.length,
    };
  }
}
