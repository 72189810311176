import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { DragDirective, DragHandleDirective } from "./drag/drag.directive";

const DIRECTIVES = [DragDirective, DragHandleDirective];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule, RouterModule],
  exports: [...DIRECTIVES],
})
export class DragModule {}
