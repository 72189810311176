<div class="flex-shrink-0">
  <div
    class="px-1 py-3 font-bold text-sm cursor-pointer"
    (click)="expanded = !expanded"
  >
    <div class="flex items-center justify-between">
      <ng-content select="[header]" />
      <i
        class="tio tio-down_ui transform transition-all duration-300 text-gray-500"
        [class.-rotate-180]="expanded"
      ></i>
    </div>
  </div>
  <div
    class="overflow-hidden transition-all duration-300 h-auto ease-in-out"
    [class.max-h-0]="!expanded"
    [class.max-h-screen]="expanded"
  >
    <div class="px-1 py-2">
      <ng-content select="[content]" />
    </div>
  </div>
</div>
