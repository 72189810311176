import { MediaService } from "@core/api/media.service";
import { MediaLibraryItem } from "@core/models/media-library.types";

export class MediaLibrarySourceService {
  shouldLoadNextPage = true;
  refresh = true;
  isLoading = false;
  per_page = 30;
  page = 1;

  scope = null;
  key = null;
  searchString = "";

  media = [];

  constructor(private mediaService: MediaService) {}

  configure(sourceKey: string, scope: string) {
    this.key = sourceKey;
    this.scope = scope;

    this.refreshMedias();
  }

  getDefaultVariant(media: MediaLibraryItem) {
    const variants = media.variants;
    if ("default" in variants) {
      if (variants.default.url_thumb === null && media.type !== "pattern") {
        variants.default.url_thumb =
          "https://media.confect.io/static/thumbnail_default.png";
        this.probeThumb(media.id, 10);
      }
      return variants.default;
    }

    const k = Object.keys(variants)[0];
    return variants[k];
  }

  hasDefaultThumb(media) {
    const v = this.getDefaultVariant(media);
    return (
      v.url_thumb !== null && v.url_thumb !== "https://media.confect.io/None"
    );
  }

  prepareData(data: MediaLibraryItem[]) {
    return data.map((d) => {
      d.image = this.getDefaultVariant(d).url_thumb;
      return d;
    });
  }

  loadNextPage() {
    this.isLoading = true;

    this.mediaService
      .getMedia(
        this.key,
        this.page,
        this.per_page,
        this.scope,
        this.searchString,
      )
      .subscribe((res) => {
        if (res) {
          if (this.refresh) {
            this.media = [];
            this.refresh = false;
          }
          this.media.push(...this.prepareData(res));
          this.page++;
        } else if (res.length === 0) {
          this.shouldLoadNextPage = false;
        }
        this.isLoading = false;
      });
  }

  refreshMedias() {
    this.refresh = true;
    this.page = 1;
    this.shouldLoadNextPage = true;
    this.loadNextPage();
  }

  probeThumb(mediaID, maxTries) {
    if (maxTries === 0) return;

    this.mediaService.mediaThumbRendered(mediaID, this.key).subscribe((res) => {
      const thumbRendered = res.thumb_rendered;
      if (thumbRendered) {
        // Thumb is ready
        const i = this.media.findIndex((m) => m.id === mediaID);
        const tempMedia = JSON.parse(JSON.stringify(this.media[i]));

        // Remove current media
        this.media.splice(i, 1);
        // Modify media and readd
        tempMedia.variants.default.url_thumb = res.url_thumb;
        tempMedia.image = res.url_thumb;
        this.media.splice(i, 0, tempMedia);
      } else {
        // Thumb not ready, try again in 2s
        setTimeout(() => {
          this.probeThumb(mediaID, maxTries - 1);
        }, 2000);
      }
    });
  }
}
