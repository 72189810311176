<div
  class="fullscreen"
  [style.background-color]="backgroundColor"
  [ngClass]="{
    'overflow-y-scroll': allowOverflow,
    'overflow-hidden': !allowOverflow,
  }"
>
  <ng-content />
</div>
