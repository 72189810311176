import { Component, Input } from "@angular/core";

@Component({
  selector: "ngx-balloon-menu",
  templateUrl: "./balloon-menu.component.html",
})
export class BalloonMenuComponent {
  _parent?: HTMLDivElement;
  @Input() set parent(to: HTMLDivElement) {
    this._parent = to;

    if (to == null) {
      return;
    }

    this.setPosition();
  }

  _show: boolean = false;
  @Input() set show(to: boolean) {
    setTimeout(() => {
      this._show = to;
    });
  }

  _space: number = 10;
  @Input() set space(to: number) {
    this._space = to;
    this.setPosition();
  }

  left: number = 0;
  top: number = 0;

  rect: DOMRect;

  setPosition() {
    if (this._parent == null) {
      return;
    }
    this.rect = this._parent.getBoundingClientRect();
    this.left = (this.rect.left + this.rect.right) / 2;
    this.top = this.rect.y + this._space;
  }
}
