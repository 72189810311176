import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

import Lottie from "lottie-web";

export interface EmptyStateInfo {
  img?: string;
  animation?: string;
  animationID?: string;
  title: string;
  description: string;
  buttonText?: string;
  secondaryButtonText?: string;

  route?: string;
  secondaryRoute?: string;
}

@Component({
  selector: "ngx-empty-state",
  templateUrl: "./empty-state.component.html",
})
export class EmptyStateComponent implements OnChanges {
  @Input() info: EmptyStateInfo;
  @Input() size: "default" | "small" = "default";

  // @Input() img: string | null = null;

  // @Input() title = "";
  // @Input() description = "";
  // @Input() buttonText = "";

  loadedAnimation?: string = null;

  // @Input() route?: string;
  @Output() action = new EventEmitter<boolean>();
  @Output() secondaryAction = new EventEmitter<boolean>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.info) {
      if (this.info.animation) {
        setTimeout(() => {
          Lottie.loadAnimation({
            container: document.getElementById(
              this.info.animationID ?? "animation",
            ), // the dom element
            renderer: "svg",
            loop: true,
            autoplay: true,
            rendererSettings: { preserveAspectRatio: "xMidYMid meet" },
            path: `/assets/animations/${this.info.animation}/${this.info.animation}.json`,
          });
        });
      }
    }
  }
}
