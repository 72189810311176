import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

export interface LinkInfo {
  text: string;
  route: string;
}

@Component({
  selector: "confect-modal-warning",
  templateUrl: "./modal-warning.component.html",
})
export class CModalWarningComponent implements OnInit {
  @Input() modalTitle = "";
  @Input() showModalText = false;
  @Input() modalText = "";
  @Input() confirmText = "Confirm";
  @Input() cancelText = "Cancel";
  @Input() type: string = "default";
  @Input() linkInfo: LinkInfo;
  @Output() modalClosed = new EventEmitter();

  start = true;
  end = false;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      this.start = false;
      this.end = true;
    }, 100);
  }

  goTo(url) {
    window.open(url, "_blank");
  }

  setupModal(
    showText: boolean,
    title?: string,
    text?: string,
    confirmText: string = "Confirm",
    cancelText: string = "Cancel",
  ) {
    this.showModalText = showText;
    this.modalTitle = title;
    this.modalText = text;
    this.confirmText = confirmText;
    this.cancelText = cancelText;
  }

  closeModal() {
    this.modalClosed.emit(false);
  }
  confirmModal() {
    this.modalClosed.emit(true);
  }
}
