import { Component, EventEmitter, Input, Output } from "@angular/core";

import { StepInfo } from "../stepper.component";

@Component({
  selector: "confect-step",
  templateUrl: "./step.component.html",
})
export class CStepComponent {
  @Input() currentStep: number;
  @Input() info: StepInfo;
  @Input() valid: boolean = true;
  @Input() invalidText: string = "";

  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
}
