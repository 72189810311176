<confect-modal #modal (afterClose)="afterClose.emit(true)">
  <div
    style="width: 600px; max-width: 600px"
    class="bg-white rounded-md p-8 flex flex-col space-y-8"
  >
    <div class="flex flex-col space-y-2 text-center">
      <h5 class="text-2xl font-bold">
        {{ title }}
      </h5>
      <span class="text-sm text-gray-500">{{ subTitle }}</span>
    </div>
    <form
      confect-form-inline-group
      [full]="true"
      #createForm="ngForm"
      [showButton]="true"
      buttonTitle="Create"
      (ngSubmit)="requestAccount(createForm)"
      [disabled]="!createForm.valid || isCreating"
    >
      <confect-input
        title="Company name"
        placeholder="Company Name"
        name="name"
        ngModel
        required
      />

      <confect-input
        title="Website domain"
        placeholder="myshop.com"
        name="domain"
        ngModel
        minlength="5"
        required
      />
    </form>
  </div>
</confect-modal>

<!-- 

<confect-modal #creationModal>
    <div style="width: 600px; max-width: 600px" class="bg-white rounded-md p-8">
      <p class="text-lg font-bold mb-4">Create new agency</p>
      <form
        confect-form-inline-group
        [full]="true"
        #createForm="ngForm"
        [showButton]="true"
        buttonTitle="Create"
        (ngSubmit)="createAgency(createForm)"
        [disabled]="!createForm.valid || isCreating"
      >
        <confect-input
          title="Agency name"
          placeholder="Agency name"
          name="name"
          ngModel
          required
        ></confect-input>
        <confect-input
          title="Agency domain"
          placeholder="agency.com"
          name="domain"
          ngModel
          pattern="^[a-zA-Z]+(?:-?[a-zA-Z\d])+\.[a-zA-Z]{2,6}(\.[a-zA-Z]{2,6})?$"
          required
        ></confect-input>
  
        <p class="text-lg font-bold text-red-700" *ngIf="hasCreationError">
          Error: Could not create
        </p>
      </form>
    </div>
  </confect-modal> -->
