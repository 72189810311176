<div class="w-full relative">
  <div
    class="absolute top-0 left-0 h-full w-12 bg-gradient-to-r from-[#ffffff] to-transparent flex items-center justify-start"
    [ngClass]="{ hidden: !showLeft() }"
  >
    <confect-icon-button
      icon="chevron_left"
      iconClass="text-3xl text-black"
      size="xsmall"
      (click)="moveLeft()"
    />
  </div>
  <div
    #scrollView
    class="w-full overflow-y-hidden overflow-x-auto flex scroll-smooth"
    [style.scrollbar-width]="'none'"
    (wheel)="scroll()"
  >
    <div class="flex" #scrollContent>
      <ng-content />
    </div>
  </div>

  <div
    class="absolute top-0 right-0 h-full w-12 bg-gradient-to-l from-[#ffffffff] to-transparent flex items-center justify-end"
    [ngClass]="{ hidden: !showRight() }"
  >
    <confect-icon-button
      icon="chevron_right"
      iconClass="text-3xl text-black"
      size="xsmall"
      (click)="moveRight()"
    />
  </div>
</div>
