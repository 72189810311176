import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";

import { LazyLoadImageModule } from "ng-lazyload-image";
import { NgxStripeModule } from "ngx-stripe";

import { environment } from "../../environments/environment";
import { ConfectModule } from "./@confect/confect.module";
import {
  CreativesPreviewerComponent,
  FeedSettingsComponent,
  ProductFilterComponent,
  ProductsSearchComponent,
  ShowFullscreenWrapperComponent,
  StripeCardInputComponent,
} from "./components";
import { AccessWarningComponent } from "./components/access-warning/access-warning.component";
import { AccountRequestModalComponent } from "./components/account-request-modal/account-request-modal.component";
import { AgencyFbEmptyStateComponent } from "./components/agency-fb-empty-state/agency-fb-empty-state.component";
import { BillingSettingsFormComponent } from "./components/billing-settings-form/billing-settings-form.component";
import { CategoriesTreeComponent } from "./components/categories-tree/categories-tree.component";
import { DesignPreviewerComponent } from "./components/design-previewer/design-previewer.component";
import { ItemInfoDirective } from "./components/design-previewer/design-previewer.directive";
import { EmptyStateComponent } from "./components/empty-state/empty-state.component";
import { FeatureBannerComponent } from "./components/feature-banner/feature-banner.component";
import { FeedImageSettingsComponent } from "./components/feed-image-settings/feed-image-settings.component";
import { FeedSettingsErrorComponent } from "./components/feed-settings/feed-settings-error/feed-settings-error.component";
import { FilterSpecDisplayComponent } from "./components/filter-spec-display/filter-spec-display.component";
import { IntegrationsHelperComponent } from "./components/integrations-helper/integrations-helper.component";
import { IntroPaymentComponent } from "./components/intro-payment/intro-payment.component";
import { MediaLibraryComponent } from "./components/media-library/media-library.component";
import { VerticalTabDirective } from "./components/vertical-tabs/vertical-tab.directive";
import { VerticalTabsComponent } from "./components/vertical-tabs/vertical-tabs.component";
import { InfiniteScrollDirective } from "./directives/infinite-scroll.directive";
import { MediaCompareDirective } from "./directives/media-compare/media-compare.directive";
import { MediaCompareComponent } from "./directives/media-compare/media-compare/media-compare.component";
import { MediaFilterComponent } from "./directives/media-filter/media-filter.component";
import {
  CapitalizePipe,
  ConcatPipe,
  ConstrainPipe,
  EvaIconsPipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  ShortNumberPipe,
  SubfieldPipe,
  TimingPipe,
  SlatePipe,
  LogPipe,
  FilterFormatPipe,
  TruncatePipe,
  SplitPipe,
  CRoundPipe,
} from "./pipes";

const BASE_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  LazyLoadImageModule,
  ConfectModule,
];

const EXT_MODULES = [
  MatInputModule,
  MatFormFieldModule,
  /* Removing matMenu from here kills the folders component? */
  MatProgressBarModule,

  MatTooltipModule,
  MatExpansionModule,
];

const COMPONENTS = [
  ProductFilterComponent,
  FeedSettingsComponent,
  StripeCardInputComponent,
  CreativesPreviewerComponent,
  ProductsSearchComponent,
  ShowFullscreenWrapperComponent,
  BillingSettingsFormComponent,
  MediaLibraryComponent,
  FilterSpecDisplayComponent,
  VerticalTabsComponent,
  AccessWarningComponent,
  FeedSettingsErrorComponent,
  AccountRequestModalComponent,
  IntegrationsHelperComponent,
  FeatureBannerComponent,
  IntroPaymentComponent,
  EmptyStateComponent,
  FeedImageSettingsComponent,
  AgencyFbEmptyStateComponent,
  MediaCompareComponent,
  MediaFilterComponent,
  DesignPreviewerComponent,
  CategoriesTreeComponent,
];

const DIRECTIVES = [
  InfiniteScrollDirective,
  VerticalTabDirective,
  MediaCompareDirective,

  ItemInfoDirective,
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  SubfieldPipe,
  ConcatPipe,
  ConstrainPipe,
  ShortNumberPipe,
  SlatePipe,
  LogPipe,
  FilterFormatPipe,
  TruncatePipe,
  SplitPipe,
  CRoundPipe,
];

@NgModule({
  imports: [
    ...BASE_MODULES,
    ...EXT_MODULES,

    NgxStripeModule.forRoot(environment.stripePublishableKey),
  ],
  exports: [
    ...BASE_MODULES,
    ...EXT_MODULES,

    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
    };
  }
}
