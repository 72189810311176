import { Directive, HostBinding } from "@angular/core";

@Directive({
  selector: "[ngxVerticalTab]",
})
export class VerticalTabDirective {
  @HostBinding("class")
  classes = `
  hover:bg-gray-50
  text-gray-500
  hover:text-gray-700

  cursor-pointer
  no-underline
  group
  flex
  items-center
  px-3
  py-2
  text-sm
  font-medium
  rounded-md
`;
}
