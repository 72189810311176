import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "confect-icon",
  template: `
    <div
      class="inline-flex items-center"
      [class.flex-row-reverse]="side === 'right'"
    >
      @if (_icon) {
        <span [ngClass]="iconClasses"></span>
      }
      <ng-content />
    </div>
  `,
})
export class CIconComponent implements OnInit {
  _icon: string | undefined = "";
  _classes: string | undefined = "";

  iconClasses = {};

  @Input() side: "left" | "right" = "left";

  @Input() set icon(to: string | undefined) {
    this._icon = to;
    this.calculateClasses();
  }
  @Input() set iconClass(to: string | undefined) {
    this._classes = to;
    this.calculateClasses();
  }

  calculateClasses() {
    const newClasses: Record<string, boolean> = {};

    if (this._icon) {
      newClasses[`tio-${this._icon}`] = true;
    }

    if (this._classes) {
      newClasses[this._classes] = true;
    }

    this.iconClasses = newClasses;
  }

  constructor() {}

  ngOnInit(): void {}
}
