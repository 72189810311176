<mat-accordion class="example-headers-align" displayMode="flat">
  @for (filterOption of _filterOptions; track trackBy($index, filterOption)) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span
            class="text-gray-600 font-medium text-sm font-sans"
            [class.filteredProperty]="numberSelectedOptions(filterOption) > 0"
          >
            {{
              capitalizeFirstLetter(filterOption.name.split(".").slice(-1)[0])
            }}
            {{ formattedSelectedOptions(filterOption) }}
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div confect-form-inline-group [full]="true">
          @if (
            filterOption.group === "additional_fields" ||
            filterOption.group === "custom_fields"
          ) {
            <confect-checkbox
              title="Has {{ filterOption.name }}"
              [value]="checkProperty(filterOption.key, filterOption.group)"
              (valueChange)="
                internalValueChanged(
                  updateHasProperty(filterOption.key, filterOption.group),
                  'has_properties'
                )
              "
            />
          }

          <confect-input
            class="block mb-4"
            placeholder="Filter options"
            [ngModel]="paginators[filterOption.name].searchString"
            (ngModelChange)="paginators[filterOption.name].changeQuery($event)"
          />

          @for (
            option of paginators[filterOption.name].paginatedData;
            track option
          ) {
            <confect-checkbox
              [id]="filterOption.name + ' - ' + option.name"
              [title]="option.name"
              [value]="
                hasOption(filterOption, option) ||
                checkProperty(filterOption.key, filterOption.group)
              "
              (valueChange)="toggleOption(filterOption, option)"
              [disable]="checkProperty(filterOption.key, filterOption.group)"
            />
          }
        </div>
        @if (paginators[filterOption.name].showPager) {
          <div class="flex space-x-4 items-center justify-center">
            <confect-button
              (click)="paginators[filterOption.name].prevPage()"
              [disabled]="!paginators[filterOption.name].hasPrevPage"
              type="white"
            >
              Prev
            </confect-button>
            <confect-button
              (click)="paginators[filterOption.name].nextPage()"
              [disabled]="!paginators[filterOption.name].hasMorePages"
              type="white"
            >
              Next
            </confect-button>
          </div>
        }
      </ng-template>
    </mat-expansion-panel>
  }

  @if (builtinsEnabled) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          ><span
            class="text-gray-600 font-medium text-sm font-sans"
            [class.filteredProperty]="
              _selectedFilters.on_sale ||
              _selectedFilters.min_price ||
              _selectedFilters.max_price
            "
          >
            Price
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div confect-form-inline-group [full]="true">
        <confect-checkbox
          title="On Sale"
          [value]="_selectedFilters.on_sale"
          (valueChange)="generalValueChange('on_sale', $event)"
        />
        <confect-input
          title="Min price"
          [ngModel]="_selectedFilters.min_price ?? ''"
          (ngModelChange)="internalValueChanged($event, 'min_price')"
        />
        <confect-input
          title="Max price"
          [ngModel]="_selectedFilters.max_price ?? ''"
          (ngModelChange)="internalValueChanged($event, 'max_price')"
        />
      </div>
    </mat-expansion-panel>
  }

  @if (builtinsEnabled) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span
            class="text-gray-600 font-medium text-sm font-sans"
            [class.filteredProperty]="_selectedFilters.newer_than"
          >
            Newer Than
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div confect-form-inline-group [full]="true">
        <confect-input
          title="Days ago"
          [ngModel]="_selectedFilters.newer_than ?? ''"
          (ngModelChange)="internalValueChanged($event, 'newer_than')"
        />
      </div>
    </mat-expansion-panel>
  }

  @if (builtinsEnabled) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span
            class="text-gray-600 font-medium text-sm font-sans"
            [class.filteredProperty]="_selectedFilters.num_additional_images"
          >
            Additional images
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- <confect-select
        name="numAddImages"
        placeholder="Select amount"
        [ngModel]="_selectedFilters.num_additional_images"
        (ngModelChange)="internalValueChanged($event, 'num_additional_images')"
        [multiple]="true"
        [clearable]="true"
        [searchable]="false"
        [options]="[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
      >
      </confect-select> -->
      @for (i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track i) {
        <confect-checkbox
          title="{{ i !== 0 ? i : 'No' }} add. image{{ i !== 1 ? 's' : '' }}"
          [value]="addImageBoxChecked()[i]"
          (valueChange)="
            internalValueChanged(updateBoxes(i), 'num_additional_images')
          "
        />
      }
    </mat-expansion-panel>
  }

  @if (builtinsEnabled && productIdEnabled) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span
            class="text-gray-600 font-medium text-sm font-sans"
            [class.filteredProperty]="_selectedFilters?.product_id?.length"
          >
            Product ID
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="flex flex-col space-y-4 items-center w-full">
        <p class="text-center">{{ filterProductCount() }} products selected</p>
        <confect-button
          class="w-full"
          [block]="true"
          (click)="openSearchWindow()"
          type="secondary"
        >
          Search
        </confect-button>
        @if (_selectedFilters?.product_id?.length) {
          <span
            class="text-center font-bold text-gray-500 cursor-pointer hover:underline"
            (click)="resetSearchProducts()"
            >Reset Selection</span
          >
        }
      </div>
    </mat-expansion-panel>
  }

  @if (showImageModeSettings) {
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span
            class="text-gray-600 font-medium text-sm font-sans"
            [class.filteredProperty]="this._selectedFilters.bg_status"
          >
            Image Background
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <confect-select
        #imageModeSelect
        name="imageMode"
        [ngModel]="bgStatusValue"
        (ngModelChange)="bgStatusChange($event)"
        [options]="[
          {
            name: 'All',
            id: '',
          },
          {
            name: 'Background removed',
            id: 'REMOVED',
          },
          {
            name: 'With background',
            id: 'NOT_REMOVED',
          },
        ]"
      />
    </mat-expansion-panel>
  }
</mat-accordion>

<ng-template #productSearchTemplate let-close="close">
  <div class="w-[70vw]">
    <ngx-products-search
      [selectedProductsInput]="
        _selectedFilters.product_id != null ? _selectedFilters.product_id : []
      "
      (selectedProductsChange)="searchResult($event); close()"
      [productFilters]="_selectedFiltersForProductSearch"
      [noBorders]="true"
    />
  </div>
</ng-template>
