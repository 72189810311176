import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgZone,
  OnDestroy,
  Output,
  QueryList,
  ViewContainerRef,
  inject,
} from "@angular/core";

import { CMath } from "@core/utils/confect-math";

import {
  Subject,
  fromEvent,
  map,
  merge,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";

export interface Point {
  x: number;
  y: number;
}

@Directive({ selector: "[drag-handle]" })
export class DragHandleDirective {}

@Directive({
  selector: "[drag]",
})
export class DragDirective implements AfterViewInit, OnDestroy {
  origin: Point;
  originBounds: any;

  dragging = false;

  private readonly destroy$$ = new Subject<void>();
  private readonly document = inject(DOCUMENT);

  @Input() dragDisable: boolean = false;
  @Input() sensitivity: number = 0;
  @Output() dragMoved = new EventEmitter<Point>();

  @ContentChildren(DragHandleDirective, { read: ElementRef })
  children: QueryList<ElementRef>;

  constructor(
    private viewRef: ViewContainerRef,
    private elRef: ElementRef,
    private zone: NgZone,
  ) {}

  @HostBinding("style.transform") transform;

  @Input() constrainMovement: (
    pos: Point,
    origin: Point,
    originBounds: any,
  ) => Point = (pos: Point, origin: Point, originBounds: any) => pos;
  @Input() dragStart: (pos: Point) => void = () => {};
  @Input() dragMove: (pos: Point) => void = (pos: Point) => {
    this.transform = `translateY(${pos.y}px) translateX(${pos.x}px)`;
  };
  @Input() dragEnd = () => {
    this.transform = "translateY(0px) translateX(0px)";
  };

  ngAfterViewInit(): void {
    const nativeElement = this.viewRef.element.nativeElement;

    const stopDefaultDrag$ = fromEvent(this.document, "drag", {
      capture: true,
    }).pipe(
      tap((event) => {
        event.preventDefault(), event.stopPropagation();
      }),
      takeUntil(this.destroy$$),
    );

    const dragHandles =
      this.children.length > 0
        ? this.children.map((elRef: ElementRef) => elRef.nativeElement)
        : [];

    const mouseDown$ = (
      dragHandles.length > 0
        ? merge(...dragHandles.map((dh) => fromEvent(dh, "mousedown")))
        : fromEvent(nativeElement, "mousedown")
    ).pipe(
      takeUntil(this.destroy$$), // clear subscription
    );

    const mouseMove$ = fromEvent(this.document, "mousemove").pipe(
      takeUntil(this.destroy$$), // clear subscription
    );
    const mouseUp$ = fromEvent(this.document, "mouseup").pipe(
      takeUntil(this.destroy$$), // clear subscription
    );

    const dragMove$ = mouseDown$.pipe(
      tap((event: MouseEvent) => {
        this.document.documentElement.classList.add("select-none");

        if (this.dragDisable) {
          return event;
        }

        this.origin = { x: event.clientX, y: event.clientY };

        this.originBounds = JSON.parse(
          JSON.stringify(this.elRef.nativeElement.getBoundingClientRect()),
        );

        return event;
      }),
      switchMap((startEvent: MouseEvent) =>
        mouseMove$.pipe(
          map(this.move),
          takeUntil(mouseUp$.pipe(tap(this.stop))),
        ),
      ),
      tap((position) => {}),
      takeUntil(this.destroy$$), // clear subscription
    );
    dragMove$.subscribe();
    stopDefaultDrag$.subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
  }

  move = (event: MouseEvent) => {
    if (this.dragDisable) {
      return event;
    }

    if (
      !this.dragging &&
      CMath.euclideanDist({ x: event.clientX, y: event.clientY }, this.origin) >
        this.sensitivity
    ) {
      this.dragStart(this.origin);

      this.dragging = true;
    }

    const movement: Point = this.constrainMovement(
      {
        x: event.clientX,
        y: event.clientY,
      },
      this.origin,
      this.originBounds,
    );
    const distance: Point = {
      x: movement.x - this.origin.x,
      y: movement.y - this.origin.y,
    };

    this.dragging && this.dragMove(distance);
    this.dragging && this.dragMoved.emit(distance);
    return event;
  };

  stop = () => {
    this.document.documentElement.classList.remove("select-none");

    if (this.dragDisable || !this.dragging) {
      return;
    }

    this.dragging = false;
    this.dragEnd();
    this.origin = { x: 0, y: 0 };
  };
}
