export const EU_COUNTRIES = new Set([
  "belgium",
  "bulgaria",
  "czechia",
  "denmark",
  "germany",
  "estonia",
  "ireland",
  "greece",
  "spain",
  "france",
  "croatia",
  "italy",
  "cyprus",
  "latvia",
  "lithuania",
  "luxembourg",
  "hungary",
  "malta",
  "netherlands",
  "austria",
  "poland",
  "portugal",
  "romania",
  "slovenia",
  "slovakia",
  "finland",
  "sweden",
]);
