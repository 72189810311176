import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ImageSettings, ImageSettingsMode } from "@core/api-models/admin.types";
import { SettingsService } from "@core/api/settings.service";

@Component({
  selector: "ngx-feed-image-settings",
  templateUrl: "./feed-image-settings.component.html",
})
export class FeedImageSettingsComponent implements OnInit {
  options = [
    {
      name: "Packshots",
      description: "Products are on a white or solid color background.",
      value: 0,
      image: "/assets/images/onboarding/packshots.jpg",
    },
    {
      name: "Human Models",
      description: "Products are mostly shown on human models.",
      value: 1,
      image: "/assets/images/onboarding/human_models.jpg",
    },
    {
      name: "Photographs",
      description: "Products are not on a white or solid color background.",
      image: "/assets/images/onboarding/photographs.jpg",
      value: 2,
    },
  ];

  @Input() onboarding = false;

  settings?: ImageSettings;
  _value = 0;

  @Output() change = new EventEmitter<ImageSettings>();

  constructor(private ss: SettingsService) {}

  ngOnInit(): void {
    this.ss.getAccountImageSettings().subscribe((res) => {
      this.settings = res;
      this._value = this.settings.mode === ImageSettingsMode.MIX ? 0 : 1;
    });
  }

  settingsChange(to: number) {
    this._value = to;
    const settings = {
      mode:
        this._value === 0
          ? ImageSettingsMode.MIX
          : ImageSettingsMode.DO_NOT_REMOVE_BG,
      mix_settings: { exclude: true, product_filter: {} },
    };

    this.ss.updateAccountImageSettings(settings).subscribe({
      next: (res) => {
        this.change.emit(this.settings);
        // this._status.image_settings = settings;
        // this.payloadChange.emit({ name: this.name, value: settings });
      },
    });
  }
}
