import { Component, HostBinding, Input } from "@angular/core";

@Component({
  selector: "[split-content]",
  templateUrl: "./split-view-content.component.html",
})
export class SplitViewContentComponent {
  @HostBinding("class.w-full") w = true;
  @HostBinding("class.h-full") h = true;
  @HostBinding("class.hidden") hidden = true;
  @HostBinding("class.flex") flex = true;

  _show: boolean = false;
  @Input({ required: true }) set show(to: boolean) {
    this.hidden = !to;
    this.flex = to;
    this._show = to;
  }
}
