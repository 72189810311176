import {
  AfterViewInit,
  Component,
  ElementRef,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  signal,
} from "@angular/core";

@Component({
  selector: "confect-side-scroll",
  templateUrl: "./side-scroll.component.html",
})
export class SideScrollComponent implements AfterViewInit {
  @ViewChild("scrollContent") content: ElementRef<any>;
  @ViewChild("scrollView") view: ElementRef<any>;

  contentWidth: WritableSignal<number> = signal(0);
  viewWidth: WritableSignal<number> = signal(0);
  scrollLeft: WritableSignal<number> = signal(0);
  scrollRight: WritableSignal<number> = signal(0);

  showLeft: Signal<boolean> = computed(() => this.scrollLeft() > 10);
  showRight: Signal<boolean> = computed(() => this.scrollRight() > 10);

  resizeObserver = new ResizeObserver(() => {
    setTimeout(() => {
      this.setSizes();
    });
  });

  ngAfterViewInit(): void {
    this.resizeObserver.observe(this.view.nativeElement);
    setTimeout(() => {
      this.setSizes();
    });
  }

  setSizes() {
    this.contentWidth.set(this.content.nativeElement.scrollWidth);
    this.viewWidth.set(this.view.nativeElement.offsetWidth);
    this.scrollLeft.set(this.view.nativeElement.scrollLeft);
    this.scrollRight.set(
      this.contentWidth() - this.viewWidth() - this.scrollLeft(),
    );
  }

  scroll(offset = 0) {
    this.scrollLeft.set(this.view.nativeElement.scrollLeft + offset);
    this.scrollRight.set(
      this.contentWidth() - this.viewWidth() - this.scrollLeft(),
    );
  }

  moveLeft() {
    const newVal = this.view.nativeElement.scrollLeft - this.viewWidth() / 2;

    this.view.nativeElement.scrollLeft = newVal < 10 ? 0 : newVal;

    this.scroll(-this.viewWidth() / 2 - (newVal < 10 ? newVal : 0));
  }
  moveRight() {
    const newVal = this.view.nativeElement.scrollLeft + this.viewWidth() / 2;
    this.view.nativeElement.scrollLeft =
      this.contentWidth() - this.viewWidth() - newVal < 10
        ? this.contentWidth() - this.viewWidth()
        : newVal;

    this.scroll(
      this.viewWidth() / 2 +
        (this.contentWidth() - this.viewWidth() - newVal < 10
          ? this.contentWidth() - this.viewWidth() - newVal
          : 0),
    );
  }
}
