import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";

import { Feed, FeedEditable } from "../api-models/feeds.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class FeedsService extends ApiService {
  getActiveProductCount(): Observable<any> {
    return of({ count: 0 });
  }

  getProductSortingOptions(): Observable<any> {
    return this.http.get(this.baseURL + "/feeds/product/sorting");
  }

  getGroupingData(): Observable<any> {
    return this.http.get(this.baseURL + "/feeds/product/grouping-urls");
  }

  getGroupingDataByGrouping(grouping: string): Observable<any> {
    return this.http.get(
      `${this.baseURL}/feeds/product/grouping-urls/${grouping}`,
    );
  }

  updateGroupingData(data): Observable<any> {
    const json = {
      data: data,
    };
    return this.http.post(this.baseURL + "/feeds/product/grouping-urls", json);
  }

  resetGroupingDataElement(grouping, groupingKey): Observable<any> {
    const options = this.params({
      grouping: grouping,
      grouping_key: groupingKey,
    });
    return this.http.delete(
      this.baseURL + "/feeds/product/grouping-urls",
      options,
    );
  }

  getFeed(feedID: number): Observable<Feed> {
    return this.http.get<Feed>(`${this.endpoint}/feeds/${feedID}`);
  }

  getFeeds(): Observable<Feed[]> {
    return this.http.get<Feed[]>(this.endpoint + "/feeds");
  }

  saveFeed(feed: FeedEditable): Observable<FeedEditable> {
    const suffix = feed.id != null ? `/${feed.id}` : "";
    return this.http.post<FeedEditable>(
      `${this.endpoint}/feeds${suffix}`,
      feed,
    );
  }

  deactivateFeed(feedID): Observable<any> {
    return this.http.delete(`${this.endpoint}/feeds/${feedID}`);
  }

  refreshFeed(feedID): Observable<any> {
    return this.http.post(`${this.endpoint}/feeds/${feedID}/refresh`, null);
  }

  // No usage
  // getFeedStatistics(): Observable<any> {
  //   return this.http.get(`${this.baseURL}/feeds/statistics`);
  // }

  // No usage
  // getProductLookup(options): Observable<any> {
  //   const params = this.arrayGetParams(options);
  //   return this.http.get(this.baseURL + "/feeds/products", { params });
  // }
}
