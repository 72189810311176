<div
  class="w-full h-full flex"
  [ngClass]="{ 'flex-col': !isVerticalSplit, 'flex-row': isVerticalSplit }"
>
  @if (!loading) {
    <div
      class="flex flex-shrink-0 flex-grow-0"
      [style.max-width]="isVerticalSplit ? firstMax : false"
      [style.min-width]="isVerticalSplit ? firstMin : false"
      [style.max-height]="!isVerticalSplit ? firstMax : false"
      [style.min-height]="!isVerticalSplit ? firstMin : false"
      [ngClass]="{
        'h-full flex-row ': isVerticalSplit,
        'w-full flex-col': !isVerticalSplit,
      }"
      [style.flex-basis]="firstBasis()"
    >
      @if (first != null) {
        <div
          class="w-full h-full"
          sub-view
          [spec]="first"
          (specChange)="_spec.split.first = $event; specChange.emit(_spec)"
        >
          <ng-template split-content-tmp let-view="view">
            <ng-template
              [ngTemplateOutlet]="contentTemplate"
              [ngTemplateOutletContext]="{ view: view }"
            />
          </ng-template>
        </div>
      }
      @if (firstID != null) {
        <div class="w-full h-full flex">
          <ng-template
            [ngTemplateOutlet]="contentTemplate"
            [ngTemplateOutletContext]="{ view: firstID }"
          />
        </div>
      }
    </div>
    <div
      class="relative flex flex-shrink items-center justify-center"
      [ngClass]="{
        'h-full w-0': isVerticalSplit,
        'w-full h-0': !isVerticalSplit,
        hidden: disableDrag,
      }"
    >
      <div
        #handle
        drag
        [dragDisable]="disableDrag"
        [sensitivity]="0"
        [dragMove]="move"
        [dragStart]="start"
        [dragEnd]="stop"
        class="absolute group flex items-center justify-center z-[100]"
        [ngClass]="{
          'w-4 h-5/6  cursor-ew-resize': isVerticalSplit,
          'w-5/6 h-4  cursor-ns-resize': !isVerticalSplit,
        }"
      >
        <div
          class="bg-primary group-hover:flex hidden"
          [ngClass]="{
            'w-0.5 h-full cursor-ew-resize': isVerticalSplit,
            'w-full h-0.5 cursor-ns-resize': !isVerticalSplit,
          }"
        ></div>
      </div>
    </div>
    <div
      class="flex flex-shrink-0 flex-grow-0"
      [style.max-width]="isVerticalSplit ? secondMax : false"
      [style.min-width]="isVerticalSplit ? secondMin : false"
      [style.max-height]="!isVerticalSplit ? secondMax : false"
      [style.min-height]="!isVerticalSplit ? secondMin : false"
      [style.flex-basis]="secondBasis()"
      [ngClass]="{
        'h-full flex-row ': isVerticalSplit,
        'w-full flex-col': !isVerticalSplit,
      }"
    >
      @if (second != null) {
        <div
          class="w-full h-full"
          sub-view
          [spec]="second"
          (specChange)="_spec.split.second = $event; specChange.emit(_spec)"
        >
          <ng-template split-content-tmp let-view="view">
            <ng-template
              [ngTemplateOutlet]="contentTemplate"
              [ngTemplateOutletContext]="{ view: view }"
            />
          </ng-template>
        </div>
      } @else {
        <div class="w-full h-full flex">
          <ng-template
            [ngTemplateOutlet]="contentTemplate"
            [ngTemplateOutletContext]="{ view: secondID }"
          />
        </div>
      }
    </div>
  }
</div>
