<div class="flex items-center justify-between flex-wrap">
  <div class="relative flex-grow focus-within:z-10">
    <div
      class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
    >
      <confect-icon icon="search" iconClass="text-xl text-gray-500" />
    </div>
    <input
      type="text"
      class="focus:ring-0 block w-full rounded-r-md rounded-l-md pl-6 border-none"
      [placeholder]="placeholder"
      [value]="value"
      (input)="onChange($any($event).target.value)"
    />
  </div>
</div>
