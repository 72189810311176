@if (featureKey != null && show === true) {
  <div class="pb-2 sm:pb-5">
    <div class="mx-4 px-2">
      <div class="p-2 rounded-lg bg-primary-600 shadow-lg sm:p-3">
        <div class="flex items-center justify-between flex-wrap">
          <div class="w-0 flex-1 flex items-center">
            <span>
              <confect-icon
                icon="error_outlined"
                iconClass="text-4xl text-white"
              />
            </span>
            <div class="flex flex-col space-y-1">
              <p class="ml-3 font-medium text-white truncate">
                {{
                  info?.state === "initial"
                    ? topText
                    : info?.state === "trial_ended" && info?.days_left !== 0
                      ? "You have canceled your 30 day free trial early. You still have " +
                        info?.days_left +
                        " days left before the trial runs out."
                      : "You have finished your 30 day free trial."
                }}
              </p>
              <p class="ml-3 font-medium text-white truncate">
                {{
                  info?.state === "initial"
                    ? bottomText
                    : info?.state === "trial_ended" && info?.days_left !== 0
                      ? "For full access, please continue your free trial."
                      : "Please continue your subscribe in order to have full access."
                }}
              </p>
            </div>
          </div>
          <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
            @if (info?.state === "initial") {
              <confect-button
                type="white"
                (click)="startTrial()"
                tooltip
                [tooltipText]="tooltip"
                tooltipPosition="below"
                >Start 30 day free trial</confect-button
              >
            }
            @if (info?.state === "trial_ended") {
              <confect-button
                type="white"
                tooltip
                [tooltipText]="tooltip"
                tooltipPosition="below"
                routerLink="/settings/features"
                >{{
                  info?.days_left === 0 ? "Subscribe" : "Continue Free Trial"
                }}</confect-button
              >
            }
          </div>
        </div>
      </div>
    </div>
  </div>
}

<confect-modal #paymentModal>
  @if (paymentModal.shown) {
    <ngx-intro-payment />
  }
</confect-modal>
