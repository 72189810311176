@if (showClose) {
  <div class="flex items-center justify-end">
    <confect-button type="white" (click)="closeModal(false)"
      ><confect-icon icon="clear" iconClass="text-lg"
    /></confect-button>
  </div>
}
<ng-container
  [ngTemplateOutlet]="template"
  [ngTemplateOutletContext]="templateContent ?? { close: close }"
/>
