import {
  Component,
  ElementRef,
  Input,
  Signal,
  ViewChild,
  WritableSignal,
  computed,
  signal,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "confect-slider",
  templateUrl: "./slider.component.html",
  styleUrl: "./slider.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CSliderComponent,
    },
  ],
})
export class CSliderComponent implements ControlValueAccessor {
  @Input() set sliderInfo(to: {
    interval: { start: number; end: number };
    stepSize: number;
    default?: number;
  }) {
    if (to == null) {
      return;
    }
    this.start.update(() => to.interval.start);
    this.end.update(() => to.interval.end);
    this.stepSize.update(() => to.stepSize);
  }
  @Input() name = "";
  @Input() writable = true;
  @Input() decimals: number = 2;
  @Input() type: "light" | "normal" | "dark" = "normal";

  @ViewChild("slideBox") slideBox: ElementRef;
  @ViewChild("input") input: ElementRef;

  start: WritableSignal<number> = signal(0);
  end: WritableSignal<number> = signal(100);
  stepSize: WritableSignal<number> = signal(1);

  internalState: Signal<{
    start: number;
    end: number;
    stepSize: number;
  }> = computed(() => this.calcRelativeState());

  // State
  value: WritableSignal<number> = signal(0);
  constrainedVal: Signal<number> = computed(() =>
    this.constrain(this.value(), 0, 100),
  );
  actValue: Signal<number> = computed(() => this.relToAct(this.value()));
  roundedValue: Signal<null | any> = computed(() => {
    if (isNaN(Number(this.value()))) {
      return this.value();
    }
    const factor = Math.pow(10, this.decimals);
    return Math.round(this.actValue() * factor) / factor;
  });

  touched = false;

  cache = {
    mousePos: 0,
    value: 0,
  };

  focus = false;
  typing = false;
  sliding = false;

  // Callbacks
  onChange = (_value) => {};
  onTouched = () => {};

  writeValue(value: any) {
    this.value.update(() => this.actToRel(value));
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  round(input: number, decimals: number) {
    const factor = Math.pow(10, decimals);
    return Math.round(input * factor) / factor;
  }

  constrain(input: number, min: number, max: number) {
    return Math.min(Math.max(input, min), max);
  }

  actToRel(act) {
    return ((act - this.start()) / (this.end() - this.start())) * 100;
  }
  relToAct(rel) {
    return (
      Math.round(
        ((rel / 100) * (this.end() - this.start()) + this.start()) * 10,
      ) / 10
    );
  }
  calcRelativeState() {
    return {
      start: this.actToRel(this.start()),
      end: this.actToRel(this.end()),
      stepSize: (this.stepSize() / (this.end() - this.start())) * 100,
    };
  }

  snapToStep(position) {
    const stepSize = this.internalState().stepSize;
    const stepsToPos = Math.round(position / stepSize);

    return stepSize * stepsToPos;
  }

  setSlide(event: MouseEvent) {
    const w = this.slideBox.nativeElement.offsetWidth;
    const l = (event.target as any).getBoundingClientRect().left;
    const change = ((event.clientX - l) / w) * 100;

    const newVal = this.constrain(this.snapToStep(change), 0, 100);
    this.value.update(() => newVal);
    this.onChange(this.relToAct(newVal));

    this.beginSlide(event);
  }

  beginSlide(event: MouseEvent) {
    this.onTouched();
    this.sliding = true;
    setTimeout(() => {
      this.input.nativeElement.focus();
      this.focus = true;
    });
    this.cache.mousePos = event.clientX;
    this.cache.value = this.value();

    window.addEventListener("mousemove", this.slideMove, false);
    window.addEventListener("mouseup", this.endSlide, false);
  }

  slideMove = (event: MouseEvent) => {
    this._move(event);
  };

  _move(event: MouseEvent) {
    const w = this.slideBox.nativeElement.offsetWidth;
    const change = ((event.clientX - this.cache.mousePos) / w) * 100;

    const newVal = this.constrain(
      this.snapToStep(this.cache.value + change),
      0,
      100,
    );
    this.value.update(() => newVal);

    this.onChange(this.relToAct(newVal));
  }

  endSlide = (event: MouseEvent) => {
    this.sliding = false;
    if (!this.writable) {
      this.input.nativeElement.blur();
    }
    window.removeEventListener("mousemove", this.slideMove, false);
    window.removeEventListener("mouseup", this.endSlide, false);
  };

  parseFloat(str) {
    return parseFloat(str);
  }
  validateString(str) {
    return !isNaN(Number(str));
  }
}
