import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import {
  Agency,
  AgencyFBClient,
  AgencyFBClientGroupDashboard,
  Client,
  ClientCount,
  ClientLastOnline,
  GPTPrompt,
  GPTResponse,
  HistoricCommission,
  RealizedMonthlyCommission,
  TrialCreationResponse,
} from "../api-models/agency.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AgencyService extends ApiService {
  getAgencyClientCount(): Observable<ClientCount> {
    return this.http.get<ClientCount>(`${this.endpoint}/agencies/client-count`);
  }

  getAgencyRealizedMonthlyCommission(): Observable<RealizedMonthlyCommission> {
    return this.http.get<RealizedMonthlyCommission>(
      `${this.endpoint}/agencies/realized-monthly-commission`,
    );
  }

  getAgencyClients(): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.endpoint}/agencies/clients`);
  }

  getAgencyClientLastOnline(
    company_ids: number[],
  ): Observable<ClientLastOnline[]> {
    return this.http.post<ClientLastOnline[]>(
      `${this.endpoint}/agencies/last-online`,
      {
        company_ids,
      },
    );
  }

  getAgencyHistoricCommissions(
    to_date: string,
    from_date: string,
  ): Observable<HistoricCommission[]> {
    return this.http.post<HistoricCommission[]>(
      `${this.endpoint}/agencies/historic-commissions`,
      {
        to_date,
        from_date,
      },
    );
  }

  getAgency(): Observable<Agency> {
    return this.http.get<Agency>(`${this.endpoint}/agencies`);
  }

  agencyRequestTrial(
    company_name: string,
    feed_url: string,
    person_name: string,
    person_email: string,
    person_mobile_no: string,
    extra_details?: string,
  ): Observable<any[]> {
    return this.http.post<any>(`${this.endpoint}/agencies/request-trial`, {
      company_name,
      feed_url,
      person_name,
      person_email,
      person_mobile_no,
      extra_details,
    });
  }

  agencyCreateCompanyTrial(
    company_name: string,
    domain: string,
  ): Observable<TrialCreationResponse> {
    this.hj.event("agency_created_company_trial");

    return this.http.post<TrialCreationResponse>(
      `${this.endpoint}/agencies/trial`,
      {
        company_name,
        domain,
      },
    );
  }

  agencyCreateFBClientCompanyTrial(
    uuid: string,
    company_name: string,
    domain: string,
  ): Observable<TrialCreationResponse> {
    return this.http.post<TrialCreationResponse>(
      `${this.endpoint}/agencies/fb-client-trial`,
      {
        uuid,
        company_name,
        domain,
      },
    );
  }

  getAgencyFBClients(): Observable<AgencyFBClient[]> {
    return this.http.get<AgencyFBClient[]>(
      `${this.endpoint}/agencies/fb-clients`,
    );
  }

  requestAgencyFBClientAnalytics(
    uuid: string,
  ): Observable<{ sucess: boolean }> {
    return this.http.post<{ sucess: boolean }>(
      `${this.endpoint}/agencies/fb-clients/${uuid}/request-analytics`,
      null,
    );
  }

  updateAgencyFBClientGroups(
    client: AgencyFBClient,
    groups: Record<string, boolean>,
  ): Observable<{ sucess: boolean }> {
    return this.http.post<{ sucess: boolean }>(
      `${this.endpoint}/agencies/fb-clients/${client.uuid}/groups`,
      groups,
    );
  }

  getAgencyIndustryDashboards(): Observable<AgencyFBClientGroupDashboard[]> {
    return this.http.get<AgencyFBClientGroupDashboard[]>(
      `${this.endpoint}/agencies/fb-client-group-dashboards`,
    );
  }

  refreshAgencyIndustryDashboard(
    dash: AgencyFBClientGroupDashboard,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/agencies/fb-client-group-dashboards/${dash.uuid}/refresh`,
      null,
    );
  }

  getGPTResponse(prompt: GPTPrompt) {
    return this.http.post<GPTResponse>(`${this.endpoint}/agencies/gpt`, prompt);
  }
}
