import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm } from "@angular/forms";

import { Feed } from "@core/api-models/feeds.models";
import { FeedsService } from "@core/api/feeds.service";
import { AccessService } from "@core/services/access.service";

import { CDropdownItem } from "@theme/@confect/components/dropdown/dropdown.component";
import { CModalSuccessComponent } from "@theme/@confect/components/modal-success/modal-success.component";
import { CModalComponent } from "@theme/@confect/components/modal/modal.component";

import { Subject, finalize, takeUntil } from "rxjs";
import * as timeago from "timeago.js";

interface FeedIssue {
  errorKey: string;
  feed: Feed;
}

@Component({
  selector: "ngx-feed-settings",
  templateUrl: "./feed-settings.component.html",
  styleUrls: ["./feed-settings.component.scss"],
})
export class FeedSettingsComponent implements OnInit, OnDestroy {
  @Input() addOnly = false;
  @Output() save = new EventEmitter();

  // Make timeago public for template usage
  timeago = timeago;

  FEEDS: Feed[] = [];

  FAILED_FEEDS: FeedIssue[] = [];
  WARNING_FEEDS: FeedIssue[] = [];

  feedStatuses: Record<number, { message: string; color: string }> = {};

  isSaving = false;
  isRefreshing = false;

  showAdd = false;
  newFeed = this.emptyFeed();

  percentageProductsReady = "0%";
  allowNewFeed = false;

  dropdownItems: CDropdownItem[][] = [
    [
      {
        title: "Edit",
        action: "edit",
        icon: "edit",
      },
    ],
  ];

  unsubscribe$ = new Subject();

  @ViewChild("modal") modal: CModalComponent;
  @ViewChild("modalSuccess") modalSuccess: CModalSuccessComponent;

  @ViewChild("feedDeletion") feedDeletion: CModalComponent;

  selectedFeed?: Feed;

  currentAccount;

  constructor(
    private accessService: AccessService,
    private feedsService: FeedsService,
  ) {
    this.currentAccount = this.accessService.current.account.id;
  }

  ngOnInit() {
    if (this.accessService.hasPermission("multiple_feeds")) {
      this.dropdownItems[0].push({
        title: "Delete",
        action: "delete",
        icon: "delete_outlined",
      });
    }

    this.updateFeeds();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  parseFeedInfo(
    feeds: Feed[],
    key: string,
  ): {
    errorKey: string;
    feed: Feed;
  }[] {
    const infoFeeds = feeds.filter((f) => Object.keys(f[key]).length > 0);

    const res = [];

    infoFeeds.forEach((f) => {
      for (const k of Object.keys(f[key])) {
        res.push({ errorKey: k, feed: f });
      }
    });

    return res;
  }

  private _errorCheckString(info: FeedIssue) {
    return `${info.feed.id}-${info.errorKey}`;
  }

  private checkErrorsChanged(oldInfo: FeedIssue[], newInfo: FeedIssue[]) {
    return !(
      oldInfo.map((f) => this._errorCheckString(f)).join("-") ===
      newInfo.map((f) => this._errorCheckString(f)).join("-")
    );
  }

  private _updateFeeds(res: Feed[]) {
    this.FEEDS = res.map((f) => {
      f["lu"] =
        f.last_updated == null ? "Never" : timeago.format(f.last_updated);
      return f;
    });

    const newFailed = this.parseFeedInfo(res, "error_info");
    const newWarnings = this.parseFeedInfo(res, "warning_info");

    if (this.checkErrorsChanged(this.FAILED_FEEDS, newFailed)) {
      this.FAILED_FEEDS = newFailed;
    }
    if (this.checkErrorsChanged(this.WARNING_FEEDS, newWarnings)) {
      this.WARNING_FEEDS = newWarnings;
    }

    this.feedStatuses = {};
    this.FEEDS.forEach((f) => {
      let msg = "Feed is working";
      let color = "GREEN";

      if (Object.keys(f.error_info).length > 0) {
        msg = "Feed has critical errors";
        color = "RED";
      } else if (Object.keys(f.warning_info).length > 0) {
        msg = "Feed has warnings, but works fine";
        color = "YELLOW";
      }

      this.feedStatuses[f.id] = {
        message: msg,
        color: color,
      };
    });

    this.allowNewFeed =
      this.accessService.hasPermission("multiple_feeds") ||
      this.FEEDS.length === 0;
  }

  updateFeeds() {
    this.feedsService
      .getFeeds()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: Feed[]) => {
        this._updateFeeds(res);
      });
  }

  emptyFeed() {
    return {
      id: null,
      name: "",
      type: "google",
      url: "",
    };
  }

  startAdd() {
    if (this.allowNewFeed) {
      this.newFeed = this.emptyFeed();
      this.showAdd = true;
    }
  }

  startEdit(feed) {
    this.newFeed = JSON.parse(JSON.stringify(feed));
    this.showAdd = true;
  }

  cancelAdd() {
    this.newFeed = this.emptyFeed();
    this.showAdd = false;
  }

  saveFeed(feed) {
    if (this.isSaving) {
      return;
    }

    if (feed.name === "" || feed.url === "") {
      return;
    }

    this.isSaving = true;

    this.feedsService
      .saveFeed(feed)
      .pipe(
        finalize(() =>
          setTimeout(() => {
            this.modal.hide();
          }, 1500),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (res) => {
          this.showAdd = false;
          this.newFeed = this.emptyFeed();

          this.updateFeeds();

          this.isSaving = false;
          this.save.emit(res);

          this.modalSuccess.setupModal(true, false, "Saved!");
          this.modal.show();
        },
        error: (_err) => {
          this.isSaving = false;
        },
        complete: () => {
          this.isSaving = false;
        },
      });
  }

  deactivateFeed(feed: Feed) {
    this.selectedFeed = feed;
    this.feedDeletion.show();
  }

  confirmFeedDeletion(form: NgForm) {
    if (form.form.value["confirmation"] !== true) {
      return;
    }

    this.feedsService
      .deactivateFeed(this.selectedFeed.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.updateFeeds();
      });

    this.feedDeletion.hide();
    this.selectedFeed = null;
  }

  refreshFeed(feed) {
    if (this.isRefreshing) {
      return;
    }

    this.feedsService
      .refreshFeed(feed.id)
      .pipe(
        finalize(() =>
          setTimeout(() => {
            this.modal.hide();
          }, 3000),
        ),
        takeUntil(this.unsubscribe$),
      )
      .subscribe({
        next: (_) => {
          this.modalSuccess.setupModal(
            true,
            false,
            "Your products are now being updated!",
          );
          this.modal.show();
        },
        error: (_) => {},
        complete: () => {
          this.isRefreshing = false;
        },
      });
  }

  dropdownItemSelected(feed: Feed, item: CDropdownItem) {
    switch (item.action) {
      case "edit":
        this.startEdit(feed);
        break;
      case "delete":
        this.deactivateFeed(feed);
        break;
    }
  }

  trackByID(_idx, feed: Feed | any) {
    if (feed["feed"] != null) {
      return feed["feed"].id;
    }
    return feed.id;
  }
}
